import React from 'react'
import { Route } from 'react-router-dom'

import {RepairOrder, RepairOrderAccess, RepairOrderManage} from '../pages/repair-order'; 
import {Quotation, QuotationAccess, QuotationManage} from '../pages/quotation'; 
import {BillingNote, BillingNoteAccess, BillingNoteManage} from '../pages/billing-note'; 
import {Car, CarAccess, CarView} from '../pages/customer-history'; 
import {Receipt, ReceiptAccess, ReceiptManage} from '../pages/receipt'; 


export const SaleRouter = (<>
  <Route path="/repair-orders/" exact element={<RepairOrder />}>
      <Route index element={<RepairOrderAccess />} />
      <Route path="manage" element={<RepairOrderManage />} />
      {/* <Route path="view" element={<PilotScaleView />} /> */}
  </Route>
  <Route path="/quotation/" exact element={<Quotation />}>
      <Route index element={<QuotationAccess />} />
      <Route path="manage" element={<QuotationManage />} />
      {/* <Route path="view" element={<PilotScaleView />} /> */}
  </Route>
  <Route path="/billing-note/" exact element={<BillingNote />}>
      <Route index element={<BillingNoteAccess />} />
      <Route path="manage" element={<BillingNoteManage />} />
      {/* <Route path="view" element={<PilotScaleView />} /> */}
  </Route>
  <Route path="/customer-history/" exact element={<Car />}>
      <Route index element={<CarAccess />} />
      <Route path="view" element={<CarView />} />
  </Route>
  <Route path="/receipt/" exact element={<Receipt />}>
      <Route index element={<ReceiptAccess />} />
      <Route path="manage" element={<ReceiptManage />} />
      {/* <Route path="view" element={<PilotScaleView />} /> */}
  </Route>
</>)