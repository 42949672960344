import dayjs from 'dayjs';
import { comma } from '../../utils/util';
import { Typography } from 'antd';  
import { GoDotFill } from "react-icons/go";

const calTotalDiscount = (rec) => {
  const total =  Number(rec?.qty ||  0) * Number(rec?.price ||  0);
  const discount = 1 - ( Number(rec?.discount ||  0) / 100 );

  return total * discount;
}

const calRemain = (rec) => {
  const remain =  Number(rec?.amount ||  0) - Number(rec?.qty ||  0);

  return remain;
}
 
const onCellEdit = () => ({ 
  onCell: (recoed, index) => ({
    className: '', 
  })
});

const cellNoEdit = (clickCell) => ({
  onCell: (recoed, index) => ({
    className: `field-edit ${!!recoed?.sub_item ? 'no-expend !bg-gray-200' : ''}`,
    onClick:()=> (!!clickCell && !recoed?.sub_item) && clickCell(recoed, index)
  })
})

export const accessColumn = () => [
  {        
    title: "เลขใบรายการซ่อม",
    dataIndex: "rpocode",
    key: "rpocode",
    width: '10%', 
    align: "left",
    sorter: true, 
  },
  {
    title: "วันที่ใบรายการซ่อม",
    dataIndex: "rpodate",
    key: "rpodate",
    width: '10%',
    align: "left",
    sorter: true,
    hide: false,
    render: (v) => !!v ? dayjs(v).format("DD/MM/YYYY") : "-",
  },
  {
    title: "รหัสลูกค้า",
    dataIndex: "cuscode",
    key: "cuscode",
    width: '10%', 
    align: "left",
    sorter: true,
    hide: false,
  },
  {
    title: "ชื่อลูกค้า",
    dataIndex: "cusname",
    key: "cusname",
    align: "left",
    width: '15%', 
    sorter: false,
  },
  {
    title: "หมายเหตุ",
    dataIndex: "remark",
    key: "remark",
    align: "left",
    width: '30%',
    onCell: () => ({ style:{textWrap:'balance'}}),
    sorter: false,
    render:(v) => <pre className='m-0 border-0'>{v}</pre>
  },
  {
    title: "ทะเบียนรถ",
    dataIndex: "carno",
    key: "carno",
    width: "10%",
    align: "left",
    sorter: true,
    hide: true,
  },
  {
    title: "จังหวัด",
    dataIndex: "carno_province",
    key: "carno_province",
    width: "10%",
    align: "left",
    sorter: true,
    hide: true,
  },
  {
    title: "เลขใบเสนอราคา",
    dataIndex: "qtcode",
    key: "qtcode",
    width: "10%",
    align: "left",
    sorter: true,
    hide: true,
  },
  {
    title: "ออกโดย",
    dataIndex: "created_name",
    key: "created_name",
    width: "18%",
    align: "left",
    sorter: true,
    hide: true,
  },
]; 

export const columnDetail = (clickCell, action) => [
  {
    title: "ลำดับ",
    dataIndex: "ind",
    key: "ind",
    width: 80, 
    ...cellNoEdit(clickCell),
    render: (im, rc, index) => !rc?.sub_item ? <>{index + 1}</> : <GoDotFill />,
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "stcode",
    key: "stcode",
    width: '12%', 
    ...cellNoEdit(clickCell),
    align: "left",
  },
  // {
  //   title: "ชื่อสินค้า",
  //   dataIndex: "rpodetail",
  //   key: "rpodetail", 
  //   align: "left", 
  //   onCell: (recoed, index) => ({
  //     onClick:()=>clickCell(recoed, index)
  //   }),
  //   render: (_, rec) => rec.stname,
  // },
  {
    title: "ชื่อสินค้าสำหรับสั่งซื้อ",
    dataIndex: "rpodetail",
    key: "rpodetail", 
    align: "left",
    ...cellNoEdit(clickCell),
    render: (_, rec) => rec?.rpodetail || <>{rec.stname}{!!rec.material_code && `/${rec.material_code}`}</>,
  },
  {
    title: "จำนวนที่ต้องการ",
    dataIndex: "amount",
    key: "amount", 
    width: "10%",
    align: "right",
    className: "!pe-3",
    // editable: true,
    ...cellNoEdit(clickCell),
    type:'number',
    render: (_, rec) => <>{ comma( Number(rec?.amount ||  0) )}</>,
  },
  {
    title: "จำนวนจากสต๊อก",
    dataIndex: "qty",
    key: "qty", 
    width: "10%",
    align: "right",
    className: "!pe-3",
    // editable: true,
    ...cellNoEdit(clickCell),
    type:'number',
    render: (_, rec) => <>{ comma( Number(rec?.qty ||  0) )}</>,
  },
  {
    title: "เหลืออีก",
    dataIndex: "remain",
    key: "remain", 
    width: "10%",
    align: "right",
    className: "!pe-3",
    // editable: true,
    ...cellNoEdit(clickCell),
    type:'number',
    render: (_, rec) => <>{ comma( calRemain(rec) )}</>,
  },
  {
    title: "ราคา",
    dataIndex: "price",
    key: "price", 
    width: "10%",
    align: "right",
    className: "!pe-3",
    // editable: true,
    ...cellNoEdit(clickCell),
    type:'number',
    render: (_, rec) => <>{ comma( Number(rec?.price ||  0),  2, 2 )}</>,
  },
  {
    title: "ส่วนลด(%)",
    dataIndex: "discount",
    key: "discount",
    width: "10%",
    align: "right",
    className: "!pe-3",
    // editable: true,
    ...cellNoEdit(clickCell),
    type:'number',
    render: (_, rec) => <>{ comma( Number(rec?.discount ||  0),  2, 2 )}</>,
  },
  {
    title: "ราคารวม",
    dataIndex: "total",
    key: "total",
    width: "10%",
    align: "right",
    className: "!pe-3",
    ...cellNoEdit(clickCell),
    render: (_, rec) => <>{ comma( calTotalDiscount(rec),  2, 2 )}</>,
  },
  {
    title: "เลือกสต๊อกสินค้า",
    dataIndex: "action",
    key: "action",
    width: "8%",  
    ...cellNoEdit(false),
    render: action,
  }
];

export const columnDetailNoQuotation = (clickCell, action) => [
    {
      title: "ลำดับ",
      dataIndex: "ind",
      key: "ind",
      width: '8%', 
      ...cellNoEdit(clickCell),
      render: (im, rc, index) => !rc?.sub_item ? <>{index + 1}</> : <GoDotFill />,
    },
    {
      title: "รหัสสินค้า",
      dataIndex: "stcode",
      key: "stcode",
      width: '12%', 
      ...cellNoEdit(clickCell),
      align: "left",
    },
    {
      title: "ชื่อสินค้า",
      dataIndex: "rpodetail",
      key: "rpodetail", 
      align: "left", 
      ...cellNoEdit(clickCell),
      render: (_, rec) => rec.stname,
    },
    {
      title: "ชื่อสินค้าสำหรับสั่งซื้อ",
      dataIndex: "rpodetail",
      key: "rpodetail", 
      align: "left",
      width: '22%',
      editable: true,
      ...onCellEdit(),
      render: (_, rec) => rec?.rpodetail || <span className='not-input'>กรอกข้อมูล</span>,
    },
    {
      title: "จำนวนที่ต้องการ",
      dataIndex: "qty",
      key: "qty", 
      width: "10%",
      align: "right",
      ...cellNoEdit(clickCell),
      // editable: true,
      type:'number',
      render: (_, rec) => <>{ comma( Number(rec?.qty ||  0) )}</>,
    },
    {
      title: "ราคาต่อหน่วย",
      dataIndex: "price",
      key: "price", 
      width: "10%",
      align: "right",
      editable: true,
      type:'number',
      ...onCellEdit(),
      render: (_, rec) => <>{ comma( Number(rec?.price ||  0),  2, 2 )}</>,
    },
    {
      title: "ส่วนลด(%)",
      dataIndex: "discount",
      key: "discount",
      width: "10%",
      align: "right",
      editable: true,
      type:'number',
      ...onCellEdit(),
      // ...onCell(clickCell),
      render: (_, rec) => <>{ comma( Number(rec?.discount ||  0),  2, 2 )}</>,
    },
    {
      title: "ราคารวม",
      dataIndex: "total",
      key: "total",
      width: "10%",
      align: "right",
      className: "!pe-3",
      ...cellNoEdit(clickCell),
      render: (_, rec) => <>{ comma( calTotalDiscount(rec),  2, 2 )}</>,
    },
    {
      title: "เลือกสต๊อกสินค้า",
      dataIndex: "action",
      key: "action",
      width: "8%", 
      ...cellNoEdit(false),
      render: action,
    }
];

export const columnDetailNoQuotationSub = () => [
    {
      title: "ลำดับ",
      dataIndex: "ind",
      key: "ind",
      width: '8%', 
      ...cellNoEdit(false),
      render: (im, rc, index) => <>#</>,
    },
    {
      title: "รหัสสินค้า",
      dataIndex: "stcode",
      key: "stcode",
      width: '12%', 
      ...cellNoEdit(false),
      align: "left",
    },
    {
      title: "ชื่อสินค้า",
      dataIndex: "rpodetail",
      key: "rpodetail", 
      align: "left", 
      ...cellNoEdit(false),
      render: (_, rec) => rec.stname,
    },
    {
      title: "ชื่อสินค้าสำหรับสั่งซื้อ",
      dataIndex: "rpodetail",
      key: "rpodetail", 
      align: "left",
      width: '22%',
      ...cellNoEdit(false),
      render: (_, rec) => rec?.rpodetail || <span className='not-input'>กรอกข้อมูล</span>,
    },
    {
      title: "จำนวนที่ต้องการ",
      dataIndex: "qty",
      key: "qty", 
      width: "10%",
      align: "right",
      ...cellNoEdit(false),
      render: (_, rec) => <>{ comma( Number(rec?.qty ||  0) )}</>,
    },
    {
      title: "ราคาต่อหน่วย",
      dataIndex: "price",
      key: "price", 
      width: "10%",
      align: "right",
      ...cellNoEdit(false),
      render: (_, rec) => <>{ comma( Number(rec?.price ||  0),  2, 2 )}</>,
    },
    {
      title: "ส่วนลด(%)",
      dataIndex: "discount",
      key: "discount",
      width: "10%",
      align: "right",
      ...cellNoEdit(false),
      render: (_, rec) => <>{ comma( Number(rec?.discount ||  0),  2, 2 )}</>,
    },
    {
      title: "ราคารวม",
      dataIndex: "total",
      key: "total",
      width: "10%",
      align: "right",
      className: "!pe-3",
      ...cellNoEdit(false),
      render: (_, rec) => <>{ comma( calTotalDiscount(rec),  2, 2 )}</>,
    }
];

export const columnsDetailsEditable = ( handleSave, column ) => {
  const cols = column;
  const cmm  =  cols.map((col, ind) => { 
    if (!col.editable) return col; 
    return {
      ...col,
      onCell: (record) => {
        // console.log(record);
        return {
          record,
          editable: col.editable && !record?.sub_item,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
          fieldType: !!col?.textArea,
          required: !!col?.required,
          type: col?.type || "input",
          className: !!record?.sub_item ? 'field-edit !bg-gray-200 !pe-4 no-expend' : '',
          // autocompleteOption: col?.autocompleteOption,
        };
      },
    };

  });
  // console.dir( cmm );
  return cmm;
};

export const columnViewDetail = (clickCell = undefined) => [
  {
    title: "ลำดับ",
    dataIndex: "ind",
    key: "ind",
    width: 80, 
    ...cellNoEdit(clickCell),
    render: (im, rc, index) => <>{index + 1}</>,
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "stcode",
    key: "stcode",
    width: 120,
    align: "left",
    ...cellNoEdit(clickCell),
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "stname",
    key: "stname", 
    align: "left", 
    ...cellNoEdit(clickCell),
  },
  {
    title: "ชื่อสินค้าสำหรับสั่งซื้อ",
    dataIndex: "rpodetail",
    key: "rpodetail", 
    align: "left",
    width: "20%",
    ...cellNoEdit(clickCell),
  },
  {
    title: "จำนวน",
    dataIndex: "amount",
    key: "amount", 
    width: "10%",
    align: "right",
    className: "!pe-3",
    ...cellNoEdit(clickCell),
    render: (v) => <>{ comma( Number( v || 0),  2, 2 )}</>,
  },
  {
    title: "ราคาชื้อ",
    dataIndex: "price",
    key: "price", 
    width: "10%",
    align: "right",
    className: "!pe-3",
    ...cellNoEdit(clickCell),
    render: (v) => <>{ comma( Number( v || 0),  2, 2 )}</>,
  },
  {
    title: "ส่วนลด(%)",
    dataIndex: "discount",
    key: "discount",
    width: "10%",
    align: "right",
    className: "!pe-3",
    ...cellNoEdit(clickCell),
    render: (v) => <>{ comma( Number( v || 0),  2, 2 )}</>,
  },
  {
    title: "ราคารวม",
    dataIndex: "total",
    key: "total",
    width: "10%",
    align: "right",
    className: "!pe-4",
    ...cellNoEdit(clickCell),
    render: (_, rec) => <>{ comma( calTotalDiscount({...rec, qty: rec?.amount || 0 }),  2, 2 )} บาท</>,
  }
];


export const infoItem = (val) => [
  {
    key: 'rpodate',
    label: 'วันที่ใบรายการซ่อม',
    children: <Typography.Text>{dayjs(val?.rpodate).format('YYYY/MM/DD')}</Typography.Text>,
  },
  {
    key: 'payment',
    label: 'การชำระเงิน/เครดิสเทอม',
    children: <Typography.Text>{val?.payment}</Typography.Text>,
  },
  {
    key: 'qtcode',
    label: 'เลขใบเสนอราคา',
    children: <Typography.Text>{val?.qtcode || '-'}</Typography.Text>,
  }, 
  {
    key: 'qtdate',
    label: 'วันที่ใบเสนอราคา',
    children: <Typography.Text>{!!val?.qtdate ? dayjs(val?.qtdate).format('YYYY/MM/DD') : '-' }</Typography.Text>,
  },
  {
    key: 'confirm_date',
    label: 'วันที่ยืนยันราคา',
    children: <Typography.Text>{!!val?.confirm_date ? dayjs(val?.confirm_date).format('YYYY/MM/DD') : '-'}</Typography.Text>,
  },
  // {
  //   key: 'isvat',
  //   label: 'ภาษีมูลค่าเพิ่ม (Vat)',
  //   children: <Typography.Text>{Number(val?.vat) > 0 ? "7%" : "0%"}</Typography.Text>,
  // },
  {
    key: 'created_name',
    label: 'ออกเอกสารโดย',
    children: <Typography.Text>{val?.created_name || '-'}</Typography.Text>,
  },
];

//{xs:1, sm:2, md:2, lg:3, xl:4, xxl:4} 
export const cusItem = (val) => [
  {
    key: 'cuscode',
    label: 'รหัสลูกค้า',
    children: <Typography.Text>{val?.cuscode || '\u00A0'}</Typography.Text>,
    span: { xl: 1, xxl: 1 }
    // labelStyle:lableDesStyle
  },
  {
    key: 'cusname',
    label: 'ชื่อลูกค้า', 
    children: <Typography.Text>{val?.cusname || '\u00A0'}</Typography.Text>, 
    span: { xl: 3, xxl: 3 }
    // labelStyle:lableDesStyle
  },
  {
    key: 'taxno',
    label: 'เลขผู้เสียภาษี', 
    children: <Typography.Text>{val?.taxno || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_name',
    label: 'ติดต่อ', 
    children: <Typography.Text>{val?.contact_name || '\u00A0'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_tel',
    label: 'เบอร์โทร', 
    children: <Typography.Text>{val?.contact_tel || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_email',
    label: 'อีเมล', 
    children: <Typography.Text>{val?.contact_email || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'address',
    label: 'ที่อยู่ผู้ขาย', 
    children: <Typography.Text className='text-wrap'>{val?.address || '\u00A0'}</Typography.Text>,
  },
];


export const formData = {
  head: { 
    rpocode: null,
    rpodate: dayjs(),
    cuscode: null,
    cusname: null,
    qtcode: null,
    address: null,
    contact_name: null,
    contact_tel: null,
    contact_email: null,
    taxno: null,
    price_total: 0,
    vat:0,
    vat_percent: 0,
    isvat: false,
    grand_total: 0,
    car_no: null,
    car_province: null,
    car_color: null,
    car_brand: null,
    car_model: null,
    car_type: null,
    hasqt: true, 
    payments: null,
    customer_type: 1,
    stock_submit: false, 
  },
  list: []
}

export const paycredit = [ "เงินสด","30 วัน","45 วัน","60 วัน","90 วัน","120 วัน" ];