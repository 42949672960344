import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom';

function Location() {
    useEffect(() => { 
      return () => { };
    }, []);
    return (<div className='layout-content'><Outlet /></div>)
}

export default Location