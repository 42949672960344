import "antd/dist/reset.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import "./assets/styles/style.css";
import Router from "./route/router";  
import React, { useContext, useLayoutEffect } from 'react';
import { ConfigProvider, App as AntdApp } from "antd";

function App() {    
  const { locale, theme } = useContext(ConfigProvider.ConfigContext);
  useLayoutEffect(() => {
    ConfigProvider.config({
      holderRender: (children) => ( 
          <ConfigProvider prefixCls="static" iconPrefixCls="icon" theme={{ token: {colorPrimary: '#130ab0'}}}>
            <AntdApp message={{ maxCount: 3, top: 80, duration: 2 }} notification={{ maxCount: 1 }} >
              {children}
            </AntdApp>
          </ConfigProvider> 
      ),
    });
  }, [locale, theme]);

  return (
    <div className="App">    
      <Router />  
    </div>
  );
}

export default App;