/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, DatePicker, Form, Input, Space } from 'antd';
import { QctPageLayout } from '../../components/layout/index.js';
import { FormSearchValue, TableSearchValue  } from "../../components/form/index.js";
// import { Typography } from 'antd';
import { accessColumn } from './model.js';
import useSearch from '../../store/hook/use-search.hook.js';
import BillingNoteService from "../../service/BillingNote.service.js"; 
import dayjs from "dayjs"; 
const apirequest = BillingNoteService();

function Access() {
  const navigate = useNavigate();

  const stSearch = useSearch("billing-note-access");
  const { criteria:cria, tbparams:parm } = stSearch.gettingSearch();
  const tbparamsDefault = { ...parm, pagination:  {...{ current: 1, pageSize: 10 }, ...parm?.pagination} };
  const criteriaDefault = {
    ...cria,
    bndate: cria?.bndate?.map(m => dayjs(m)) || null,
    duedate: cria?.duedate?.map(m => dayjs(m)) || null,
  } || { };
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  
  const [criteria, setCriteria] = useState({...criteriaDefault});

  const [tbparams, setTbparams] = useState({...tbparamsDefault});
  const [pageValue, setPageValue] = useState({...tbparamsDefault.pagination}); 
 
  const [viewOnly, setViewOnly] = useState(false);
  const [selected, setSelected] = useState(undefined);


  const formSearch = (
    <FormSearchValue title='ค้นหา' onValues={(value) => handleCriteria(value)} defaultValue={criteriaDefault} >
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="เลขใบวางบิล" name="bncode" >
          <Input placeholder="ใส่เลขใบวางบิล" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
           <Form.Item label='วันที่ใบวางบิล' name='bndate'>
               <DatePicker.RangePicker placeholder={['เริ่มวันที่', 'ถึงวันที่']} style={{width:'100%', height:40}}  />
           </Form.Item>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
           <Form.Item label='วันที่กำหนดชำระ' name='payment_duedate'>
               <DatePicker.RangePicker placeholder={['เริ่มวันที่', 'ถึงวันที่']} style={{width:'100%', height:40}}  />
           </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="รหัสลูกค้า" name="cuscode" >
          <Input placeholder="ใส่รหัสลูกค้า" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="ชื่อลูกค้า" name="cusname" >
          <Input placeholder="ใส่ชื่อลูกค้า" />
        </Form.Item>
      </Col>
            
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="ชื่อผู้ติดต่อ" name="contact" >
          <Input placeholder="ใส่ชื่อผู้ติดต่อ" />
        </Form.Item>
      </Col> 
    </FormSearchValue>
  );

  const handleCriteria = (criteria) => {
    setCriteria(criteria);
    setTbparams(page => ({ 
      ...page, 
      pagination: {...page?.pagination, current: 1},
      current: 1
    }));
  }

  const handlePaging = (page) => {  
    setTbparams(page); 
    // setTbparams(page.pagination); 
  }
  
  const handleSearch = () => {
    
    const [bndate_form, bndate_to] = criteria?.bndate?.map( m => dayjs(m).format("YYYY-MM-DD") ) || [];  
    const [duedate_form, duedate_to] = criteria?.duedate?.map( m => dayjs(m).format("YYYY-MM-DD") ) || []; 

    const newPram = {...criteria, bndate_form, bndate_to, duedate_form, duedate_to};
    const parm = { criteria:newPram, tbparams };
    
    apirequest.search(parm, { ignoreLoading:Object.keys(parm.criteria).length !== 0 } ).then( res => {
      const { data:{ source, tbparams } } = res.data;
      
      stSearch.updateSearch( parm );
      setPageValue(tbparams?.pagination);
      // setPaing( state => ( {...state, ...pagination }) );
      setDataSource(source); 

      const { order, field } = tbparams;
      setTimeout( ()=>{
        setColumns( state => stSearch.columnOrder(state, order, field)); 
      }, 80);
    }).catch( e => console.dir( e ) );
  }

  const handleRowSelect = (bncode, key) => {
    setViewOnly( bncode?.approved_status !== 'N' && !!key ); 
    setSelected( !!key ? bncode : undefined );
  }

  const handleCreatData = () => {
    const config = { mode:"create", acname: "สร้าง" };
    navigate("manage", {state:{config}});
  }
  
  const handleUpdateData = (code, bncode) => { 
    const config = { mode:"update", acname: `แก้ไข #${bncode?.bncode}`, bncode, code };
    navigate("manage", {state:{config}});
  }
  
  const handleReviewData = (code, bncode) => { 
    const config = { mode:"view", acname: `ใบวางบิล #${bncode?.bncode}`, bncode, code };
    navigate("manage", {state:{config}});
  }
  
  const handlePrintsData = (code, bncode) => { 
    // const newWindow = window.open('', '_blank');
    // newWindow.location.href = `/billing-note-print/${code}`;
    const url = `/billing-note-print/${code}/1`;
    const newWindow = window.open('', url, url);
    newWindow.location.href = url;
  }

  useEffect( () => { 
    // console.log( criteriaDefault );
    handleSearch();

    // setMounted(true);
  }, [JSON.stringify(criteria), JSON.stringify(tbparams)]);

  React.useEffect(() => {
    const initeil = async() => {
      

    const { order, field } = tbparamsDefault;
    const cols = accessColumn();
    const colOrder = stSearch.columnOrder(cols, order, field);
    setColumns(colOrder);
  }

  initeil();    

  }, []);

  return (<>
  <QctPageLayout title={"ใบวางบิล"}>
    <Space direction='vertical' className='flex gap-4'>
      { formSearch }
      <TableSearchValue
        title='รายการข้อมูลใบวางบิล'
        pagingDefault={tbparamsDefault?.pagination}
        onPageChange={handlePaging}
        onSelectedRow={handleRowSelect}
        onCreate={handleCreatData}
        onUpdate={ !viewOnly && !!selected && handleUpdateData}
        onReView={  viewOnly && !!selected && handleReviewData}
        onPrints={  viewOnly && !!selected && handlePrintsData}
        // onDelete={handleDeleteData}
        tbProps={{
          rowKey:"bncode",
          dataSource:dataSource,
          columns:columns,
          pagination: pageValue
        }}
      />
    </Space>

  </QctPageLayout>
  </>)
}

export default Access