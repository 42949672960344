import React from "react";
import { Route } from "react-router-dom";

import {
  Suppliers,
  SuppliersAccess,
  SuppliersManage,
} from "../pages/suppliers";
import { Items, ItemsAccess, ItemsManage } from "../pages/item";
import { Itemtype, ItemtypeAccess, ItemtypeManage } from "../pages/itemtype";
import { ItemCategory, ItemCategoryAccess, ItemCategoryManage } from "../pages/itemcategory";
import { Location, LocationAccess, LocationManage } from "../pages/location";
import { Carmodel, CarmodelAccess, CarmodelManage } from "../pages/carmodels";
import { Employee, EmployeeAccess, EmployeeManage } from "../pages/employees";
import { Business, BusinessAccess, BusinessManage } from "../pages/business";
import { Customer, CustomerAccess, CustomerManage } from "../pages/customer";
import { Car, CarAccess, CarManage } from "../pages/cars";
import { Users, UsersAccess, UsersManage } from "../pages/user";

export const DataRouter = (
  <>
    <Route path="/users/" exact element={<Users />}>
      <Route index element={<UsersAccess />} />
      <Route path="manage/:action" element={<UsersManage />} />
    </Route>

    <Route path="/car/" exact element={<Car />}>
      <Route index element={<CarAccess />} />
      <Route path="manage/:action" element={<CarManage />} />
    </Route>

    <Route path="/car/" exact element={<Car />}>
      <Route index element={<CarAccess />} />
      <Route path="manage/:action" element={<CarManage />} />
    </Route>

    <Route path="/customer/" exact element={<Customer />}>
      <Route index element={<CustomerAccess />} />
      <Route path="manage/:action" element={<CustomerManage />} />
    </Route>

    <Route path="/business/" exact element={<Business />}>
      <Route index element={<BusinessAccess />} />
      <Route path="manage/:action" element={<BusinessManage />} />
    </Route>

    <Route path="/employee/" exact element={<Employee />}>
      <Route index element={<EmployeeAccess />} />
      <Route path="manage/:action" element={<EmployeeManage />} />
    </Route>

    <Route path="/suppliers/" exact element={<Suppliers />}>
      <Route index element={<SuppliersAccess />} />
      <Route path="manage" element={<SuppliersManage />} />
      {/* <Route path="view" element={<PilotScaleView />} /> */}
    </Route>

    <Route path="/items/" exact element={<Items />}>
      <Route index element={<ItemsAccess />} />
      <Route path="manage/:action" element={<ItemsManage />} />
      {/* <Route path="create" element={<ItemsCreate />} /> */}
    </Route>

    <Route path="/itemtype/" exact element={<Itemtype />}>
      <Route index element={<ItemtypeAccess />} />
      <Route path="manage/:action" element={<ItemtypeManage />} />
    </Route>
    
    <Route path="/itemcategory/" exact element={<ItemCategory />}>
      <Route index element={<ItemCategoryAccess />} />
      <Route path="manage/:action" element={<ItemCategoryManage />} />
    </Route>

    <Route path="/location/" exact element={<Location />}>
      <Route index element={<LocationAccess />} />
      <Route path="manage/:action" element={<LocationManage />} />
    </Route>

    <Route path="/carmodel/" exact element={<Carmodel />}>
      <Route index element={<CarmodelAccess />} />
      <Route path="manage/:action" element={<CarmodelManage />} />
    </Route>
  </>
);
