import { Layout, Typography } from "antd";

function Footer() {
  const { Footer: AntFooter } = Layout;

  return (
    <AntFooter style={{ background: "#fafafa" }} className="px-4 sm:px-20 lg:px-40 flex-col">
      <div className="h-full flex items-end justify-center" style={{letterSpacing: 1.025}}>
        <Typography.Text className="m-0 text-bold">Copyright 2024-{(new Date().getFullYear())} Jaroon Software Co.,Ltd.</Typography.Text>
      </div>
    </AntFooter>
  );
}

export default Footer;
