import React from 'react'
import { useLocation } from "react-router-dom";
import { Badge } from 'antd'
import PurchaseOrderService from '../../../service/PurchaseOrder.service';

const api = PurchaseOrderService();
const usePrevious = (value) => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const WaitingApprove = ({children, main=false}) => {

    const location = useLocation(); 
    const previousLocation = usePrevious(location);

    const [waiting, setWaiting] = React.useState(0)
        

    React.useEffect( () => { 
        if( location?.pathname === previousLocation?.pathname) return;
        api.waiting().then( res => {  
            const { data } = res.data;
            // console.log( waitcount );
            setWaiting(data?.waitcount || 0);
        }).catch( e => { console.dir( e )});
    }, [location, previousLocation])

    return (
        !main
        ? <Badge count={waiting} overflowCount={5} size='default' offset={[17, 6]} >
            {children}
          </Badge>
        : <Badge dot={waiting > 0} offset={[5, 0]}>
            {children}
          </Badge>
    )
}

export default WaitingApprove;
