import { Badge } from "antd";

export const accessColumn = () => [  
  {
    title: "categorycode",
    dataIndex: "categorycode",
    key: "categorycode",
    width: "20%",
    hidden: true,
  },
  {
    title: "ชื่อหมวดหมู่สินค้า",
    dataIndex: "categoryname",
    key: "categoryname",
    width: "50%",
    hide: false,
  },
  {
    title: "อักษรขึ้นต้นสินค้า",
    dataIndex: "category_precode",
    key: "category_precode",
    width: "20%",
    hide: false,
  },  
  {
    title: "สถานะการใช้งาน",
    dataIndex: "active_status",
    key: "active_status",
    width: "25%",
    hide: false,
    render: (data) => (
      <div>
        {data === "Y" ? (
          <Badge status="success" text="เปิดการใช้งาน" />
        ) : (
          <Badge status="error" text="ปิดการใช้การ" />
        )}
      </div>
    ),
  },
];

export const formData = {
  categorycode: null,
  categoryname: null,
  active_status: null,
};
