import { Button, Card, Col, Drawer, Flex, Form, Row, Space, Typography } from 'antd';
import React from 'react'; 
// import useConfirm from '../../../store/hook/use-confirm.hook';
import { TableSearchValue } from '../../form';
import OptionService from '../../../service/Options.service.js';
import { SelectStockLocation } from '../../select/index.js';
import { myModalStockNormalColumn } from './model.module.js';
import { CloseOutlined } from '@ant-design/icons';

const tbparamsDefault = { pagination: { current: 1, pageSize: 10 } };
const emptyText = { emptyText:<span>ไม่มีข้อมูลสินค้า</span> };
const optrequest = OptionService();
export default function MyDrawerStockNormal({show, close, title="รายการสต๊อก", keySelect=undefined}) {
    // const confirms = useConfirm();

    const [criteria, setCriteria] = React.useState({});

    const [tbparams, setTbparams] = React.useState({...tbparamsDefault});
    const [pageValue, setPageValue] = React.useState({...tbparamsDefault.pagination});

    const headStyle = {
        borderBottom: `2px solid var(--primary)`,
        backgroundColor: `var(--primary-90)`, 
    }

    const bodyStyle = {
        border: `2px solid var(--primary)`
    }

    const footStyle = {
        borderTop: `2px solid var(--primary)`, 
    }
    const [openDrawer, setOpenDrawer] = React.useState(show);
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(true); 
    const [dataSource, setDataSource] = React.useState([]);  


    const handleCancel = () => { 
        setOpenDrawer(false);
    }

    const handleCriteria = (_, criteria) => { 
        setCriteria(criteria);
        setTbparams(page => ({ 
          ...page, 
          pagination: {...page?.pagination, current: 1},
          current: 1
        }));
    }

    const handleSearch = React.useCallback( async () => { 
        const parm = { criteria:{ ...criteria, stcode:keySelect }, tbparams, p:"stock-items" };
        setLoading(true);
        try { 
            const res = await optrequest.optionsStockPost(parm, { ignoreLoading:true } )
            const { data:{ source, tbparams } } = res.data;
            
            setPageValue(tbparams?.pagination); 
            setDataSource(source);

            setTimeout( () => {  
                setLoading(false) ;
            }, 200 );
      } catch(err) {
        setTimeout( () => setLoading(false), 200 );
      } 
    }, [criteria, keySelect, tbparams]);

    React.useEffect(() => {
        handleSearch();
    
    }, [handleSearch]);
 
    const [drawerWidth, setDrawerWidth] = React.useState(50); 
  
    // Adjust drawer width based on window size
    const updateDrawerWidth = () => {
      if (window.innerWidth < 600) {
        setDrawerWidth(100);
      } else if (window.innerWidth < 1024) {
        setDrawerWidth(50);
      } else {
        setDrawerWidth(45);
      }
    };
  
    // Update width on window resize
    React.useEffect(() => {
      updateDrawerWidth();
      window.addEventListener('resize', updateDrawerWidth);
      return () => window.removeEventListener('resize', updateDrawerWidth);
    }, []);

    const modalSearch = (<>
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>

            <Col xs={24} sm={24} lg={24}> 
                <Form.Item label='ประเภทสินค้า' name='location'>
                    <SelectStockLocation keySelected={keySelect} />
                </Form.Item>                     
            </Col>
        </Row>   
    </>);

    const commentFooter = (<>
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
            <Col span={12} className='p-0'>
                <Flex gap={4} justify='start'>
                    {/*  */}
                </Flex>
            </Col>
            <Col span={12} className='p-0'>
                <Flex gap={4} justify='end'>
                    <Button  className='bn-center bn-secondary-outline min-w-32' onClick={() => handleCancel()} >ปิด</Button>
                </Flex>
            </Col>
        </Row>
    </>);
    return (
        <>
            <Drawer
                title={<Typography.Text className='m-0 text-white'>{title}</Typography.Text> }
                // closable={false}
                closeIcon={<CloseOutlined className='text-white' />}
                onClose={()=>handleCancel()}
                open={openDrawer}
                key="comment"
                width={`${drawerWidth}%`}
                footer={commentFooter}
                styles={{ header: headStyle, content:bodyStyle, footer:footStyle }}
                afterOpenChange={(e) => {
                    if(!e){  
                        (typeof close === 'function') && close( false );  
                    }
                }}
            >
                <Space direction="vertical" size="middle" style={{ display: 'flex', position: 'relative'}} className='current'  >
                    <Card style={{backgroundColor:'#f0f0f0' }}>
                        <Form form={form} layout="vertical" autoComplete="off" onValuesChange={handleCriteria} >
                            {modalSearch}
                        </Form>
                    </Card>
                    <Card style={{minHeight:490}}>
                        <TableSearchValue
                            title='รายการข้อมูลสินค้า'
                            multi={true}
                            pagingDefault={tbparamsDefault?.pagination}
                            onPageChange={(page)=>setTbparams(page)} 
                            notSelect={true}
                            tbProps={{
                                rowKey:"key_code",
                                dataSource:dataSource,
                                columns:myModalStockNormalColumn(),
                                pagination: pageValue,
                                locale:{...emptyText},
                                loading
                            }}
                        />
                    </Card>
                </Space> 
            </Drawer>
        </>
    )
}
