import React, { useState, useEffect } from "react";
import {
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Badge,
  Button,
} from "antd";
import { ModalResetPassword } from "../../components/modal/users/modal-reset";
export default function UsersManageForm({
  formName = "form-name",
  submit,
  initeial,
  mode,
}) {
  const [form] = Form.useForm();
  const [openResetModal, setOpenResetModal] = useState(false);
  const formRole = { required: true, message: "กรุณากรอกข้อมูลให้ครบถ้วน!" };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  useEffect(() => {
    //  console.log(initeial);
    if (mode !== "create") {
      form.setFieldsValue(initeial);
    }
  }, [initeial, mode, form]);

  const dividerProp = {
    orientation: "left",
    style: { marginBlock: 10 },
    className: "!border-black",
  };

  const onFinish = (values) => {
    submit(values);
  };

  return (
    <Space direction="vertical" className="w-full">
      <Form
        form={form}
        layout="vertical"
        name={formName}
        autoComplete="off"
        className="w-full"
        // onValuesChange={(_, value)=> setFormValue(value)}
        onFinish={onFinish}
      >
        <Divider {...dividerProp}>รหัสเข้าใช้งาน</Divider>
        <Row className="!mx-0" gutter={[8, 8]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item label="Username" name="username" rules={[formRole]}>
              <Input placeholder="กรอก Username" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item label="Password" name="password" rules={[formRole]}>
              <Input.Password
                size="small"
                disabled={mode === "edit" ? true : false}
                placeholder="กรอก Password"
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={6}
            style={
              mode === "edit" ? { display: "inline" } : { display: "none" }
            }
          >
            <Form.Item label="Reset Password">
              <Button
                className="bn-primary-outline bn-center"
                onClick={() => {
                  setOpenResetModal(true);
                }}
              >
                เปลี่ยนรหัสผ่านใหม่
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Divider {...dividerProp}>ข้อมูลผู้ใช้</Divider>
        <Row className="!mx-0" gutter={[8, 8]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item label="ชื่อ" name="firstname" rules={[formRole]}>
              <Input placeholder="กรอกชื่อของผู้ใช้" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item label="นามสกุล" name="lastname" rules={[formRole]}>
              <Input placeholder="กรอกนามสกุลของผู้ใช้" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item label="ระดับผู้ใช้" name="type" rules={[formRole]}>
              <Select
                size="large"
                placeholder="เลือกระดับผู้ใช้"
                showSearch
                filterOption={filterOption}
                options={[
                  {
                    value: "Admin",
                    label: "Admin",
                  },
                  {
                    value: "พนักงานขาย",
                    label: "พนักงานขาย",
                  },
                  {
                    value: "ธุรการ",
                    label: "ธุรการ",
                  },
                  {
                    value: "จัดซื้อ",
                    label: "จัดซื้อ",
                  },
                  {
                    value: "ช่าง",
                    label: "ช่าง",
                  },
                  {
                    value: "กรรมการ",
                    label: "กรรมการ",
                  },
                  {
                    value: "ผู้จัดการสาขา",
                    label: "ผู้จัดการสาขา",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item label="เบอร์โทร" name="tel">
              <Input placeholder="กรอกเบอร์โทรของผู้ใช้" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item label="E-mail" name="email">
              <Input placeholder="กรอก E-mail ของผู้ใช้" />
            </Form.Item>
          </Col>
          <Divider {...dividerProp}>เพิ่มเติม</Divider>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={6}
            style={
              mode === "edit" ? { display: "inline" } : { display: "none" }
            }
          >
            <Form.Item name="active_status" label="สถานะการใช้งาน">
              <Select
                size="large"
                options={[
                  {
                    value: "Y",
                    label: <Badge status="success" text="เปิดการใช้งาน" />,
                  },
                  {
                    value: "N",
                    label: <Badge status="error" text="ปิดการใช้งาน" />,
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="code">
              <Input type="hidden" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {openResetModal && (
        <ModalResetPassword
          show={openResetModal}
          close={() => {
            setOpenResetModal(false);
          }}
          code={form.getFieldValue("code")}
        />
      )}
    </Space>
  );
}
