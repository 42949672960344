// import { Breadcrumb, theme } from "antd"; 
import { Button, Typography, theme } from "antd"; 
import { TbArrowBackUp } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";

// const { Content } = Layout;

function QctPageLayout({ 
  children, 
  title, 
  back,
  backto,
  footerRight,
  hearderRight,
}){
    const {
      token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const navigate = useNavigate();
    const buttonStyle = {
      minWidth: 100,
    }
    // const containerStyle = {
    //   position: 'relative',
    //   overflow: 'hidden',
    // };
    // const items = [
    //   {
    //     path: '/dashboard',
    //     title: 'Home',
    //   },
    // ];
    // function itemRender(currentRoute, params, items, paths) {
    //   console.log( currentRoute, params, paths, items)
    //   const isLast = currentRoute?.path === items[items.length - 1]?.path;
    
    //   return isLast ? (
    //     <span>{currentRoute.title}</span>
    //   ) : (
    //     <Link to={`/${paths.join("/")}`}>{currentRoute.title}</Link>
    //   );
    // }
    return ( 
    < >
        {/* <div style={containerStyle}> */}
          {/* <Breadcrumb style={{ margin: '16px 0' }} itemRender={itemRender} items={items} /> */}
          <div className="w-full flex flex-wrap mb-3">
            <div className="w-full sm:w-1/2 flex justify-center sm:justify-start">
              {!!title && <Typography.Title level={3} className="!my-3" style={{fontSize:'clamp( 18px, 1vw, 24px)'}}>{title}</Typography.Title>}

            </div>
            <div className="w-full sm:w-1/2 flex justify-end">
              <div className="bn-group flex items-center gap-2 sm:gap-4">
                { back  && 
                <Button
                  style={buttonStyle}
                  className="bn-primary-outline bn-center"
                  onClick={()=> !!backto ? navigate(backto, {replace:true})  : navigate(-1) } 
                  icon={<TbArrowBackUp style={{fontSize:'1rem'}} />}
                >กลับ</Button>}
                { hearderRight }
              </div>
            </div>
          </div>
          <div
            style={{
              background: colorBgContainer,
              minHeight: `calc(100% - 32px - 2.25rem ${((!!back && !!footerRight) ? '- 1.5rem - 40px' : '')}`,
              padding: 24,
              borderRadius: borderRadiusLG,
              // position: 'relative',
              // overflow: 'hidden',
            }}
            className="qtc-form-search"
          >
            {children} 
          </div> 
          <div className="w-full flex flex-wrap">
            {(!!back && !!footerRight) && (<>
              <div className="w-full sm:w-1/2 flex justify-start py-3">

              </div>
              <div className="w-full sm:w-1/2 flex justify-end py-3">
                <div className="bn-group flex items-center gap-2 sm:gap-4">
                    { back  && 
                    <Button
                      style={buttonStyle}
                      className="bn-primary-outline bn-center"
                      onClick={()=> !!backto ? navigate(backto, {replace:true})  : navigate(-1) } 
                      icon={<TbArrowBackUp style={{fontSize:'1rem'}} />}
                    >กลับ</Button>}
                    { footerRight }
                  </div>
                </div>
            </>)}
            </div> 
        {/* </div> */}
    </>);
}

export default QctPageLayout;