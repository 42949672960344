import dayjs from 'dayjs';
import { comma } from '../../utils/util';
import { Typography } from 'antd';
import { PoStatus } from '../../components/badge-and-tag/purchase-order';
const calTotalDiscount = (rec) => {
  const total =  Number(rec?.qty ||  0) * Number(rec?.price ||  0);
  const discount = 1 - ( Number(rec?.discount ||  0) / 100 );

  return total * discount;
};

export const accessColumn = () => [
  {        
      title: "เลขใบสั่งซื้อ",
      dataIndex: "purcode",
      key: "purcode",
      width: 140, 
      align: "left",
      sorter: true, 
  },
  {
      title: "ชื่อผู้ขาย",
      dataIndex: "supcode",
      key: "supcode",
      width: "22%", 
      align: "left",
      sorter: true,
      hide: false,
      render: (_, rec) => `${rec.supname} [${rec.supcode}] `
  },
  {
      title: "ออกใบสั่งซื้อโดย",
      dataIndex: "created_name",
      key: "created_name", 
      width: "18%",
      align: "left",
      hide: false,
      sorter: true,
  },
  {
    title: "วันที่สั่งซื้อ",
    dataIndex: "purdate",
    key: "purdate",
    width: 120,
    align: "left",
    hide: false,
    sorter: true,
    render: (v) => dayjs(v).format("DD/MM/YYYY"),
  },
  {
    title: "วันที่ส่งของ",
    dataIndex: "duedate",
    key: "duedate",
    width: 120,
    align: "left",
    hide: false,
    sorter: true,
    render: (v) => !!v ? dayjs(v).format("DD/MM/YYYY") : "-",
  },
  {
    title: "ยอดรวม",
    dataIndex: "total",
    key: "total",
    width: 120,
    align: "right",
    hide: false,
    className: "!pe-3",
    sorter: true,
    render: (v) => comma(Number(v || 0),2,2),
  },
  {
    title: "Vat",
    dataIndex: "vat",
    key: "vat",
    width: 120,
    align: "right",
    hide: false,
    className: "!pe-3",
    sorter: true,
    render: (v) => comma(Number(v || 0),2,2),
  },
  {
    title: "ยอดรวมสุทธิ",
    dataIndex: "grand_total",
    key: "grand_total",
    width: 120,
    align: "right",
    hide: false,
    className: "!pe-3",
    sorter: true,
    render: (v) => comma(Number(v || 0),2,2),
  },
];  

export const columnDetail = () => [
    {
      title: "ลำดับ",
      dataIndex: "ind",
      key: "ind",
      width: 80,
      render: (im, rc, index) => <>{index + 1}</>,
    },
    {
      title: "รหัสสินค้า",
      dataIndex: "stcode",
      key: "stcode",
      width: 120,
      align: "left",
    },
    {
      title: "ชื่อสินค้า",
      dataIndex: "stname",
      key: "stname",
      align: "left",
    },
    {
      title: "ชื่อสินค้าสำหรับสั่งซื้อ",
      dataIndex: "purdetail",
      key: "purdetail",
      align: "left",
      width: "20%",
    },
    {
      title: "จำนวน",
      dataIndex: "qty",
      key: "qty", 
      width: "10%",
      align: "right",
      className: "!pe-3",
      render: (_, rec) => <>{ comma( Number(rec?.qty ||  0),  2, 2 )}</>,
    },
    {
      title: "ราคาชื้อ",
      dataIndex: "price",
      key: "price", 
      width: "10%",
      align: "right",
      className: "!pe-3",
      render: (_, rec) => <>{ comma( Number(rec?.price ||  0),  2, 2 )}</>,
    },
    {
      title: "ส่วนลด(%)",
      dataIndex: "discount",
      key: "discount",
      width: "10%",
      align: "right",
      className: "!pe-3",
      render: (_, rec) => <>{ comma( Number(rec?.discount ||  0),  2, 2 )}</>,
    },
    {
      title: "ราคารวม",
      dataIndex: "total",
      key: "total",
      width: "10%",
      align: "right",
      className: "!pe-3",
      render: (_, rec) => <>{ comma( calTotalDiscount(rec),  2, 2 )}</>,
    }
]; 

export const formData = {
  head: { 
    pocode: null,
    supcode: null,
    supname: null,
    purdate: null,
    duedate: null,
    qtcode: null,
    payment: null,
    address: null,
    created_date: null,
    updated_date: null,
    created_by: null,
    updated_by: null,
    contact: null,
    taxnumber: null,
    total: 0,
    vat:0,
    grand_total: 0,
  },
  list: []
};

export const infoItem = (val) => [
  {
    key: 'prudate',
    label: 'วันที่สั่งซื้อ',
    children: <Typography.Text>{!!val?.duedate ? dayjs(val?.purdate).format('YYYY/MM/DD') : "-"}</Typography.Text>,
  },
  {
    key: 'durdate',
    label: 'วันที่นัดส่งของ',
    children: <Typography.Text>{!!val?.duedate ? dayjs(val?.duedate).format('YYYY/MM/DD') : "-"}</Typography.Text>,
  },
  {
    key: 'payment',
    label: 'การชำระเงิน/เครดิสเทอม',
    children: <Typography.Text>{val?.payment}</Typography.Text>,
  },
  {
    key: 'qtcode',
    label: 'เลขใบเสนอราคา',
    children: <Typography.Text>{val?.qtcode || '-'}</Typography.Text>,
  },
  {
    key: 'isvat',
    label: 'ภาษีมูลค่าเพิ่ม (Vat)',
    children: <Typography.Text>{!!Number(val?.isvat) > 0 ? "7%" : "0%"}</Typography.Text>,
  },
  {
    key: 'status',
    label: 'สถานะ',
    children:  <PoStatus data={val?.status} />,
  },
];

//{xs:1, sm:2, md:2, lg:3, xl:4, xxl:4} 
export const supItem = (val) => [
  {
    key: 'supcode',
    label: 'รหัสผู้ขาย',
    children: <Typography.Text>{val?.supcode || '\u00A0'}</Typography.Text>,
    span: { xl: 1, xxl: 1 }
    // labelStyle:lableDesStyle
  },
  {
    key: 'supname',
    label: 'ชื่อผู้ขาย', 
    children: <Typography.Text>{val?.supname || '\u00A0'}</Typography.Text>, 
    span: { xl: 3, xxl: 3 }
    // labelStyle:lableDesStyle
  },
  {
    key: 'taxnumber',
    label: 'เลขผู้เสียภาษี', 
    children: <Typography.Text>{val?.taxnumber || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_name',
    label: 'ติดต่อ', 
    children: <Typography.Text>{val?.contact_name || '\u00A0'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_tel',
    label: 'เบอร์โทร', 
    children: <Typography.Text>{val?.contact_tel || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_email',
    label: 'อีเมล', 
    children: <Typography.Text>{val?.contact_email || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'address',
    label: 'ที่อยู่ผู้ขาย', 
    children: <Typography.Text className='text-wrap'>{val?.address || '\u00A0'}</Typography.Text>,
  },
];