import { Select } from 'antd'
import React from 'react'
import { CAR_BRAND_OPTON, filterOption } from '../../../utils/util'

export default function MySelect({name="brand", style={}, className="", onChange, value=null }) {
  const [options, setOptions] = React.useState([]);
  React.useEffect(() =>{ 
    const init = async () => {
      setOptions(CAR_BRAND_OPTON)
    }

    init(); 
  }, []);

  const handleChange = (e, res) => {
    if( typeof onChange === "function") {
      onChange(e, res);
    }
  }
  return (<>
      <Select
        defaultValue={value}
        style={{...{ width: '100%', height: '40px' }, ...style}}
        name={name}
        placeholder="เลือกยี่ห้อ"
        className={className}
        showSearch
        filterOption={filterOption}
        options={options}
        onChange={handleChange}
      />
  </>)
}
