import { Flex, Tag } from 'antd'
import React from 'react'

export default function TagsMulti({data, icon}) {
  return ( <>
    <Flex wrap="wrap" gap={2}>{
        data?.map((str, i) =>
        <Tag icon={icon} color="var(--primary)" key={i} className="m-0 flex flex-nowrap items-center gap-1">
            {str}
        </Tag>
        )
    }</Flex>
    </>)
}
