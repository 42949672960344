import React from 'react'
import { Modal, Card, Form, Typography, Flex } from "antd";
import { Row, Col, Space } from "antd";
import { Input } from "antd";
import { TbSearch, TbFileDollar } from "react-icons/tb";

import { TableSearchValue } from '../../form';
// import OptionService from '../../../service/Options.service.js';
import QuorationService from '../../../service/Quotation.service.js';

import { myModalColumn } from "./model.module.js";
import { useTbSearchContext } from '../../../store/context/table-search.context.js';

// const optrequest = OptionService();
const apirequest = QuorationService();
const tbparamsDefault = { pagination: { current: 1, pageSize: 10 } };
export default function MyModal({show, close, values}) {
    const context = useTbSearchContext();
    const [form] = Form.useForm();
    const [openModal, setOpenModal] = React.useState(show);
    const [loading,  setLoading] = React.useState(true); 

    const [criteria, setCriteria] = React.useState({});

    const [tbparams, setTbparams] = React.useState({...tbparamsDefault});
    const [pageValue, setPageValue] = React.useState({...tbparamsDefault.pagination});

    const [dataSource, setDataSource] = React.useState([]);
    const containerStyle = {
      position: 'relative',
      overflow: 'hidden',
    };    

    const handleClose = () =>{ 
        setOpenModal(false);
        context.clearSelect();
        setTimeout( () => { close(false);  }, 140); 
        //setTimeout( () => close(false), 200 );
    }

    const handleCriteria = (criteria) => { 
        setCriteria(criteria);
        setTbparams(page => ({ 
          ...page, 
          pagination: {...page?.pagination, current: 1},
          current: 1
        }));
    }

    const handleRowSelect = (key, record) => {
        if( !key ) return;
        
        if( !!values ) values( key, record );
        
        handleClose();
    }

    const handleSearch = React.useCallback(() => {
      const parm = { criteria: {...criteria, notrpocode: true }, tbparams };
      setLoading(true);
      try { 
        apirequest.search(parm, { ignoreLoading:true } ).then( res => {
          const { data:{ source, tbparams } } = res.data;
          parm.criteria.tag?.length === 0 && delete parm.criteria.tag;  
          setPageValue(tbparams?.pagination);
          // setPaing( state => ( {...state, ...pagination }) );
          setDataSource(source);

          setLoading(false);
        }).catch( e => { console.log(e) } );
      } catch(err){
        console.log( err );
      } finally {
        setLoading(false); 
      }
    }, [criteria, tbparams]);

    React.useEffect(() => {
        handleSearch();
    
      }, [handleSearch]);

    React.useEffect(() => {
        const initeil = async() => {
            //
        } 
        initeil();     
    
      }, []);

    const modalTitle = (<>
        <Flex align='center' gap={4}>
            <TbFileDollar style={{fontSize: '1rem'}} />
            <Typography.Text className='ms-1 mb-0'>รายการใบเสนอราคา</Typography.Text>
        </Flex>    
    </>);

    const modalSearch = (<>
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
            <Col xs={24} sm={24} lg={12}>
                <Form.Item label="ค้นหา" name='search'  >
                    <Input suffix={<TbSearch />} placeholder='ค้นหาใบเสนอราคา หรือ เลขใบเสนอราคา'/>
                </Form.Item>                        
            </Col>  
        </Row>    
    </>);

    return (
        <>
        <div className='modal-quotation' id="modal-area">
            <Modal
                open={openModal}
                title={modalTitle}
                afterClose={() => handleClose() }
                onCancel={() => setOpenModal(false) } 
                maskClosable={false}
                style={{ top: 0, height: 'calc(100vh - 10px)' }}
                width={840}
                className='modal-loading-type mymodal'
                footer={<></>}
            >
            {/* <Spin spinning={loading} > */}
                <div style={containerStyle}> 
                    <Space direction="vertical" size="middle" style={{ display: 'flex', position: 'relative'}} className='current'  >
                        <Card style={{backgroundColor:'#f0f0f0' }}>
                            <Form form={form} layout="vertical" autoComplete="off" onValuesChange={handleCriteria} >
                                {modalSearch}
                            </Form>
                        </Card>
                        <Card style={{height:'100%'}}>
                            <TableSearchValue
                                context={context}
                                title='รายการข้อมูลผู้ขาย'
                                pagingDefault={tbparamsDefault?.pagination}
                                onPageChange={(page)=>setTbparams(page)}
                                onSelectedRow={handleRowSelect}
                                tbProps={{
                                    rowKey:"qtcode",
                                    dataSource:dataSource,
                                    columns:myModalColumn(),
                                    pagination: pageValue,
                                    loading
                                }}
                            />
                        </Card>
                    </Space>      
                    {/* { openManage && 
                        <ModalShippingTypeView 
                            open={openManage} 
                            close={()=>setOpenManage(false)} 
                            source={sourceData}
                        />
                    }*/}
                </div>

            {/* </Spin> */}
            </Modal>                
        </div>

        </>
    )
}

