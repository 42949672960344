import React from 'react'
import { Button, Input, Space } from 'antd'
import { TbSearch } from "react-icons/tb";

import MyModal from './MyModal';
import { TbSearchProvider } from '../../../store/context/table-search.context';
export default function MyModalInput({ selected, placeholder=null, className="", id, value }) {
    const [open, setOpen] = React.useState(false);
    const [valueData, setValueData] = React.useState({supcode: value});

    const handleChoosed = ( value, key ) => {
        // console.log( {key, value} );
        if( !!value )
        setValueData( value );
        // setOpen(false);
        !!selected && selected( value );
    }

    // React.useEffect()
    return (
        <div>
            <Space.Compact style={{ width: '100%' }}>
                <Input disabled 
                    placeholder={ placeholder || 'Select Loading Type'}
                    id={ id || "input-quotation" } 
                    value={value || valueData.supcode}
                    className={`input-30 ${className}`}
                />
                <Button 
                    type="primary" 
                    className='bn-center' 
                    icon={<TbSearch style={{fontSize: '1rem'}} />} 
                    onClick={() => setOpen(true)} style={{minWidth:40}} 
                ></Button>
            </Space.Compact>

            { open && 
                <TbSearchProvider>
                    <MyModal show={open} values={handleChoosed} close={() => setOpen(false)} /> 
                </TbSearchProvider>
            }
        </div>

    )
}
