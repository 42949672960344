import React from 'react'
import { Badge, Card, Form } from "antd";
import { Row, Col, Space } from "antd";
import { Input } from "antd";
import { TbSearch } from "react-icons/tb";

import { TableSearchValue } from '../../form';
import OptionService from '../../../service/Options.service.js';
import { myModalColumn } from "./model.module.js";
import { useTbSearchContext } from '../../../store/context/table-search.context.js';
import { MinusCircleOutlined } from '@ant-design/icons';

const optrequest = OptionService();
const tbparamsDefault = { pagination: { current: 1, pageSize: 10 } };
export default function MyModalAppBusiness({values, countRpo = false}) {
    const context = useTbSearchContext();
    const [form] = Form.useForm();
    const [loading,  setLoading] = React.useState(true); 

    const [criteria, setCriteria] = React.useState({});

    const [tbparams, setTbparams] = React.useState({...tbparamsDefault});
    const [pageValue, setPageValue] = React.useState({...tbparamsDefault.pagination}); 

    const [dataSource, setDataSource] = React.useState([]);
    const containerStyle = {
      position: 'relative',
      overflow: 'hidden',
    };

    const handleCriteria = (criteria) => { 
        
        setCriteria(criteria);
        setTbparams(page => ({ 
          ...page, 
          pagination: {...page?.pagination, current: 1},
          current: 1
        }));
    }

    const handleRowSelect = (record, key) => {
        if( !key ) return;
        
        if( !!values ) values( record, key ); 
    }

    const columns = () => {
        if(!!countRpo) {
            return [
                ...myModalColumn(),
                {
                    title: "ใบรายการซ่อม",
                    dataIndex: "count_rpo",
                    key: "count_rpo", 
                    width: 140, 
                    align: "center",
                    sorter: true,
                    render: (_,res) => {
                        return !!res.count_rpo && res.count_rpo > 0 
                        ? <Badge
                            className="site-badge-count-109"
                            count={res.count_rpo}
                            overflowCount={10}
                            style={{ backgroundColor: '#f5222d' }}
                        />
                        : <Badge count={<MinusCircleOutlined style={{ color: '#9f9f9f' }} />} />
                    }
                }
            ]
        }else return myModalColumn()
    }

    const handleSearch = React.useCallback(() => {
      const parm = { criteria, tbparams, p:"customer-business" };
      setLoading(true);
      try { 
        optrequest.optionsQuotationPost(parm, { ignoreLoading:true } ).then( res => {
            const { data:{ source, tbparams } } = res.data;
            parm.criteria.tag?.length === 0 && delete parm.criteria.tag;
            setPageValue(tbparams?.pagination);
            // setPaing( state => ( {...state, ...pagination }) );
            setDataSource(source);

            setLoading(false);
        }).catch( r =>{
            throw new Error(r); 
        })
      } catch(err){
        console.log( err ); 
      } finally {
        setLoading(false); 
      }
    }, [criteria, tbparams]);

    React.useEffect(() => {
        handleSearch();
    
      }, [handleSearch]);

    React.useEffect(() => {
        const initeil = async() => { 
        } 
        initeil();     
    
      }, []);

    const modalSearch = (<>
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
            <Col xs={24} sm={24} lg={24}>
                <Form.Item label="ค้นหา" name='search'  >
                    <Input suffix={<TbSearch />} placeholder='ค้นหาข้อมูลลูกค้า บริษัท'/>
                </Form.Item>
            </Col> 
        </Row>    
    </>);

    return (<>
    <div style={containerStyle}> 
        <Space direction="vertical" size="middle" style={{ display: 'flex', position: 'relative'}} className='current'  >
            <Card style={{backgroundColor:'#f0f0f0' }}>
                <Form form={form} layout="vertical" autoComplete="off" onValuesChange={handleCriteria} >
                    {modalSearch}
                </Form>
            </Card>
            <Card style={{height:'100%'}}>
                <TableSearchValue
                    context={context}
                    title='รายการข้อมูล ลูกค้าบริษัท'
                    pagingDefault={tbparamsDefault?.pagination}
                    onPageChange={(page)=>setTbparams(page)}
                    onSelectedRow={handleRowSelect}
                    tbProps={{
                        rowKey:"cuscode",
                        dataSource:dataSource,
                        columns:columns(),
                        pagination: pageValue,
                        loading
                    }}
                />
            </Card>
        </Space>     
    </div> 
    </>)
}