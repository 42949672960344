import {
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tabs,
  Table,
  Badge,
} from "antd";
import React, { useState, useEffect } from "react";
import OptionService from "../../service/Options.service";
import ItemService from "../../service/Item.service";

import { columnsdetail } from "./model";

const opService = OptionService();
const itemService = ItemService();

export default function ItemsManageForm({
  formName = "form-name",
  submit,
  initeial,
  mode,
  checkStep,
}) {
  const [form] = Form.useForm();
  const formRole = { required: true, message: "กรุณากรอกข้อมูลให้ครบถ้วน!" };
  const [optionType, setOptionType] = useState([]);
  // const [optionCategory, setOptionCategory] = useState([]);

  useEffect(() => {
    GetItemsType();

    // GetItemsCategory();
    if (mode !== "create") {
      form.setFieldsValue(initeial);
    } else {
      
      form.setFieldsValue({
        typecode: checkStep.typecode,
      });
      itemService
        .getcode(checkStep.typecode)
        .then((res) => {
          let { data } = res;
          form.setFieldsValue({
            stcode: data,
          });
        })
        .catch((err) => {});
    }
  }, [initeial, mode, checkStep, form]);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const dividerProp = {
    orientation: "left",
    style: { marginBlock: 10 },
    className: "!border-black",
  };
  const GetItemsType = () => {
    opService.optionsItemstype().then((res) => {
      let { data } = res.data;
      setOptionType(data);
    });
  };

  const Getstcode = () => {    
    itemService
    .getcode(form.getFieldValue("typecode"))
    .then((res) => {
      let { data } = res;
      form.setFieldsValue({
        stcode: data,
      });
    })
    .catch((err) => {});
  };

  const onFinish = (values) => {
    const itype = optionType?.find((f) => f.typecode === values?.typecode);

    const reponse = { ...values, categorycode: itype?.categorycode };

    // console.log(reponse);
    submit({ ...reponse });
  };
  const info = (
    <>
      <Divider {...dividerProp}>ข้อมูลสร้างสินค้า</Divider>
      <Row className="!mx-0" gutter={[8, 8]}>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item label="ประเภทสินค้า" name="typecode" >
            <Select
              size="large"
              showSearch
              filterOption={filterOption}
              placeholder="เลือกประเภทสินค้า"              
              onChange={Getstcode}          
              disabled
              options={optionType.map((item) => ({
                value: item.typecode,
                label: item.typename,
              }))}
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item label="รหัสสินค้า" name="stcode" >
            <Input placeholder="กรอกรหัสสินค้า" readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Form.Item label="ชื่อสินค้า" name="stname" rules={[formRole]}>
            <Input placeholder="กรอกชื่อสินค้า" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Form.Item name="material_code" label="Material code">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="stcode_vat" label="รหัสสินค้าคลัง VAT">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="categorycode">
              <Input type="hidden" />
            </Form.Item>
          </Col>
      </Row>
    </>
  );
  const details_tab1 = (
    <>
      <Divider {...dividerProp}>รายละเอียดสินค้า</Divider>
      <Row className="!mx-0" gutter={[8, 8]}>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="stname_vat" label="ชื่อเปิดบิล VAT">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="brand" label="ยี่ห้อ">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="stname_per" label="ชื่อสินค้า/ดอก">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="stfront" label="รุ่น1/หน้า">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="stseries" label="รุ่น2/ซี่รี่ย์">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="stborder" label="รุ่น3/ขอบ">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="stload" label="รุ่น4/โหลด">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="stspeed" label="รุ่น5/สปีด">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="sttw" label="รุ่น6/TW">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="stweight" label="รุ่น7/น้ำหนัก">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="stwidth" label="รุ่น8/กว้าง">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="stlong" label="รุ่น9/ยาว">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="sthigh" label="รุ่น10/สูง">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="stchange_round" label="รอบการเปลี่ยน">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="stchange_time" label="เวลาในการเปลี่ยน">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="stcar_brand" label="ยี่ห้อรถ">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="stcar_model" label="รุ่นรถ">
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
  const other = (
    <>
      <Divider {...dividerProp}>เพิ่มเติม</Divider>
      <Row className="!mx-0" gutter={[8, 8]}>
        <Col xs={24} sm={12} lg={12}>
          <Form.Item label="หมายเหตุ" name="remark">
            <Input.TextArea placeholder="กรอกข้อมูลเพิ่มเติม" rows={4} />
          </Form.Item>
        </Col>
        <Col
          xs={24}
          sm={12}
          lg={6}
          style={mode === "edit" ? { display: "inline" } : { display: "none" }}
        >
          <Form.Item label="สถานการใช้งาน" name="active_status">
            <Select
              size="large"
              options={[
                {
                  value: "Y",
                  label: <Badge status="success" text="เปิดการใช้งาน" />,
                },
                {
                  value: "N",
                  label: <Badge status="error" text="ปิดการใช้งาน" />,
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
  const price = (
    <>
      <Divider {...dividerProp}>ราคา</Divider>
      <Row className="!mx-0" gutter={[8, 8]}>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="price" label="ราคาขายปลีก">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="price_A" label="ราคาส่ง A">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="price_B" label="ราคาส่ง B">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="price_online" label="ราคา Online">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item name="min" label="สต๊อกขั้นต่ำ (ชิ้น)">
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
  const stock = (
    <>
      <Divider {...dividerProp}>สต๊อก</Divider>
      <Row className="!mx-0" gutter={[8, 8]}>
        <Col xs={24} sm={8} lg={8}>
          <Form.Item name="สต๊อกจริง" label="สต๊อกจริง">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8} lg={8}>
          <Form.Item name="สต๊อก VAT" label="สต๊อก VAT">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8} lg={8}>
          <Form.Item name="จำนวนขายต่อชุด" label="จำนวนขายต่อชุด">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Table
        size="small"
        columns={columnsdetail}
        // dataSource={AllItems}
        rowKey="stcode"
      />
    </>
  );
  const tabdata = [
    {
      key: "ข้อมูลพิ้นฐาน",
      label: "ข้อมูลพิ้นฐาน",
      children: (
        <div>
          {info}
          {details_tab1}
          {other}
        </div>
      ),
    },
    {
      key: "ราคา",
      label: "ราคา",
      children: <div>{price}</div>,
    },
    {
      key: "สต๊อก",
      label: "สต๊อก",
      children: <div>{stock}</div>,
    },
  ];

  return (
    <Space direction="vertical" className="w-full">
      <Form
        form={form}
        layout="vertical"
        name={formName}
        autoComplete="off"
        className="w-full"
        // onValuesChange={(_, value)=> setFormValue(value)}
        onFinish={onFinish}
      >
        <Tabs type="card" defaultActiveKey="ข้อมูลพิ้นฐาน" items={tabdata} />
      </Form>
    </Space>
  );
}
