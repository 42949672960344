/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext, 
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'; 
import { ComponentDrag } from '../../table/TableEditable.js';

import { Modal, Card, Form, Typography, Flex, Button, Drawer, Tooltip } from "antd";
import { Row, Col, Space } from "antd";
import { Input } from "antd";
import { TbSearch, TbClipboardList, TbCheck } from "react-icons/tb"; 
import { TableSearchValue } from '../../form/index.js';
// import OptionService from '../../../service/Options.service.js'; 
// import { v4 as uuidv4 } from 'uuid';

import { useTbSearchContext } from '../../../store/context/table-search.context.js';
import { myModalColumn } from "./model.module.js"; 
import useConfirm from '../../../store/hook/use-confirm.hook.js';

// const optrequest = OptionService(); 
const tbparamsDefault = { pagination: { current: 1, pageSize: 10 } };
const emptyText = { emptyText:<span>ไม่มีข้อมูลสินค้า</span> };
export default function MyModalItems({show, close, values, selected=[], source=[]}) {
    const containerStyle = {
      position: 'relative',
      overflow: 'hidden',
      height: '100%'
    };
    
    const confirms = useConfirm();
    const context = useTbSearchContext();
    const [form] = Form.useForm(); 

    const [openModal, setOpenModal] = React.useState(show);
    const [openViewSelected, setOpenViewSelected] = React.useState(false);
    const [isViewOpened, setIsViewOpened] = React.useState(false);

    // const [criteria, setCriteria] = React.useState({});

    // const [tbparams, setTbparams] = React.useState({...tbparamsDefault});
    // const [pageValue, setPageValue] = React.useState({...tbparamsDefault.pagination});
    const [dataSource, setDataSource ] = React.useState(source);
    const [dataSourceWrap, setDataSourceWrap] = React.useState([]);

    const [dataSelect, setDataSelect] = React.useState([...selected]);

    // const [inited, setInited] = React.useState(false);
    const keySelected = React.useMemo(() => { 
        setDataSource( s => s.map( m => ({...m, disabled: dataSelect.some( sm => sm.rpocode === m.rpocode )})))
        setDataSourceWrap(s => s.map( m => ({...m, disabled: dataSelect.some( sm => sm.rpocode === m.rpocode )})));
        // console.log('Calculating average...');
      const keys = selected.map( key  => key?.rpocode );
      return keys;
    }, [selected]); 

    const onDragEnd = ({ active, over }) => {
        // console.log( active, over  );
        if (active.id !== over?.id) {
            setDataSelect((previous) => {
                const activeIndex = previous.findIndex((i) => i.rpocode === active.id);
                const overIndex = previous.findIndex((i) => i.rpocode === over?.id);
                return arrayMove(previous, activeIndex, overIndex);
            });
        }
    }; 

    const sensors = useSensors(
        useSensor(PointerSensor, {
          activationConstraint: {
            // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
            distance: 1,
          },
        }),
    );

    const handleClose = () =>{ 
        setOpenModal(false);

        setTimeout( () => { close(false);  }, 140); 
        //setTimeout( () => close(false), 200 );
    }

    const handleCriteria = (_, criteria) => { 
        const { search: value } = criteria;
        // setCriteria(criteria);
        setDataSourceWrap( s => dataSource.filter((d) => {
          const text =
            d.rpocode?.includes(value) ||
            d.invcode?.includes(value) ||
            d.rpodate?.includes(value);
          return text;
        }))
        // setTbparams(page => ({ 
        //   ...page, 
        //   pagination: {...page?.pagination, current: 1},
        //   current: 1
        // }));
    }

    const handleRowSelect = (record, key) => { 
        const newData = [...dataSelect, ...record];
        setDataSource( s => s.map( m => ({...m, disabled: newData.some( sm => sm.rpocode === m.rpocode )})))
        setDataSourceWrap(s => s.map( m => ({...m, disabled: newData.some( sm => sm.rpocode === m.rpocode )})));
        setDataSelect(newData); 

        context.clearSelect();
        // handleClose();
    }

    const handleChoosed = async () => {
        const result = await confirms.confirm({content: 'คุณต้องการสร้างใบวางบิลหรือไม่'}); 
        if( !result ) return;

        typeof values === "function" && values( dataSelect ); 
        handleClose();
    } 

    const handleOpenViewItems = () => {
        setOpenViewSelected(true);
        setIsViewOpened(true); 
    }

    React.useEffect(() => {
        const initeil = async() => {   
            // setInited(true);
            setDataSourceWrap(dataSource);
            // setCriteria( state => ({...state})) 
        } 
        initeil();
        return () => {   };
      }, []);

    React.useEffect(() => {
        const initeil = async() => {   
            // setInited(true);
            setDataSourceWrap(dataSource);
            // setCriteria( state => ({...state})) 
        } 
        initeil();
        return () => {   };
      }, [dataSource]);
 
    const handleDelete = async (key) => {
        const result = confirms.deleted();
        if(!result) return;
        
        const newData = [...dataSelect.filter( soc => soc.rpocode !== key)]
        setDataSelect(newData); 
        setDataSource( s => s.map( m => ({...m, disabled: newData.some( sm => sm.rpocode === m.rpocode )})))
        setDataSourceWrap(s => s.map( m => ({...m, disabled: newData.some( sm => sm.rpocode === m.rpocode )})));
    }

    const modalTitle = (<>
        <Flex align='center' gap={4}>
            <TbClipboardList style={{fontSize: '1rem'}} />
            <Typography.Text className='ms-1 mb-0'>รายการใบรายการซ่อม</Typography.Text>
        </Flex>
    </>);

    const modalFooter = (<> 
        <Row>
          <Col span={24}>{/* Ignore */}</Col>
          <Col span={24}>
            <Flex justify="flex-end">
              <Button
                className="bn-center bn-primary"
                icon={<TbCheck />}
                onClick={() => handleChoosed()}
              >ยืนยัน</Button>
            </Flex>
          </Col>
        </Row> 
    </>);


    const modalSearch = (<>
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
            <Col xs={24} sm={24} lg={10}>
                <Form.Item label="ค้นหา" name='search'  >
                    <Input suffix={<TbSearch />} placeholder='ค้นหาข้อมูลใบรายการซ่อม'/>
                </Form.Item>                        
            </Col>  
        </Row>    
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
            <Col xs={24} sm={24} lg={12}>
                <Flex className='justify-start'>
                    <Tooltip placement="topLeft" title={`คลิกที่ข้อความเพื่อดูสินค้าที่เลือกทั้งหมด`} arrow={{pointAtCenter: true}}>
                        <Typography.Link onClick={handleOpenViewItems}>ใบรายการซ่อมที่เลือก {dataSelect.length} รายการ.</Typography.Link> 
                    </Tooltip>
                </Flex>
            </Col> 
            <Col xs={24} sm={24} lg={12}> 
                <Flex className='justify-start sm:justify-end'>
                    {/* <Typography.Link>มีสินค้าที่เลือกแล้ว {selected.length} รายการ.</Typography.Link> */}
                </Flex>
            </Col>            
        </Row>
    </>);

    return (<> 
    {/* <div className='modal-billing-note' id="modal-area">  */}
        <Modal
            open={openModal}
            title={modalTitle}
            afterClose={() => handleClose() }
            onCancel={() => setOpenModal(false) } 
            maskClosable={false}
            // style={{ top: 10, height: 'calc(100vh - 10px)' }}
            style={{ top: 10 }}
            width={960}
            className='mymodal'
            footer={modalFooter}
            // containerStyle={{ height: 'calc(100vh - ( 10px * 2) )' }}
        >
            <div style={containerStyle}>  
                <Space direction="vertical" size="middle" style={{ display: 'flex', position: 'relative'}} className='current'  >
                    <Card style={{backgroundColor:'#f0f0f0' }}>
                        <Form form={form} layout="vertical" autoComplete="off" onValuesChange={handleCriteria} >
                            {modalSearch}
                        </Form>
                    </Card>
                    <Card style={{minHeight:490}}>
                        <TableSearchValue 
                            title='รายการข้อใบรายการซ่อม'
                            multi={true}
                            pagingDefault={tbparamsDefault?.pagination}
                            // onPageChange={(page)=>setTbparams(page)}
                            onSelectedRow={handleRowSelect}
                            tbProps={{
                                rowKey:"rpocode",
                                dataSource:dataSourceWrap,
                                columns:myModalColumn(),
                                // pagination: pageValue, 
                            }}
                        />
                    </Card>
                </Space>      
                {isViewOpened && 
                    <Drawer
                        title={`ใบรายการซ่อมที่เลือกทั้งหมด ${dataSelect.length} รายการ`}
                        onClose={() => { setOpenViewSelected(false); }}
                        open={openViewSelected} 
                        width='100%'
                        className="responsive-drawer" 
                        afterOpenChange={(e)=>{ !e && setIsViewOpened(false); }}
                        getContainer={false}
                    >
                        <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                            <SortableContext items={keySelected} strategy={verticalListSortingStrategy}>
                                <TableSearchValue 
                                    title='รายการใบรายการซ่อมที่เลือกแล้ว'
                                    provider={false}
                                    onDelete={handleDelete}
                                    tbProps={{
                                        components:ComponentDrag,
                                        rowKey:"rpocode",
                                        dataSource:dataSelect,
                                        columns:[
                                            ...myModalColumn().map( m => ({...m,  sorter:false})), 
                                        ],
                                        pagination:{position:['none']},
                                        locale:{...emptyText}
                                    }}
                                /> 
                            </SortableContext>
                        </DndContext>
                    </Drawer>
                } 
            </div>
        </Modal>
    {/* </div>          */}
    </>)
}