/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { QctPageLayout } from "../../components/layout";
import { TbCheck } from "react-icons/tb";
import { Button, message } from "antd";
import UsersManageForm from "./UsersManageForm";

import UserService from "../../service/User.service";

import { formData } from "./model";

const formName = "users-form";
const apirequest = UserService();
function UsersManage() {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    config: { mode, acname, code },
  } = location.state || { config: null };

  const [formValue, setformValue] = useState(formData);

  const handleSubmit = async (value) => {
    const actions =
    mode !== "create"
        ? apirequest.update(value)
        : apirequest.create(value);
    const restext =
      mode !== "create" ? "Request edit done" : "Request create done";
    await actions
      .then(async (r) => {
        navigate(-1);
        message.success(restext);        
      })
      .catch((err) => {
        console.log(err);

        message.error("Error request.");
      });
  };

  useEffect(() => {
    const initeil = async () => {
      try {
        if (mode !== "create" && !!code) {
          const res = await apirequest.get(code);
          const { data } = res.data;
          setformValue(data);

          // console.log(data);
        }
      } catch (er) {
        console.log(er);

        message.error("error request.");
      }
    };

    initeil();
  }, [mode, code]);

  const frButton = (
    <>
      <Button
        icon={<TbCheck style={{ fontSize: "1rem" }} />}
        className="bn-center bn-primary"
        form={formName}
        htmlType="submit"
      >
        ยืนยัน/บันทึก
      </Button>
    </>
  );
  return (
    <>
      <QctPageLayout
        title={`ระบบผู้ใช้ - ${acname}`}
        back={true}
        hearderRight={frButton}
      >
        <UsersManageForm
          formName={formName}
          submit={handleSubmit}
          initeial={formValue}
          mode={mode}
        />
      </QctPageLayout>
    </>
  );
}

export default UsersManage;
