import { Badge } from "antd";

export const accessColumn = () => [  
  {
    title: "รหัสพนักงาน",
    dataIndex: "empcode",
    key: "empcode",
    width: "10%",
    hide: false,
  },
  {
    title: "ชื่อ",
    dataIndex: "firstname",
    key: "firstname",
    width: "15%",
    hide: false,
  },
  {
    title: "นามสกุล",
    dataIndex: "lastname",
    key: "lastname",
    width: "15%",
    hide: false,
  },
  {
    title: "เบอร์โทร",
    dataIndex: "tel",
    key: "tel",
    width: "15%",
    hide: false,
  },
  {
    title: "ตำแหน่ง",
    dataIndex: "position",
    key: "position",
    width: "15%",
    hide: false,
  },
  {
    title: "สถานะการใช้งาน",
    dataIndex: "active_status",
    key: "active_status",
    width: "20%",
    hide: false,
    render: (data) => (
      <div>
        {data === "Y" ? (
          <Badge status="success" text="เปิดการใช้งาน" />
        ) : (
          <Badge status="error" text="ปิดการใช้การ" />
        )}
      </div>
    ),
  },
];

export const formData = {
  employee_code: null,
  employee_name: null,
  active_status: null,
};
