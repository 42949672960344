export const MEDTHOD = {
  GET: "get",
  POST: "post",
  PATCH: "patch",
  DELETE: "delete",
  PUT: "put",
};

export const ROLES = {
  ADMIN: "admin",
  USER: "user",
};

export const LAYOUT = {
  ALOND: "alond",
  CHILD: "child",
};

export const PROJECT_KEY = "70e08856-0604-11ef-a7eb-0242ac150002"; //

export const STORE_KEY = {
  authen : `${PROJECT_KEY}-authen-qct`,
  current : `${PROJECT_KEY}-current-qct`
}

export const PAYMENT_CONDITION = [
  {    
    label:"เงินสด",
    value:"เงินสด",
  },
  {
    label:"เครดิต 30 วัน",
    value:"เครดิต 30 วัน",
  },
  {
    label:"เครดิต 45 วัน",
    value:"เครดิต 45 วัน",
  },
  {
    label:"เครดิต 60 วัน",
    value:"เครดิต 60 วัน",
  },
  {
    label:"เครดิต 90 วัน",
    value:"เครดิต 90 วัน",
  },
  {
    label:"เครดิต 120 วัน",
    value:"เครดิต 120 วัน",
  },
]