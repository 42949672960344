import { Form, Input, message, Typography } from "antd";
import { comma } from "../../../utils/util"; 

export const cusItem = (val) => [
  {
    key: 'cuscode',
    label: 'รหัสลูกค้า',
    children: <Typography.Text>{val?.cuscode || '\u00A0'}</Typography.Text>,
    span: { xl: 1, xxl: 1 }
    // labelStyle:lableDesStyle
  },
  {
    key: 'cusname',
    label: 'ชื่อลูกค้า', 
    children: <Typography.Text>{val?.cusname || '\u00A0'}</Typography.Text>, 
    span: { xl: 3, xxl: 3 }
    // labelStyle:lableDesStyle
  },
  {
    key: 'taxnumber',
    label: 'เลขผู้เสียภาษี', 
    children: <Typography.Text>{val?.taxnumber || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_name',
    label: 'ติดต่อ', 
    children: <Typography.Text>{val?.contact_name || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_tel',
    label: 'เบอร์โทร', 
    children: <Typography.Text>{val?.contact_tel || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_email',
    label: 'อีเมล', 
    children: <Typography.Text>{val?.contact_email || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'address',
    label: 'ที่อยู่ลูกค้า', 
    children: <Typography.Text className='text-wrap'>{val?.address || '-'}</Typography.Text>,
  },
];

export const infoVat = (val) => [
    {
      key: 'invcode',
      label: <>เลขที่ใบกำกับภาษี { !val.invcode && <strong className="ms-1 text-red-500">*</strong> }</>,
      // span: { xl: 1, xxl: 1, lg: 1, md: 1 },
      // contentStyle: { paddingInline: '0 2rem'},
      children: (
        !!val.invcode ? <Typography.Text>{val?.invcode || '\u00A0'}</Typography.Text>
        :<>
          <Form.Item className="!mb-0 w-full"  name='invcode'  rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' }]}>
              <Input placeholder='กรอกเลขที่ใบกำกับภาษี' maxLength={7} />
          </Form.Item>        
        </>
      ),
    },
    { key: 'no-invcode' }
];

const onCell = () => ({
  onCell: (recoed, index) => ({
    className: `field-edits hover:!bg-white`,  
  })
});

// const calTotalDiscount = (rec) => {
//   const total =  Number(rec?.qty ||  0) * Number(rec?.price ||  0);
//   const discount = 1 - ( Number(rec?.discount ||  0) / 100 ); 
//   return total * discount;
// };

export const columnViewDetail = (clickCell = undefined) => [
  {
    title: "ลำดับ",
    dataIndex: "ind",
    key: "ind",
    width: 60, 
    style: { borderStartStartRadius: 0},
    ...onCell(clickCell),
    render: (im, rc, index) => <>{index + 1}</>,
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "stcode",
    key: "stcode",
    width: 120,
    align: "left",
    ...onCell(clickCell),
  },
  {
    title: "ชื่อสินค้าสำหรับสั่งซื้อ",
    dataIndex: "rpodetail",
    key: "rpodetail", 
    align: "left",
    ...onCell(clickCell),
  },
  {
    title: "จำนวน",
    dataIndex: "amount",
    key: "amount", 
    width: "10%",
    align: "right",
    className: "!pe-3",
    ...onCell(clickCell),
    render: (v) => <>{ comma( Number( v || 0),  2, 2 )}</>,
  },
  {
    title: "ราคาชื้อ",
    dataIndex: "price",
    key: "price", 
    width: "10%",
    align: "right",
    className: "!pe-3",
    ...onCell(clickCell),
    render: (v) => <>{ comma( Number( v || 0),  2, 2 )}</>,
  },
  {
    title: "ส่วนลด(%)",
    dataIndex: "discount",
    key: "discount",
    width: "10%",
    align: "right",
    className: "!pe-3",
    ...onCell(clickCell),
    render: (v) => <>{ comma( Number( v || 0),  2, 2 )}</>,
  },
  {
    title: "ราคารวม",
    dataIndex: "price_total",
    key: "price_total",
    width: "10%",
    align: "right",
    className: "!pe-3",
    ...onCell(clickCell), 
    // render: (_, rec) => <>{ comma( calTotalDiscount({...rec, qty: rec?.amount || 0 }),  2, 2 )} บาท</>,
    render: (v) => <>{ comma( Number( v || 0 ),  2, 2 )} บาท</>,
  },
  {
    title: "สต๊อก vat",
    dataIndex: "qty_vat",
    key: "qty_vat",
    width: "10%",
    align: "right",
    className: "!pe-3",
    style:{borderStartEndradius: 0},
    render: (_, rec) => <>{ stockVatRender( rec )}</>,
  }
];

const stockVatRender = (rec) => {
  const { stock_by_product, count_stock, amount, qty_vat } = rec;
  const has_asone = Number( stock_by_product || 0 ) === 1;
  const has_count = Number( count_stock || 0 ) === 1 && ( has_asone || true ); // ไม่ชัวว่าใช่มั้ยเลย or true ไว้ก่อน
  const has_stock = Number( qty_vat || 0 ) >= Number( amount || 0 );

  if ( has_count && has_stock ) return comma( rec?.qty_vat );
  else if( has_count &&!has_stock ) return <span>สต๊อกไม่พอ</span>
  else return "-";
}

export const stockVat = (rec) => {
  const { stock_by_product, count_stock, amount, qty_vat } = rec;
  const hasAsone = Number( stock_by_product || 0 ) === 1;
  const hasCount = Number( count_stock || 0 ) === 1;
  const hasStock = Number( qty_vat || 0 ) >= Number( amount || 0 );
  const notCount = Number( count_stock || 0 ) === 0;
  const result = hasCount && hasStock && ( hasAsone || true ); // ไม่ชัวว่าใช่มั้ยเลย or true ไว้ก่อน
  return result || notCount;
}

export const vildateListForTaxInvoice = ( list ) => {
  if( !Array.isArray(list) ) return false;
  
  const not_stock = list.some( s => Number( s.count_stock || 0 ) === 1 && Number( s.qty_vat || 0 ) <  Number( s.amount || 0 ));

  if( not_stock ) message.warning("มีสต๊อก vat ไม่เพียงพอ");

  const result = list.some( s => !stockVat(s) ); 
  return !result;
}