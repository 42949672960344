import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard";

import Unit from "../pages/Unit";
import Profile from "../pages/Profile";
import Login from "../pages/Login";
import PageNotFound from "../pages/404";
import PrivateRoute from "../components/auth/PrivateRoutes";
import { ROLES } from "../constant/constant";


import { DataRouter } from "./data.router";
import { WarehouseRouter } from "./warehouse.router";
import { ApproveRouter } from "./approve.route";
import { SaleRouter  } from "./sale.route";
import { PrintRouter } from "./print.route";
const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate replace to="Login" />} />
        <Route path="/Login" element={<Login />} />

        <Route element={<PrivateRoute allowdRole={[ROLES.ADMIN, ROLES.USER]} />} >
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/unit" element={<Unit />} />
          <Route path="/profile" element={<Profile />} />          

          {DataRouter}
          {WarehouseRouter}
          {ApproveRouter}
          {SaleRouter}
        </Route>

        <Route element={<PrivateRoute allowdRole={[ROLES.ADMIN, ROLES.USER]} mode="print" />} >
          {PrintRouter}
        </Route>
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
