import React from 'react';
import { Descriptions, Divider, Space, Table, Typography } from 'antd';

import { columnViewDetail, infoItem, cusItem } from "./model"
import { comma } from '../../utils/util';
import { TableSearchValue } from '../../components/form';
const dividerProp = {orientation:"left", style:{marginBlock:10}, className:'!border-black'}; 
const emptyText = { emptyText:<span>ไม่มีข้อมูลสินค้า</span> };

export default function ManageView({initeial}) {

  const [headValue, setHeadValue] = React.useState({});
  const [listValue, setListValue] = React.useState([]);
  

  const information = (<>
    <Divider {...dividerProp}>ข้อมูลใบเสนอราคา</Divider>
    <Descriptions title={false} layout="vertical" bordered size='small' column={{xs:1, sm:2, md:2, lg:2, xl:4, xxl:4}} items={infoItem(headValue)} />
  </>);

  const customer = (<>
    <Divider {...dividerProp}>ข้อมูลผู้ขาย</Divider>
    <Descriptions title={false} layout="vertical" bordered size='small' column={{xs:1, sm:2, md:2, lg:2, xl:4, xxl:4}} items={cusItem(headValue)} />
  </>); 

  const lists = (<>
      <Divider {...dividerProp}>รายละเอียด</Divider>
      <TableSearchValue
          title='รายการใบสั่งซื้อ'
          notSelect={true}
          tbProps={{ 
              rowKey:"stcode",
              dataSource:listValue,
              columns:columnViewDetail(),
              pagination: false,
              locale:{...emptyText},
              summary: (record) => totalPrice
          }}
      />
  </>);

  const totalPrice = (<>
      <Table.Summary.Row>
          <Table.Summary.Cell colSpan={5} rowSpan={3} className='!bg-white align-top'>
            <label>หมายเหตุ</label>
            <pre 
              className='border-0 italic' 
              style={{fontSize: 'clamp(11px, 0.7vw, 13px)',padding: '8px 10px'}} 
             >{headValue?.remark}</pre>
          </Table.Summary.Cell>
          <Table.Summary.Cell colSpan={2} align='end' className='!pe-4 align-top'>รวมเงินทั้งสิ้น</Table.Summary.Cell>
          <Table.Summary.Cell className='!pe-4 text-end border-right-0 align-top' style={{borderRigth:"0px solid"}} >
            <Typography.Text type="danger">{ comma(Number(headValue?.price_total || 0),2,2) } บาท</Typography.Text>
          </Table.Summary.Cell>
      </Table.Summary.Row>
      {/* <Table.Summary.Row>
          <Table.Summary.Cell colSpan={2} align='end' className='!pe-4'>ภาษีมูลค่าเพิ่ม { !!Number(headValue?.isvat) && '(7%)'}</Table.Summary.Cell>
          <Table.Summary.Cell className='!pe-4 text-end border-right-0' style={{borderRigth:"0px solid"}} >
              <Typography.Text type="danger" className='text-nowrap'>{ comma(Number(headValue?.vat || 0),2,2) } บาท</Typography.Text>
          </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row>
          <Table.Summary.Cell colSpan={2} align='end' className='!pe-4'>ยอดรวมสุทธิ</Table.Summary.Cell>
          <Table.Summary.Cell className='!pe-4 text-end border-right-0' style={{borderRigth:"0px solid"}} >
            <Typography.Text type="danger" className='text-nowrap'>{ comma(Number(headValue?.grand_total || 0),2,2) } บาท</Typography.Text>
          </Table.Summary.Cell> 
      </Table.Summary.Row> */}
  </>)

  React.useEffect(() => {
    const { head, list } = initeial;
    setHeadValue(head);
    setListValue(list);
  }, [initeial]);

  return (
    <Space direction='vertical' className='w-full desc-quotation'>
      {information}
      {customer}
      {lists} 
    </Space>
  )
}
