/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, DatePicker, Form, Input, message, Space, Typography } from 'antd';
import { QctPageLayout } from '../../components/layout';
import { FormSearchValue, TableSearchValue  } from "../../components/form";
// import { Typography } from 'antd';
import { accessColumn } from './model';
import useSearch from '../../store/hook/use-search.hook';
import PurchaseOrderService from "../../service/PurchaseOrder.service.js"; 

import dayjs from "dayjs";
import { TbDeviceImacCog } from 'react-icons/tb';
 
const apirequest = PurchaseOrderService();
function Access() {
  const navigate = useNavigate();

  const stSearch = useSearch("purchase-order-approve-access");
  const { criteria:cria, tbparams:parm } = stSearch.gettingSearch();
  const tbparamsDefault = { ...parm, pagination:  {...{ current: 1, pageSize: 10 }, ...parm?.pagination} };
  const criteriaDefault = {
    ...cria, 
    purdate: cria?.purdate?.map(m => dayjs(m)) || null,
    duedate: cria?.duedate?.map(m => dayjs(m)) || null,
  } || { };
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  
  const [criteria, setCriteria] = useState({...criteriaDefault});

  const [tbparams, setTbparams] = useState({...tbparamsDefault});
  const [pageValue, setPageValue] = useState({...tbparamsDefault.pagination}); 
 

  const formSearch = (
    <FormSearchValue title='ค้นหา' onValues={(value) => handleCriteria(value)} defaultValue={criteriaDefault} >
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="เลขใบสั่งซื้อ" name="purcode" >
          <Input placeholder="ใส่เลขใบสั่งซื้อ" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="รหัสผู้ขาย" name="supcode" >
          <Input placeholder="ใส่รหัสผู้ขาย" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="ชื่อผู้ขาย" name="supname" >
          <Input placeholder="ใส่ชื่อผู้ขาย" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
           <Form.Item label='วันที่สั่งซื้อ' name='purdate'>
               <DatePicker.RangePicker placeholder={['เริ่มวันที่', 'ถึงวันที่']} style={{width:'100%', height:40}}  />
           </Form.Item>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
           <Form.Item label='วันที่รับของ' name='duedate'>
               <DatePicker.RangePicker placeholder={['เริ่มวันที่', 'ถึงวันที่']} style={{width:'100%', height:40}}  />
           </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="ชื่อผู้ติดต่อ" name="contact" >
          <Input placeholder="ใส่ชื่อผู้ติดต่อ" />
        </Form.Item>
      </Col>
    </FormSearchValue>
  );

  const handleCriteria = (criteria) => {
    setCriteria(criteria);
    setTbparams(page => ({ 
      ...page, 
      pagination: {...page?.pagination, current: 1},
      current: 1
    }));
  }

  const handlePaging = (page) => {  
    setTbparams(page); 
    // setTbparams(page.pagination); 
  }
  
  const handleSearch = () => {
    
    const [purdate_form, purdate_to] = criteria?.purdate?.map( m => dayjs(m).format("YYYY-MM-DD") ) || [];  
    const [duedate_form, duedate_to] = criteria?.duedate?.map( m => dayjs(m).format("YYYY-MM-DD") ) || []; 
    const newPram = {...criteria, purdate_form, purdate_to, duedate_form, duedate_to}; 
    const parm = { criteria:newPram, tbparams };
    
    apirequest.search({ criteria:{...newPram, approve:'N'}, tbparams }, { ignoreLoading:Object.keys(parm.criteria).length !== 0 } ).then( res => {
      const { data:{ source, tbparams } } = res.data;
      
      stSearch.updateSearch( parm );
      setPageValue(tbparams?.pagination);
      // setPaing( state => ( {...state, ...pagination }) );
      setDataSource(source); 

      const { order, field } = tbparams;
      setTimeout( ()=>{
        setColumns( state => stSearch.columnOrder(state, order, field)); 
      }, 80);
    }).catch( e => { 
      console.log( e );
      message.error("เกิดข้อผิดพลาด");
    })
  }

  // const handleRowSelect = (record, key) => { }

  // const handleCreatData = () => {
  //   const config = { mode:"create", acname: "สร้าง" };
  //   navigate("manage", {state:{config}});
  // }
  
  // const handleUpdateData = (code, record) => { 
  //   const config = { mode:"update", acname: `แก้ไข #${record?.supcode}`, record, code };
  //   navigate("manage", {state:{config}});
  // }

  const handleReviewData = (code, record) => { 
    const config = { mode:"view", acname: `รายละเอียดข้อมูล #${record?.purcode}`, record, code };
    navigate("manage", {state:{config}});
  }

  const handleManageData = (code, record) => { 
    // const config = { mode:"manage", acname: `รายละเอียดข้อมูล #${record?.purcode}`, record, code };
    navigate("manage-approve");
  }

  useEffect( () => { 
    // console.log( criteriaDefault );
    handleSearch();

    // setMounted(true);
  }, [JSON.stringify(criteria), JSON.stringify(tbparams)]);

  React.useEffect(() => {
    const initeil = async() => {
 
      const { order, field } = tbparamsDefault;
      const cols = accessColumn();
      const colOrder = stSearch.columnOrder(cols, order, field);
      setColumns(colOrder);
  }

  initeil();    

  }, []);

  const customButton = (code, record, istyle, bstyle ) => { 
    return (<>
      <Button
        icon={<TbDeviceImacCog style={istyle} />} 
        style={bstyle} 
        className='bn bn-primary-outline'
        onClick={()=>handleManageData(code, record)}
      >
        <Typography.Text>จัดการ-การอนุมัติ</Typography.Text>
      </Button>
    </>)

  }
  return (<>
  <QctPageLayout title={"ใบสั่งซื้อรออนุมัติ"}>
    <Space direction='vertical' className='flex gap-4'>
      { formSearch }
      <TableSearchValue
        title='รายการใบสั่งซื้อรออนุมัติ'
        pagingDefault={tbparamsDefault?.pagination}
        onPageChange={handlePaging}
        onReView={handleReviewData}
        onCustom={customButton}
        // onSelectedRow={handleRowSelect}
        // onCreate={handleCreatData}
        // onUpdate={handleUpdateData}
        // onDelete={handleDeleteData}
        tbProps={{
          rowKey:"purcode",
          dataSource:dataSource,
          columns:columns,
          pagination: pageValue
        }}
      />
    </Space>

  </QctPageLayout>
  </>)
}

export default Access