/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { QctPageLayout } from '../../components/layout';
import { TbClipboardCheck, TbClipboardX } from "react-icons/tb";
import { Button, Col, Drawer, Flex, Input, Row, Typography, message } from 'antd';
import dayjs from "dayjs";
// import PurchaseOrderFrom from './MyManageForm';
import PurchaseOrderView from "./MyManageView";

import PurchaseOrderService from "../../service/PurchaseOrder.service";
// import { TbSearchProvider } from '../../store/context/table-search.context';
import { formData } from "./model"; 
import useConfirm from "../../store/hook/use-confirm.hook"
import { CancelButton } from '../../components/drawer/cancel';
const apirequest = PurchaseOrderService();
function Manage() {
  const confirms = useConfirm()
  const navigate = useNavigate();
  const location = useLocation();

  const { config:{ mode, acname, code, backTo } } = location.state || { config: null }; 
  const [formValue, setformValue] = useState(formData);

  const [result, setResult] = React.useState(null);
  const [resultText, setResultText] = React.useState(null);

  const [openComment, setOpenComment] = React.useState(false);
  const [comment, setComment] = React.useState(null);

  const handleApprove = (res) =>{ 
    setResult(res);
    setResultText(res ? 'อนุมัติ' : 'ไม่อนุมัติ')
    setOpenComment( true );
  }

  const handleSubmit = async () =>{ 
    try {   
      const r = await confirms.saved({content: "ต้องการอนุมัติสินค้าใบสั่งซื้อ ใช่หรือไม่"});

      if( !r ) return;

      await apirequest.approve({ purcode:code, result: !!result ? 'Y' : 'J', comment });
      navigate(-1);
      message.success("Request create done."); 
    } catch ( er ){
      console.log( er ); 
      message.error("Error request.")
    }
  }

  const handleCancel = async (comment) => {
    try {  
      await apirequest.approve_cancel(comment, code);
      navigate(-1);
      message.success("ยกเลิกใบสั่งสินค้าเรียบร้อย"); 
    } catch ( er ){
      console.log( er ); 
      const { data:{ option } } = er.response;
      message.error(`Error request. ${option}`);
    } 
  }

  useEffect( () => {
    const initeil = async () =>{
      try {
        if( mode !== "create" && !!code){
          const res = await apirequest.get(code);
          const { data:{ head, list } } = res.data; 

          head.purdate = !!head.purdate ? dayjs(head?.purdate) : null;
          head.duedate = !!head.duedate ? dayjs(head?.duedate) : null;

          setformValue({ head, list}); 
        } 
      } catch ( er ){
        console.log( er );
        message.error("error request.")
      }
    }
    initeil();
  }, [mode, code]);

  const acButton = (<>
    { 
      !['เสร็จสิ้น', 'ยกเลิก'].includes(formValue.head.status) && formValue.head.approved_status === 'N' &&
      <>
        <Button 
          icon={<TbClipboardCheck style={{fontSize:'1.4rem'}}/>} 
          className='bn-center bn-success'
          onClick={() => handleApprove(true)}
        >อนุมัติ</Button>
        <Button 
          icon={<TbClipboardX style={{fontSize:'1.4rem'}}/>} 
          className='bn-center bn-danger'
          onClick={() => handleApprove(false)}
        >ไม่อนุมัติ</Button>
      </>   
    }
    {
      ['เสร็จสิ้น'].includes(formValue.head.status) && formValue.head.approved_status === 'Y' &&
      <>
        <CancelButton value={handleCancel} text='ยกเลิกการอนุมัติ' disabled={['เสร็จสิ้น', 'ยกเลิก'].includes(formValue.head.status)} /> 
      </>
    } 
  </>);

  const commentFooter = (<>
    <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
        <Col span={12} className='p-0'>
            <Flex gap={4} justify='start'>
              <Button  
                className='bn-center bn-secondary-outline'
                onClick={() => setOpenComment(false)}
              >ปิด</Button>
            </Flex>
        </Col>
        <Col span={12} className='p-0'>
            <Flex gap={4} justify='end'>
                <Button 
                className={`bn-center bn-${result ? 'success' : 'danger'}-outline`} 
                onClick={handleSubmit} >{resultText}</Button>
            </Flex>
        </Col>
    </Row>
  </>);
  
  return (<>
    <QctPageLayout title={`ใบสั่งซื้อสินค้า - ${acname}`} back={true} footerRight={acButton || <></>} hearderRight={acButton} backto={backTo} >
      <PurchaseOrderView initeial={formValue} />
      {/* {mode === "view"
      ? <PurchaseOrderView initeial={formValue} />
      : <PurchaseOrderFrom />
      } */}
    </QctPageLayout>
    <Drawer
      title={`ความคิดเห็นในการ ${resultText}`}
      closable={false}
      onClose={()=>setOpenComment(false)}
      open={openComment}
      key="comment"
      footer={commentFooter}
      styles={{ 
        header:{
          borderBottom: `2px solid ${result ? 'var(--success)' : 'var(--danger)'}`
        },
        content:{
          border: `2px solid ${result ? 'var(--success)' : 'var(--danger)'}`
        },
        footer:{
          borderTop: `2px solid ${result ? 'var(--success)' : 'var(--danger)'}`
        } 
      }}
    >
      <Typography.Text >ความคิดเห็น</Typography.Text>
      <Input.TextArea rows={5} onChange={(e) => setComment(e.target.value)} ></Input.TextArea>
    </Drawer>    
  </>)
}

export default Manage