/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { QctPageLayout } from "../../components/layout";
import { message } from "antd";
import MyViewForm from "./MyViewForm";
import CarService from "../../service/Car.service";
import { initeial_formData } from "./model";

const formName = "car-form";
const apirequest = CarService();
function CarManage() {
  const location = useLocation();

  const {
    config: { mode, acname, code },
  } = location.state || { config: null };

  const [formValue, setformValue] = useState(initeial_formData);

  useEffect(() => {
    const initeil = async () => {
      try {
        if (mode !== "create" && !!code) {
          const res = await apirequest.gethistory(code);
          const { data } = res.data;
          setformValue(data);

          // console.log(data);
        }
      } catch (er) {
        console.log(er);

        message.error("error request.");
      }
    };

    initeil();
  }, [mode, code]);

  return (
    <>
      <QctPageLayout
        title={`รถ - ${acname}`}
        back={true}
      >
        <MyViewForm
          formName={formName}
          initeial={formValue}
          mode={mode}
        />
      </QctPageLayout>
    </>
  );
}

export default CarManage;
