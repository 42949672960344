/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Col, DatePicker, Divider, Flex, Form, Input, Radio } from 'antd';
import { Modal, Row, Select, Space, Table, Typography } from 'antd';

import { SuppliersInput } from '../../components/modal/suppliers'; 
import { ItemForPurchaseOrderModal } from '../../components/modal/purchase-order';

import { comma, filterOption, jsonParsable } from '../../utils/util';

import { paycredit, columnsDetailsEditable, columnDetail, formData } from "./model";
import { ComponentEdit } from '../../components/table/TableEditable';
import { TableSearchValue } from '../../components/form'; 
import { TbExclamationCircle, TbTags } from 'react-icons/tb';

import { useTbSearchContext } from "../../store/context/table-search.context"
import dayjs from "dayjs";
import { TagsMulti } from '../../components/badge-and-tag';

import OptionService from "../../service/Options.service.js"
// import useConfirm from "../../store/hook/use-confirm.hook.js"

const dateFormat = 'YYYY/MM/DD';
const optrequest = OptionService();
export default function ManageForm({formName = "form-name", submit, initeial}) { 
    // const confirms = useConfirm();
    const emptyText = { emptyText:<span>ไม่มีข้อมูลสินค้า</span> };
    const dividerProp = {orientation:"left", style:{marginBlock:10}, className:'!border-black'}; 
    const formRole = { required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' }; 

    const context = useTbSearchContext();

    const [form] = Form.useForm();
    const [formValue, setFormValue] = React.useState(formData);
    const [listSouce, setListSouce] = React.useState([]);

    const [suppliers, setSuppliers] = React.useState({});
    const [openItemsList, setOpenItemsList] = React.useState(false);

    const [optLocation, setOptLocation] = React.useState([]);

    const [isVat, setIsVat] = React.useState(null); 

    const onFinish = (values) => {
        if( listSouce.length < 1) return;


        if( listSouce.some( s => Number(s.qty || 0) < 1 )) {
            Modal.error({content: 'มีบางรายการที่ไม่ได้กรอกจำนวนที่จะสั่ง'});

            return;
        }

        const head = {...formValue, ...values, isvat: isVat};
        
        head.purdate = dayjs(head?.purdate).format("YYYY-MM-DD");
        head.duedate = !!head?.duedate ? dayjs(head?.duedate).format("YYYY-MM-DD") : null;

        const list = [...listSouce];
        submit({head, list}); 
    };


    const handleClickCell = (record) => {
        context.clickSelect( "stcode", record);
    }

    const handleSave = async (row) => {
      const newData = async (r) => {
        const formData = await form.getFieldsValue();
        const itemDetail = [...listSouce];
        const nData = [...itemDetail];
        
        const { isvat } = formData;
        
        const ind = nData.findIndex((item) => r?.stcode === item?.stcode);
        if (ind < 0) return itemDetail;
        const item = nData[ind];
        nData.splice(ind, 1, { ...item, ...r});

        const total = nData.reduce( (a,v) => a += ( (Number(v.qty || 0 ) * Number(v?.price ||  0)) * (1-(Number(v?.discount ||  0)/100) ) ), 0);
        const vat = total * (isvat ? 0.07 : 0);
        const grand_total = total + vat;

        setFormValue(state => ({...state, total, vat, grand_total }));
        // console.log( newData );
        return nData;
      };


      const n = await newData(row);
      setListSouce([...n]);
    };

    const handleDelete = (key) => {
        Modal.confirm({
          title: <Flex align='center' gap={2} className='text-red-700' ><TbExclamationCircle style={{fontSize:'1.5rem'}} />{'ยืนยันที่จะลบรายการสินค้า'}</Flex>, 
          icon:<></>,
          content: 'ต้องการลบรายการสินค้าออกจากรายการใบสั่งซื้อ ใช่หรือไม่',
          okText: 'ยืนยัน',
          okType: 'danger',
          cancelText: 'ยกเลิก',
          onOk() { 
            setListSouce((state) => state.filter( soc => soc.stcode !== key));
          },
          // onCancel() { },
        });
    }

    const handleItemsChoosed  = (value) => {
        
        const last_itemtype_order = `["${[...new Set(value?.map( m => m?.typename ))].join('","')}"]`;
        setListSouce(value);

        setFormValue( s => ({...s, last_itemtype_order}));
    }

    const handleCalculate = (nData, isvat) => {
        const total = nData.reduce( (a,v) => a += ( (Number(v.qty || 0 ) * Number(v?.price ||  0)) * (1-(Number(v?.discount ||  0)/100) ) ), 0);
        const vat = total * (isvat ? 0.07 : 0);
        const grand_total = total + vat;

        return { total, grand_total}
    }

    useEffect( () => { 
        const { head, list } = initeial;
        const init = {...head, isvat:!!Number(head.isvat)};
        const sunmary = handleCalculate(list, !!Number(init.isvat));
        const newForm = {...init, ...sunmary};
        const supIntieial = {  
            supcode: head?.supcode,
            supname: head?.supname,
            tag: head?.suptags,
            last_itemtype_order: head?.itemtype || null,
        };
        setSuppliers( supIntieial );

        form.setFieldsValue(newForm);
        setFormValue(newForm);
        setListSouce(list);
        setIsVat(!!Number(init.isvat));
        return () => {};
    }, [initeial]);

    useEffect( () => {
        // console.log( isVat );
        const vat = (isVat ? 0.07 : 0);
        setFormValue(state => {
            const total = Number( state.total || 0)
            return {...state, vat:vat * total, grand_total: total + vat * total }
        });
    }, [isVat])

    useEffect( () => {
        const initeil = async () => {
            const [optLocationRes] = await Promise.all([
                optrequest.optionsLocation({p:"location-option"})
            ]);

            setOptLocation( optLocationRes.data.data );
        }

        initeil(); 
        return () => {};
    }, []);
    

    const handleSupplier = (val) => {
        // console.log( val );
        if(!val) return;
        const { address, zipcode, district, amphoe, province, tel, fax, email, tag } = val;
        
        let dist = "";
        dist += !!district ? ` ต.${district}` :'';
        dist += !!amphoe   ? ` อ.${amphoe}` :'';
        dist += !!province ? ` จ.${province}` :'';
        dist += !!zipcode ? ` ${zipcode}` :'';
        dist += !!tel ? ` โทร.${tel}` :'';
        dist += !!fax ? ` แฟกซ์ ${fax}` :'';
        dist += !!email ? ` อีเมล ${email}` :'';

        const addr = `${address || ''}${dist}`; 
        const valueForm = form.getFieldsValue();
        const newIntieial = { 
            ...valueForm,
            ...val,
            address:addr, suptags: tag, remark: valueForm.remark,
            last_itemtype_order: jsonParsable(val?.last_itemtype_order) || null,
        };
        form.setFieldsValue( newIntieial );
        setSuppliers( val );
    }

    const information = (<> 
        <Divider {...dividerProp}>ข้อมูลใบสั่งซื้อ</Divider>
        <Row className='!mx-0' gutter={[8,8]}>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="วันที่สั่งซื้อ" name="purdate" rules={[formRole]}>
                <DatePicker style={{ width: "100%", height:40 }} format={dateFormat} placeholder='กำหนดวันที่' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="วันที่นัดส่งของ" name="duedate" >
                <DatePicker style={{ width: "100%", height:40 }} format={dateFormat} placeholder='กำหนดวันที่' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <Form.Item label="การชำระเงิน/เครดิสเทอม" name="payment" >
                    <Select 
                        showSearch 
                        filterOption={filterOption}
                        options={paycredit.map( op => ({value: op, lable: op}))}
                        style={{ width: "100%", height:40 }}
                        placeholder='เลือกการชำระเงิน'
                    />
                </Form.Item>
            </Col>            
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="เลขใบเสนอราคา" name="qtcode" >
                <Input placeholder='กรอกเลขใบเสนอราคา' style={{ width: "100%", height:40 }} />
              </Form.Item>
            </Col> 
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="ภาษีมูลค่าเพิ่ม (Vat)" name="isvat">
                <Radio.Group onChange={(e) => setIsVat(e.target.value)} className='gap-3 flex-wrap'>
                    <Radio value={true}>มี</Radio>
                    <Radio value={false}>ไม่มี</Radio> 
                </Radio.Group>
              </Form.Item>
            </Col> 
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <Form.Item label="สถานที่เก็บ" name="location_code" >
                    <Select 
                        showSearch 
                        filterOption={filterOption}
                        options={optLocation}
                        style={{ width: "100%", height:40 }}
                        placeholder='เลือกสถานที่เก็บ'
                    />
                </Form.Item>
            </Col>   
        </Row>    
    </>);

    const supplier = (<>
        <Divider {...dividerProp}>ข้อมูลผู้ขาย</Divider>
        <Row className='!mx-0' gutter={[8,8]}>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="รหัสผู้ขาย" htmlFor="supcode-1"  name="supcode" rules={[formRole]} >
                    <SuppliersInput selected={handleSupplier} placeholder='เลือกผู้ขาย' id='supcode-1' className='!bg-zinc-50 !text-black' value={formValue.supcode} />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={18} lg={18}>
                <Form.Item label="ชื่อผู้ขาย" name="supname" rules={[formRole]} >
                    <Input disabled placeholder='ชื่อผู้ขาย' className='!bg-zinc-50 !text-black' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="เลขผู้เสียภาษี"  name="taxnumber" >
                    <Input placeholder='ชื่อผู้ขาย' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="ติดต่อ"  name="contact_name" >
                    <Input placeholder='ติดต่อ' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="เบอร์โทร"  name="contact_tel" >
                    <Input placeholder='เบอร์โทร' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="อีเมล"  name="contact_email" >
                    <Input placeholder='อีเมล' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
                <Form.Item label="ที่อยู่ผู้ขาย"  name="address" rules={[formRole]} >
                    <Input.TextArea placeholder='ที่อยู่' rows={2} />
                </Form.Item>
            </Col>
            { (!!suppliers?.tag && suppliers?.tag?.length > 0) &&
                <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item label="แท็ก/ประเภทการขาย" name="suptags" >
                        <TagsMulti data={[...new Set([ ...jsonParsable( suppliers?.tag || "[]" ), ...jsonParsable( suppliers?.last_itemtype_order || "[]" )])]} icon={<TbTags />} />
                    </Form.Item>
                </Col>
            }
        </Row> 
    </>);
 
    const lists = (<>
        <Divider {...dividerProp}>รายละเอียด</Divider>
        <TableSearchValue
            title='รายการใบสั่งซื้อ'
            onDelete={handleDelete}
            onCreate={()=> setOpenItemsList(true)}
            // notSelect={true}
            tbProps={{
                components:ComponentEdit,
                rowKey:"stcode",
                dataSource:listSouce,
                columns:columnsDetailsEditable(handleSave, columnDetail(handleClickCell)),
                pagination: false,
                locale:{...emptyText},
                summary: (record) => totalPrice
            }}
        />
    </>);

    const totalPrice = (<>
        <Table.Summary.Row>
            <Table.Summary.Cell colSpan={5} rowSpan={3} className='!bg-white'>
                <Form.Item name="remark" label="หมายเหตุ" className='!m-0'> 
                    <Input.TextArea placeholder='กรอกหมายเหตุ' rows={3} className='!resize-none' />
                </Form.Item> 
            </Table.Summary.Cell>
            <Table.Summary.Cell colSpan={2} align='end' className='!pe-4'>รวมเงินทั้งสิ้น</Table.Summary.Cell>
            <Table.Summary.Cell className='!pe-4 text-end border-right-0' style={{borderRigth:"0px solid"}} >
              <Typography.Text type="danger">{ comma(Number(formValue?.total || 0),2,2) } บาท</Typography.Text>
            </Table.Summary.Cell> 
        </Table.Summary.Row>
        <Table.Summary.Row>
            {/* <Table.Summary.Cell index={0} colSpan={5} >{'\u00A0'}</Table.Summary.Cell> */}
            <Table.Summary.Cell colSpan={2} align='end' className='!pe-4'>ภาษีมูลค่าเพิ่ม { isVat && '(7%)'}</Table.Summary.Cell>
            <Table.Summary.Cell className='!pe-4 text-end border-right-0' style={{borderRigth:"0px solid"}} >
              {/* <Form.Item name="vat" className='!m-0'> */}
                {/* <InputNumber className='width-100 input-30 text-end' controls={false} min={0} onFocus={(e)=>{ e.target.select() }} onChange={handleVat} /> */}
                <Typography.Text type="danger" className='text-nowrap'>{ comma(Number(formValue?.vat || 0),2,2) } บาท</Typography.Text>
              {/* </Form.Item> */}
            </Table.Summary.Cell> 
        </Table.Summary.Row> 
        <Table.Summary.Row>
            {/* <Table.Summary.Cell index={0} colSpan={5} >{'\u00A0'}</Table.Summary.Cell> */}
            <Table.Summary.Cell colSpan={2} align='end' className='!pe-4'>ยอดรวมสุทธิ</Table.Summary.Cell>
            <Table.Summary.Cell className='!pe-4 text-end border-right-0' style={{borderRigth:"0px solid"}} > 
              <Typography.Text type="danger" className='text-nowrap'>{ comma(Number(formValue?.grand_total || 0),2,2) } บาท</Typography.Text> 
            </Table.Summary.Cell> 
        </Table.Summary.Row>
    </>)

    return (<>
    <Space direction='vertical' className='w-full'>
        <Form
            form={form} 
            layout='vertical'
            name={formName}
            autoComplete="off"
            className='w-full'
            // onValuesChange={(_, value)=> setFormValue(value)} 
            onFinish={onFinish}
        >
            {information}
            {supplier}
            {lists}
        </Form>
    </Space>
    
    { openItemsList && 
        <ItemForPurchaseOrderModal 
            show={openItemsList} 
            values={handleItemsChoosed} 
            close={() => setOpenItemsList(false)} 
            selected={listSouce} 
            tags={ jsonParsable( suppliers?.last_itemtype_order || "[]" ) } 
        />
    }
    </>)
}
