import { Link } from 'react-router-dom';
import { 
    AuditOutlined, 
    ShopOutlined, 
    TagOutlined, 
    DatabaseOutlined,
    ApartmentOutlined,
    FileDoneOutlined,
} from "@ant-design/icons";

import { WaitingApprove } from './components/badge-and-tag';


const sales = [
    {
        label: "ระบบงานขาย",
        key: "menu1",
        icon: <AuditOutlined />,
        popupOffset:[0,0],
        children: [
          {
            label: <Link to="/repair-orders">ใบรายการซ่อม</Link>,
            key: "menu1-1",
          },
          {
            label: <Link to="/billing-note">การวางบิล</Link>,
            key: "menu1-2",
          },
        //   {
        //     label: <Link to="/receipt">ใบเสร็จ</Link>,
        //     key: "menu1-3",
        //   },
        {
            label: <Link to="/customer-history">ประวัติลูกค้า</Link>,
            key: "menu1-3",
          },
          {
            label: <Link to="/quotation">ใบเสนอราคา</Link>,
            key: "menu1-4",
          },
        ],
    },
];

const warehouse = [
    {
        label: "คลังสินค้า",
        key: "menu2",
        icon: <ShopOutlined />, 
        popupOffset:[0,0],
        children: [
          {
            label: <Link to="/purchase-orders">ใบสั่งซื้อ</Link>,
            key: "menu2-1",
          },
          {
            label: <Link to="/goods-receipt">รับสินค้า</Link>,
            key: "menu2-2",
          },
        ],
    }, 
];

const approve = [
    {
        label: <WaitingApprove main={true}>การอนุมัติ</WaitingApprove>,
        key: "approve",
        icon: <FileDoneOutlined />, 
        popupOffset:[0,0],
        children: [
          {
            label: <Link to="/purchase-orders-approve"><WaitingApprove>อนุมัติ - ใบสั่งซื้อ</WaitingApprove></Link>,
            key: "approve-purchase-order",
          },
        ],
    },     
]

const products = [ 
    {
      label: "สินค้า",
      key: "menu3",
      icon: <TagOutlined />,
      popupOffset:[0,0],
      children: [ 
        {
            label: <Link to="/items">สินค้า</Link>,  
            key: "menu3-1",
        },
        {
            label: <Link to="/location">สถานที่จัดเก็บ</Link>,  
            key: "menu3-2",
        },
      ], 
    },
];

const infomation = [
    {
        label: "ข้อมูล",
        key: "menu4",
        icon: <DatabaseOutlined />,
        popupOffset:[0,0],
        children: [
            {
                label: <Link to="/car">รถ</Link>,
                key: "menu4-1",
            },
            {
                label: <Link to="/customer">ลูกค้าบุคคล</Link>,
                key: "menu4-2",
            },
            {
                label: <Link to="/business">ลูกค้าบริษัท</Link>,
                key: "menu4-3",
            },
            {
                label: <Link to="/suppliers">ผู้ขาย</Link>,
                key: "menu4-8",
            },
            {
                label: <Link to="/employee">พนักงาน</Link>,
                key: "menu4-4",
            },
            {
                label: <Link to="/carmodel">รุ่นรถ</Link>,
                key: "menu4-5",
            },
            {
                label: <Link to="/itemtype">ประเภทสินค้า</Link>,
                key: "menu4-6",
            },
            {
                label: <Link to="/itemcategory">หมวดสินค้า</Link>,
                key: "menu4-7",
            },
            
        ],
    },
];

const systems = [
    {
        label: "จัดการระบบ",
        key: "menu5",
        icon: <ApartmentOutlined />, 
        popupOffset:[0,0],
        children: [
            {
                label: <Link to="/users">ผู้ใช้ระบบ</Link>,
                key: "menu5-1",
            },
        ], 
    },
];

export const menus = [
    ...sales,
    ...warehouse,
    ...approve,
    ...products,
    ...infomation,
    ...systems,
];