/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Button, Col, DatePicker, Divider, Drawer, Flex, Form, Input, message, Table } from 'antd';
import { Modal, Row, Space, Typography } from 'antd';

import { SuppliersInput } from '../../components/modal/suppliers'; 
import { UpdateCodeVat } from '../../components/modal/items';

import { columnPurchaseOrder, columnsDetailsEditable, columnDetail, formData, formTiresData } from "./model";
import { ComponentEdit } from '../../components/table/TableEditable';
import { TableSearchValue } from '../../components/form'; 
import { TbSearch, TbShoppingCartCopy, TbSquareRoundedCheck, TbSquareRoundedChevronsLeft, TbTags } from 'react-icons/tb';
import { GiFlatTire } from "react-icons/gi";

import dayjs from "dayjs";
import { TagsMulti } from '../../components/badge-and-tag';

import OptionService from "../../service/Options.service.js";
import ManageFormReceiptTires from './MyManageFormReceiptTires.jsx';
// import ManageFormReceipted from './MyManageFormReceipted.jsx';
import { cmConfig, comma, notEnter, address } from '../../utils/util.js';
import useConfirm from '../../store/hook/use-confirm.hook.js';

const dateFormat = 'YYYY/MM/DD';
const drawerBodyStyle = { paddingBlock:8, paddingLeft:18, paddingRight:8 };
const tbparamsDefault = { pagination: { current: 1, pageSize: 5 } };


const optrequest = OptionService();
export default function ManageForm({formName = "form-name", submit, initeial}) { 
    const emptyText = { emptyText:<span>ไม่มีข้อมูลสินค้า</span> };
    const dividerProp = {orientation:"left", style:{marginBlock:10}, className:'!border-black'}; 
    const formRole = { required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' }; 

    const confirms = useConfirm();

    const [tbparamspur, setTbparamspur] = React.useState({...tbparamsDefault});
    const [pagePurCriteria, setPagePurCriteria] = React.useState({});
    const [pagePurValue, setPagePurValue] = React.useState({...tbparamsDefault.pagination});
    const [pagePurLoad, setPagePurLoad] = React.useState(false); 

    const [form] = Form.useForm();
    const [formValue, setFormValue] = React.useState(formData);
    const [formReceipted, setFormReceipted] = React.useState(formTiresData);
    // const [receivedSource, setReceivedSource] = React.useState([]);
    const [formTireSource, setFormTireSource] = React.useState([]);

    const [listSource, setListSource] = React.useState([]);
    const [listPurchaseOrderList, setListPurchaseOrder] = React.useState([]);
    
    const [suppliers, setSuppliers] = React.useState({});
    const [purchaseOrder, setPurchaseOrder] = React.useState({});
    const [purchaseOrderSelected, setPurchaseOrderSelected] = React.useState({});
    const [purchaseOrderChanged, setPurchaseOrderChanged] = React.useState(false);

    const [locationOption, setLocationOption] = React.useState([]); 
    const [openReceiptTires, setOpenReceiptTires] = React.useState(false);

    const [openUpdateCodeVat, setOpenUpdateCodeVat] = React.useState(false);
    const [itemSeleted, setItemSeleted] = React.useState(null);

    // const [openReceipted, setOpenReceipted] = React.useState(false);
 

    const onFinish = async (values) => {
        let result = true;

        if( !listSource?.some( s => !!s?.isreceived) ){ 
            confirms.warn("ไม่มีการรับสินค้า กรุณาตรวจสอบรายการอีกครั้ง");
            return;
        }

        if( !!listSource?.some( s => Number(s?.amount || 0) < Number(s?.qty ||  0) ) ){
            // Modal.warning(cmConfig("ไม่มีรายการรับสินค้า กรุณาตรวจสอบรายการอีกครั้ง"));
            result = await confirms.warninged({ content:"รับสินค้าบางรายการยังไม่ถูกรับ ต้องการทำรายการต่อหรือไม่" }); 

            if(!result) return;
        }

        if( !!listSource?.some( s => !!s?.isvat && !s?.stcode_vat && !!s?.isreceived) ){ 
            confirms.warn("สินค้าบางรายการไม่มีรหัสสินค้าคลัง VAT กรุณาตรวจสอบรายการอีกครั้ง");
            return;
        }


        result = result || await confirms.confirm({ content: 'ต้องการบันทึกรายการสินค้าใบรับสินค้า ใช่หรือไม่'});
        if(!result) return;
        try {
            const head = {...formValue, ...values};
            head.grdate = dayjs(head?.grdate).format("YYYY-MM-DD");
            head.dndate = !!head?.dndate ? dayjs(head?.dndate).format("YYYY-MM-DD") : null;
            head.invdate = !!head?.invdate ? dayjs(head?.invdate).format("YYYY-MM-DD") : null;
            const list = [...listSource].map( li => ({
                ...li, 
                total_price: Number(li.price) * Number(li.amount),
                tires:li?.tires?.map( m => ({
                    ...m,
                    mfweek_date: dayjs(m.mfweek_date).format("YYYY-MM-DD"),
                    mfyear_date: dayjs(m.mfyear_date).format("YYYY-MM-DD"),
                    quarter_date: dayjs(m.quarter_date).format("YYYY-MM-DD"),
                    wyqdate: dayjs(m.wyqdate).format("YYYY-MM-DD"),
                    location_code: li.location_code
                }))
            })); 
            
            // console.log( {head, list} );
            // return;
            submit({head, list});            
        } catch ( e ){
            console.log( e );
            message.error("เกิดข้อผิดพลาด: ทำรายการไม่สำเร็จ");
        }

    };

    const handleClickCell = (record) => {
        // console.log( record );
    }

    const handleSave = (row) => {
        const newData = (r) => {
          const itemDetail = [...listSource];
          const newData = [...itemDetail];

          const ind = newData.findIndex((item) => r?.stcode === item?.stcode);
          if (ind < 0) return itemDetail;
          const item = newData[ind];

          const total =  Number(r?.amount ||  0) * Number(r?.price ||  0);
          const discount = 1 - ( Number(r?.discount ||  0) / 100 );
          const total_price = total * discount;

          newData.splice(ind, 1, {
              ...item,
              ...r,
              total_price,
              remain : (Number(r.amount || 0)) - (Number(r.received || 0)) - (Number(r.receipt_qty || 0))
          }); 

          return newData;
        };
        setListSource([...newData(row)]);
    };  

    const handleSearch = React.useCallback(() => {
        // console.log( pagePurCriteria );
        if( !pagePurCriteria?.supcode ) return;
        const parm = { criteria: pagePurCriteria, tbparams: tbparamspur, p: "wait-receipt" };
        setPagePurLoad(true);
        try { 
            optrequest.optionsPurchaseOrderPost(parm, { ignoreLoading:true } ).then( res => {
            const { data:{ source, tbparams } } = res.data;
            setPagePurValue(tbparams?.pagination);
            // setPaing( state => ( {...state, ...pagination }) );
            setListPurchaseOrder(source);
            setPagePurLoad(false);
            setListSource([]);
            setPurchaseOrderSelected({});
            setFormReceipted({});
            setFormTireSource([]);
            // setReceivedSource([]);
            });
        } catch(err){
            setPagePurLoad(false);
        } 
    }, [pagePurCriteria, tbparamspur]);

  
    React.useEffect(() => {
      handleSearch(); 
    }, [handleSearch]);

    useEffect( () => { 
        const { head, list } = initeial;
        const init = {...head, isvat:!(Number( head?.vat || 0) === 0)};
        form.setFieldsValue(init);
        setFormValue(head);
        setListSource(list);
        // setIsVat(init.isvat);
        return () => {};
    }, [initeial]);  

    useEffect( () => {
        if( !purchaseOrder?.purcode ) return;
        const parm = { code: purchaseOrder?.purcode, p: "detail-receipt" };
        setPagePurLoad(true); 
        optrequest.optionsPurchaseOrder(parm, { ignoreLoading:true } ).then( res => {
            const { data } = res.data;
            setListSource( data.map( m => ({
                ...m, 
                // location_name: m?.location_code && locationOption[0]?.lable, 
                location_code: !m?.location_code ?  locationOption[0]?.value : m.location_code,
                isvat: !!Number(m?.isvat || 0),
                price: Number(m?.last_grprice || 0),
                amount:  Number(m?.received || 0),
                disabled: Number(m?.received || 0) >= Number(m?.qty || 0),
                isreceived: false,
            })) );

            setFormValue( s => ({...s, purcode:purchaseOrder?.purcode }));

            setPurchaseOrderSelected({});
            setFormReceipted({});
            setFormTireSource([]);
            setPurchaseOrderChanged( false );
            // setReceivedSource([]);
            setListPurchaseOrder(  s => s.map( m => {
                // console.log( m.purcode, purchaseOrder?.purcode, s.purcode === purchaseOrder?.purcode );
                return {
                    ...m, 
                    disabled: m.purcode === purchaseOrder?.purcode
                }
            }) );
            setPagePurLoad(false);
        }).catch(err =>{
            setPagePurLoad(false); 
        }); 
    }, [purchaseOrder]);

    // React.useEffect(() => {
    //     console.log( openUpdateCodeVat, itemSeleted );

    // }, [openUpdateCodeVat, itemSeleted]);

    useEffect( () => {
        const initeil = async() => {
            const [locOprionRes] = await Promise.all([
                optrequest.optionsLocation({p:'location-option'})
            ]);

            setLocationOption(locOprionRes.data.data);
        } 
        initeil(); 
        return () => {};
    }, []); 

    const handleSupplier = (val) => {
        // console.log( val );
        if(!val) return;
        const { tag } = val; 

        const addr = address(val); 
        const valueForm = form.getFieldsValue();
        const newIntieial = { 
            ...valueForm,
            ...val,
            address:addr, suptags: tag, remark: valueForm.remark 
        };
        form.setFieldsValue( newIntieial );
        setSuppliers( val );
        setPurchaseOrderChanged( false );
        setPagePurCriteria({ supcode: val.supcode });
    }

    /** Receipted items */
    const handleReceipted = (record) => {

        if( Number(record?.qty ||  0) < 1 ){
            // Modal.warning(cmConfig("ไม่มีรายการรับสินค้า กรุณาตรวจสอบรายการอีกครั้ง"));
            Modal.warning(cmConfig("สินค้ามีจำนวนสั่งน้อยกว่าหนึ่ง กรุณาตรวจสอบรายการอีกครั้ง")); 
            return;
        }

        if( Number(record?.price ||  0) < 1 ){
            // Modal.warning(cmConfig("ไม่มีรายการรับสินค้า กรุณาตรวจสอบรายการอีกครั้ง"));
            Modal.warning(cmConfig("สินค้ามีราคาเท่ากับศูนย์ กรุณาตรวจสอบรายการอีกครั้ง")); 
            return;
        }
        const newData = {
            ...record,
            amount: Number( record?.qty || 0),
            received:  Number( record?.received || 0) + Number( record?.qty || 0),
            isreceived: true,
        }

        handleSave(newData);
    }

    const handleCancelReceipted = (record) => { 
        const newData = {
            ...record,
            amount: Number( record?.amount || 0) - Number( record?.qty || 0),
            received:  Number( record?.received || 0) - Number( record?.qty || 0),
            isreceived: false,
        }

        handleSave(newData);
    }

    /** Receipt Tires */
    const handleReceipt = (record) => { 
        if( Number(record?.qty ||  0) < 1 ){
            // Modal.warning(cmConfig("ไม่มีรายการรับสินค้า กรุณาตรวจสอบรายการอีกครั้ง"));
            Modal.warning(cmConfig("สินค้ามีจำนวนสั่งน้อยกว่าหนึ่ง กรุณาตรวจสอบรายการอีกครั้ง")); 
            return;
        }

        if( Number(record?.price ||  0) < 1 ){
            // Modal.warning(cmConfig("ไม่มีรายการรับสินค้า กรุณาตรวจสอบรายการอีกครั้ง"));
            Modal.warning(cmConfig("สินค้ามีราคาเท่ากับศูนย์ กรุณาตรวจสอบรายการอีกครั้ง")); 
            return;
        }
        
        setFormReceipted( s => ({
            ...s, ...record, 
            received: record?.received ||  0,
            receipt_qty: record?.receipt_qty ||  0,
            remain: record?.remain || 0,
        }) );
        
        // console.log( record );
        setFormTireSource( record?.tires || [] );
        setOpenReceiptTires( true );
    }

    const handleReceivedTire = (res) => { 
        // console.log( res );
        handleSave( res );

        setFormReceipted({});
        setOpenReceiptTires( false );
    }

    // Update Stcode VAT
    const handleUpdateStcodeVAT = (record) => {
        setItemSeleted( record );

        // console.log( record );
        setOpenUpdateCodeVat( true );
    }

    const handleResultUpdateCodeVAT = (val) => {
        handleSave({ ...val, stcode_vat_show: val?.stcode_vat, stcode_vat: `${val?.stcode_vat}/VAT` });
    }

    const handleRender = (_, record) => {
        return (<>
           { (Number(record?.stock_by_product) === 1 && !record?.disabled)
                && ( !record?.isreceived 
                    ?<Typography.Link 
                    className='hover:!underline flex items-center gap-1' 
                    onClick={()=>handleReceipt(record)}
                    ><TbShoppingCartCopy /> คลิกรับสินค้า</Typography.Link>  

                    : <Typography.Link 
                    className='hover:!underline flex items-center gap-1 !text-red-500' 
                    onClick={()=>handleCancelReceipted(record)}
                    ><TbShoppingCartCopy /> ยกเลิก </Typography.Link> 
                )
           }
           { (Number(record?.stock_by_product) === 0 && !record?.disabled)
                && ( !record?.isreceived 
                    ?<Typography.Link 
                        className='hover:!underline flex items-center gap-1' 
                        onClick={()=>handleReceipted(record)}
                    ><TbShoppingCartCopy /> คลิกรับสินค้า</Typography.Link>  

                    : <Typography.Link 
                        className='hover:!underline flex items-center gap-1 !text-red-500' 
                        onClick={()=>handleCancelReceipted(record)}
                    ><TbShoppingCartCopy /> ยกเลิก </Typography.Link> 
                )
           }
           { !!record?.disabled && <Typography.Text className='m-0 text-green-500'>รับครบแล้ว</Typography.Text> }
        </>)
    }

    const handleCodeVat = (v, record) =>  {
        return (<>
            { Number(record?.isvat) === 1
              ? ( !v && !record?.disabled
                    ? <Typography.Link 
                        className='hover:!underline !text-red-600 flex items-center gap-1' 
                        onClick={()=> handleUpdateStcodeVAT(record)} 
                    >ไม่มีรหัสVAT</Typography.Link> 
                    : v 
                )
              : <Typography.Text>-</Typography.Text> 
            }
         </>)        
    }

    const summary = (source) =>  {
        const total = source?.reduce( (a,v) => a += Number(v?.total_price || 0), 0);
        return(<>
        <Table.Summary.Row>
            <Table.Summary.Cell colSpan={10} align='end' className='!pe-3'>รวมเงินทั้งสิ้น</Table.Summary.Cell>
            <Table.Summary.Cell className='!pe-3 text-end border-right-0' style={{borderRigth:"0px solid"}} >
              <Typography.Text type="danger">{ comma(Number(total),2,2) }</Typography.Text>
            </Table.Summary.Cell>
            <Table.Summary.Cell align='start' >บาท</Table.Summary.Cell>
        </Table.Summary.Row>
      </>)
    }

    const poExtraHeader = (<>
        <Flex className='w-full gap-2 sm:gap-4 justify-start sm:justify-end flex-wrap'>
            <Flex className='w-full lg:w-3/4 gap-2 sm:gap-4' >
                <Input 
                    placeholder='ค้นหาข้อมูล' 
                    style={{height:40, borderRadius:12}} 
                    styles={{input:{height:30}}}
                    className='flex-1'
                    suffix={<TbSearch />} 
                    onChange={(e) => setPagePurCriteria(state => ({...state, search:e.target.value}))}
                />
                <Button 
                    className='bn bn-warning-outline' 
                    style={{borderRadius:12}} 
                    disabled={!purchaseOrderChanged} 
                    onClick={() => setPurchaseOrder( purchaseOrderSelected ) }
                >
                    <Typography.Text>เลือกใบสั่งสินค้า</Typography.Text>
                </Button>
            </Flex>
        </Flex>
    </>);

    const information = (<> 
        <Divider {...dividerProp}>ข้อมูลใบรับสินค้า</Divider>
        <Row className='!mx-0' gutter={[8,8]}>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="วันที่รับสินค้า :" name="grdate" rules={[formRole]}>
                <DatePicker style={{ width: "100%", height:40 }} format={dateFormat} placeholder='กำหนดวันที่' />
              </Form.Item>
            </Col>
        </Row>
        <Row className='!mx-0' gutter={[8,8]}>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="เลขใบส่งสินค้า :" name="dncode" >
                <Input placeholder='กรอกเลขใบส่งสินค้า' style={{ width: "100%", height:40 }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="วันที่ใบส่งสินค้า :" name="dndate" >
                <DatePicker style={{ width: "100%", height:40 }} format={dateFormat} placeholder='กำหนดวันที่' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="เลขที่ใบแจ้งหนี้ :" name="invcode" >
                <Input placeholder='กรอกเลขที่ใบแจ้งหนี้' style={{ width: "100%", height:40 }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="วันที่ใบแจ้งหนี้ :" name="invdate" >
                <DatePicker style={{ width: "100%", height:40 }} format={dateFormat} placeholder='กำหนดวันที่' />
              </Form.Item>
            </Col>
        </Row>    
    </>);

    const supplier = (<>
        <Divider {...dividerProp}>ข้อมูลผู้ขาย</Divider>
        <Row className='!mx-0' gutter={[8,8]}>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="รหัสผู้ขาย" htmlFor="supcode-1"  name="supcode" rules={[formRole]} >
                    <SuppliersInput selected={handleSupplier} placeholder='เลือกผู้ขาย' id='supcode-1' className='!bg-zinc-50 !text-black' value={formValue.supcode} />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={18} lg={18}>
                <Form.Item label="ชื่อผู้ขาย" name="supname" >
                    <Input disabled placeholder='ชื่อผู้ขาย' className='!bg-zinc-50 !text-black' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="เลขผู้เสียภาษี"  name="taxnumber" >
                    <Input placeholder='ชื่อผู้ขาย' disabled className='!bg-zinc-50 !text-black' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="ติดต่อ"  name="contact_name" >
                    <Input placeholder='ติดต่อ' disabled className='!bg-zinc-50 !text-black' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="เบอร์โทร"  name="contact_tel" >
                    <Input placeholder='เบอร์โทร' disabled className='!bg-zinc-50 !text-black' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="อีเมล"  name="contact_email" >
                    <Input placeholder='อีเมล' disabled className='!bg-zinc-50 !text-black' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
                <Form.Item label="ที่อยู่ผู้ขาย"  name="address" >
                    <Input.TextArea placeholder='ที่อยู่' rows={2} disabled className='!bg-zinc-50 !text-black' />
                </Form.Item>
            </Col>
            { (!!suppliers?.tag && suppliers?.tag?.length > 0) &&
                <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item label="แท็ก/ประเภทการขาย" >
                        <TagsMulti data={JSON.parse( suppliers?.tag || "[]" )} icon={<TbTags />} />
                    </Form.Item>
                </Col>
            }
        </Row> 
    </>);
 
    const purchase_orders = (<>
        <Divider {...dividerProp}>ใบสั่งซื้อ</Divider>
        <Row className='!mx-0 !w-full !mb-24' gutter={[8,8]}>
            <TableSearchValue
                title='รายการใบสั่งสินค้า'
                custom={poExtraHeader}
                pagingDefault={tbparamsDefault?.pagination}
                onPageChange={(page) => setTbparamspur(page)}
                onSelectedRow={(res) => { 
                    if( purchaseOrder?.purcode === res?.purcode) {
                        setPurchaseOrderChanged( false );
                    } else if( !res?.purcode){
                        setPurchaseOrderSelected(res);
                        setPurchaseOrderChanged( false );
                    }else {
                        setPurchaseOrderSelected(res);
                        setPurchaseOrderChanged( true );
                    }
                }}

                tbProps={{
                    rowKey:"purcode",
                    dataSource:listPurchaseOrderList,
                    columns:columnPurchaseOrder(),
                    pagination: pagePurValue,
                    locale:{...emptyText},
                    loading: pagePurLoad,
                    className: "w-full"
                }}
            />            
        </Row> 
    </>);
 
    const lists = (<>
        <Divider {...dividerProp}>สินค้า</Divider>
        <div id="gr-form">
            <TableSearchValue
                title='รายการใบรับสินค้า'
                notSelect={true}
                tbProps={{
                    components:ComponentEdit,
                    rowKey:"stcode",
                    dataSource:listSource,
                    columns:columnsDetailsEditable( handleSave, columnDetail(handleClickCell, locationOption, handleRender, handleCodeVat) ),
                    pagination: false,
                    locale:{...emptyText},
                    summary:(r) => summary(r)
                }}
            />
        </div>
    </>); 

    const tires_action = (
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
            <Col span={12} className='p-0'>
                <Flex gap={4} justify='start'>
                    <Button 
                        form='shippingtype-form'
                        htmlType='submit'
                        icon={<TbSquareRoundedChevronsLeft style={{fontSize:'1rem'}} />} 
                        className='bn-center bn-secondary-outline'
                        style={{ width:'9.5rem' }}
                        onClick={()=>setOpenReceiptTires(false)}
                    >ปิด</Button>
                </Flex>
            </Col>
            <Col span={12} className='p-0'>
                <Flex gap={4} justify='end'>
                    <Button 
                        form='form-tires'
                        htmlType='submit'
                        icon={<TbSquareRoundedCheck style={{fontSize:'1rem'}} />} 
                        className='bn-center bn-primary-outline'
                        style={{ width:'9.5rem' }} 
                    >ยืนยัน</Button>
                </Flex>
            </Col>
        </Row>         
    );

    // const receipted_action = (
    //     <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
    //         <Col span={12} className='p-0'>
    //             <Flex gap={4} justify='start'>
    //                 <Button 
    //                     form='shippingtype-form'
    //                     htmlType='submit'
    //                     icon={<TbSquareRoundedChevronsLeft style={{fontSize:'1rem'}} />} 
    //                     className='bn-center bn-secondary-outline'
    //                     style={{ width:'9.5rem' }}
    //                     onClick={()=>setOpenReceipted(false)}
    //                 >ปิด</Button>
    //             </Flex>
    //         </Col>
    //     </Row>         
    // );

    return (<>
    <Space direction='vertical' className='w-full'>
        <Form
            form={form} 
            layout='vertical'
            name={formName}
            autoComplete="off"
            className='w-full'
            // onValuesChange={(_, value)=> setFormValue(value)}
            onKeyDown={notEnter}
            onFinish={onFinish}
        >
            {information}
            {supplier}
            {purchase_orders}
            {lists}
        </Form>
    </Space> 
    <Drawer
        title={<Flex align='center' gap={4}>รับยาง  <GiFlatTire style={{fontSize:'1.2rem'}} /></Flex>}
        onClose={()=>setOpenReceiptTires(false)}
        open={openReceiptTires}
        footer={tires_action}
        width={868}
        className="responsive-drawer"
        styles={{
            body: drawerBodyStyle
        }}
    >
        { openReceiptTires && <ManageFormReceiptTires formData={formReceipted} tires={formTireSource} submit={handleReceivedTire} /> }
    </Drawer>

    { openUpdateCodeVat && <UpdateCodeVat show={openUpdateCodeVat} selected={itemSeleted} close={ () => setOpenUpdateCodeVat(false) } values={handleResultUpdateCodeVAT}  /> }
    {/* <Drawer
        title={<Flex align='center' gap={4}>รายการสินค้าที่รับแล้ว  <TbShoppingCartCopy style={{fontSize:'1.2rem'}} /></Flex>}
        onClose={()=>setOpenReceipted(false)}
        open={openReceipted}
        footer={receipted_action}
        width={868}
        className="responsive-drawer"
        styles={{
            body: drawerBodyStyle
        }}
    >
        { openReceipted && <ManageFormReceipted formData={formReceipted} formSource={receivedSource} /> }
    </Drawer> */}
    </>)
}