import React from 'react'
import { Divider, Row, Col, Form, InputNumber, Flex, Button, Modal } from 'antd';
import { Input, DatePicker } from 'antd';
import { notEnter, quarterFormat } from '../../utils/util';
// import { GiFlatTire } from "react-icons/gi";
import { PiTireBold } from "react-icons/pi";
import { TbExclamationCircle, TbTrash } from "react-icons/tb";
import { TableSearchValue } from '../../components/form';
import { ComponentEdit } from '../../components/table/TableEditable';
import { columnDetailTires, columnsDetailTiresEditable } from './model';
import { v4 as uuid } from 'uuid';
import dayjs from "dayjs";
// const dateFormat = 'YYYY/MM/DD';
export default function ManageFormReceiptTires({
    formData,
    optionLocation,
    tires,
    submit,
    name="form-tires"
}) {
    const emptyText = { emptyText:<span>ไม่มีข้อมูลสินค้า</span> };
    const dividerProp = {orientation:"left", style:{marginBlock:10}, className:'!border-black'}; 
    const formRole = { required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' }; 

    const [form] = Form.useForm();
    const values = Form.useWatch([], form);

    const [tiresList, setTiresList] = React.useState([]);

    const [isValidated, setIsValidated] = React.useState(false);
    // const [formValue, setFormValue] = React.useState(formTiresData);
    const handleSave = (row) => {
      const newData = (r) => {
        // console.log( r );
        const itemDetail = [...tiresList];
        const newData = [...itemDetail];
    
        const ind = newData.findIndex((item) => r?.stcode === item?.stcode);
        if (ind < 0) return itemDetail;
        const item = newData[ind];
        newData.splice(ind, 1, { ...item, ...r}); 

        return newData;
      };
      setTiresList([...newData(row)]);
    };

    const handleDelete = (key) => {
      Modal.confirm({
        title: (
          <Flex align='center' gap={2} className='text-red-700' >
            <TbExclamationCircle style={{fontSize:'1.5rem'}} />{'ยืนยันที่จะลบรายการ'}
          </Flex>
        ), 
        icon:<></>,
        content: 'ต้องการลบรายการ ใช่หรือไม่',
        okText: 'ยืนยัน',
        okType: 'danger',
        cancelText: 'ยกเลิก',
        onOk() {
          const newData = tiresList.filter( soc => soc.uuid !== key);
          setTiresList(newData);

          const f = form.getFieldsValue();
          const length = newData?.length;
          const qty = Number(f?.qty|| 0);
          const amount = length;
          const remain = qty - amount;
          const upform = {...f, amount, remain };
          form.setFieldsValue( upform );
        },
        // onCancel() { },
      });
    }

    const handleClickCell = (record) => {
      console.log( record );
    }

    const handleWeek = (e) => {
      // console.log( e );
      form.setFieldValue("mfyear_date", e);
      form.setFieldValue("quarter_date", e);
    }

    const handleCreateList = async () => {
      try{
        const f = await form.validateFields();
        const tireLength = tiresList?.length;
        const qty = Number(f?.qty|| 0);
        const length = Number(f?.rqty|| 0) || (qty - tireLength);
        const received = Number(f?.received|| 0);

        if( (length + tireLength) > (qty - received) ){
          Modal.error({content:"จำนวนที่รับมากกว่ารายการสินค้าที่สั่ง"})
        }else{
          // const list = Array.from({length}, () => ({...f}));
          const list = Array.from({ length }, () => ({...f, uuid:uuid()}) );
          // const newData = [...tiresList, ...list];
          // console.log( tiresList, newData );
          setTiresList( state => [...state, ...list] ); 

          // const empty = {...f, mfweek:null, mfyear:null, quarter:null, amount:0 };
          const amount = Number(f?.amount|| 0) + length;
          const remain = qty - amount;
          const rqty = 0;
          const upform = {
            ...formData, 
            ...f, amount, 
            rqty, 
            remain
          };
          form.setFieldsValue( upform );
        }

      } catch(e) { console.log(e) }

    }

    const handleRender = (v, record) => {
      return (<>
        <Flex className='w-full'>
          <Button 
            size='small'
            icon={<TbTrash  style={{fontSize:'1rem'}}/>} 
            className='bn-center bn-danger-outline'
            onClick={()=>handleDelete(record?.uuid)}
          ></Button>
        </Flex>
      </>)
    }

    const onSubmit = (record) => {
      const tires = tiresList?.map( im => ({
        ...im,
        mfweek: dayjs(im.mfweek_date).format("w"),
        mfyear: dayjs(im.mfyear_date).format("YYYY"),
        quarter: dayjs(im.quarter_date).format("Q"),
        wyqdate: dayjs(im.mfweek_date),
      }))
      const response = { ...formData, ...record, tires:tires };

      submit( response );
    }
    
    const detailFunc = {
      column:columnDetailTires, 
      oncell:handleClickCell, 
      action:handleRender,
    }

    const receipt_info = (<> 
        <Divider {...dividerProp}>ข้อมูลรายการสั่งซื้อ</Divider>
        <Row className='!mx-0' gutter={[8,8]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item label="รหัสสินค้า :" name="stcode" >
                <Input style={{ width: "100%", height:40 }} placeholder='รหัสสินค้า' disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item label="ชื่อสินค้า :" name="stname" >
                <Input style={{ width: "100%", height:40 }} placeholder='ชื่อสินค้า' disabled/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item label="ชื่อสินค้าสำหรับสั่งซื้อ :" name="purdetail" >
                <Input style={{ width: "100%", height:40 }} placeholder='ชื่อสินค้าสำหรับสั่งซื้อ' disabled/>
              </Form.Item>
            </Col> 
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="จำนวนที่สั่งซื้อ :" name="qty" >
                <Input style={{ width: "100%", height:40 }} placeholder='จำนวนที่สั่งซื้อ' disabled/>
              </Form.Item>
            </Col> 
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="จำนวนที่รับแล้ว :" name="received" >
                <Input style={{ width: "100%", height:40 }} placeholder='จำนวนที่รับแล้ว' disabled/>
              </Form.Item>
            </Col> 
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="จำนวนที่รับแล้ว :" name="amount" >
                <Input style={{ width: "100%", height:40 }} placeholder='จำนวนที่รับแล้ว' disabled/>
              </Form.Item>
            </Col> 
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="คงเหลือ :" name="remain" >
                <Input style={{ width: "100%", height:40 }} placeholder='คงเหลือ' disabled/>
              </Form.Item>
            </Col> 
        </Row> 
    </>);

    const information = (<> 
        <Divider {...dividerProp}>ข้อมูลยางรถยนต์</Divider>
        <Row className='!mx-0' gutter={[8,8]}>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
              <Form.Item label="สัปดาห์ที่ผลิต :" name="mfweek_date"  rules={[formRole]}>
                <DatePicker style={{ width: "100%", height:40 }} format={`w`} maxDate={dayjs()} placeholder='กำหนดสัปดาห์ที่ผลิต' picker="week" onChange={handleWeek}  />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
              <Form.Item label="ปีที่ผลิต :" name="mfyear_date" >
                <DatePicker style={{ width: "100%", height:40 }} format={`YYYY`} placeholder='กำหนดปีที่ผลิต' picker="year" mode='year' disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
              <Form.Item label="ไตรมาส :" name="quarter_date" >
                <DatePicker style={{ width: "100%", height:40 }} format={quarterFormat} placeholder='กำหนดไตรมาส' picker="quarter" disabled />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
              <Form.Item label="สถานที่เก็บ :" name="location_code" >
                <Select
                    allowClear
                    autoClearSearchValue={false}
                    style={{ width: '100%', height:40 }}
                    className="input-30"
                    placeholder="เลือกข้อมูล"
                    options={optionLocation.map( m => ({ label:m.label, value:m.value }))}
                    filterOption={filterOption}
                />
              </Form.Item>
            </Col> */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item 
                label="จำนวนรับ :" 
                name="rqty" 
                htmlFor='rqty'
              >
                <Flex className='w-full justify-between gap-2' name="test">
                  <InputNumber 
                    id='rqty'
                    name="rqty"
                    value={form.getFieldValue("rqty")}
                    style={{ width: "100%" }} 
                    className='input-40' 
                    controls={false} 
                    placeholder='กรอกจำนวนรับ' 
                    onPressEnter={handleCreateList}
                    // onChange={handleCreateList}
                    onFocus={(e) => e.target.select()}
                    max={formData?.qty}
                    disabled={!isValidated}
                  />
                  <Button 
                    icon={<PiTireBold  style={{fontSize:'1rem'}}/>} 
                    className='bn-center bn-primary-outline'
                    onClick={handleCreateList}
                  >รับยาง</Button>
                  <Button 
                    icon={<PiTireBold  style={{fontSize:'1rem'}}/>} 
                    className='bn-center bn-primary-outline'
                    onClick={handleCreateList}
                  >รับยางทั้งหมด</Button>
                </Flex>                
              </Form.Item>
            </Col> 
        </Row> 
    </>);

    const list_receipt = (<> 
        <Divider {...dividerProp}>รายการยางที่รับ</Divider>
        <TableSearchValue
          title='รายการใบรับสินค้า'
          notSelect={true}
          tbProps={{
              components:ComponentEdit,
              rowKey:"uuid",
              dataSource:tiresList,
              columns:columnsDetailTiresEditable(handleSave, optionLocation, {...detailFunc} ),
              pagination: false,
              locale:{...emptyText},
          }}
        /> 
    </>);  

    React.useEffect(() => {
      form
        .validateFields({ validateOnly: true })
        .then(() => setIsValidated(true))
        .catch(() => setIsValidated(false));
    }, [form, values]);

    // React.useEffect(() => {
    //   const mfweek = form.getFieldValue("mfweek");
    //   console.log( mfweek );
    // }, [form, form.getFieldValue("mfweek")]);

    React.useEffect( () => {
      const init = () => {
        setTiresList(tires);

        form.setFieldsValue(formData);
      }

      init();
      return () => {}
    }, [formData, form, tires])
  return (
    <>
        <Form form={form}             
            layout='vertical'
            name={name}
            autoComplete="off"
            className='w-full'
            // onValuesChange={(_, value)=> setFormValue(value)}
            onKeyDown={notEnter}
            onFinish={onSubmit}
        >
          {receipt_info}
          {information}
          {list_receipt}
        </Form>
    </>
  )
}
