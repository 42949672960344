import { Alert } from "antd";
import { QctPageLayout } from "../components/layout"
const Dashboard = () => {
  return ( 
    <QctPageLayout title="Dashboard"> 
        <Alert align="center" style={{ fontSize: 30}} message="ยินดีต้อนรับ" type="success" /> 
    </QctPageLayout> 
  );
};

export default Dashboard;
