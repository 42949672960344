import React from 'react'
import { Modal, Card, Form, Typography, Flex, Divider, Descriptions, Row, Col, Button, message, Spin } from "antd";
import { Space } from "antd";

import { infoItem, infoVat } from "./model.module.js";  
import OptionService from '../../../service/Options.service.js'; 
import { TbCheck, TbHighlight } from 'react-icons/tb';
const apirequest = OptionService();
const dividerProp = {orientation:"left", style:{marginBlock:10}, className:'!border-black'}; 
export default function MyModalUpdateCodeVat({show, close, values, selected}) { 
    const [form] = Form.useForm();
    const [openModal, setOpenModal] = React.useState(show);
    const [loading,  setLoading] = React.useState(true);  
 

    const [dataSource, setDataSource] = React.useState([]);
    const containerStyle = {
      position: 'relative',
      overflow: 'hidden',
    };

    const handleUpload =  (val) => {
        setLoading(true);
        try {
            const code = selected?.stcode;
            const payload = { ...val, stcode: code }
            apirequest.optionsItemsPut({payload, p:"code-vat"}, { ignoreLoading : true }).then( res => {
                message.success("เพิ่มข้อมูล รหัสสินค้าคลัง VAT เสร็จสิ้น.");

                values( { ...selected, ...val } );

                handleClose();
            }).catch( e => {    
                Modal.error({content:'เกิดข้อผิดพลาดในการร้องขอข้อมูล'})
            })
            
        } catch (error) {
            // console.log( error );
            Modal.error({content: "เกิดข้อผิดพลาดจากการแก้ไขข้อมูล"})
        } finally {
            setLoading( false );
        }
    }

    const handleClose = () =>{ 
        setOpenModal(false); 
        setTimeout( () => { close(false);  }, 140); 
        //setTimeout( () => close(false), 200 );
    } 

    React.useEffect(() => {
        try {
            const code = selected?.stcode;
            apirequest.optionsItems({code, p:'item-data'}).then( res => {
                const { data } = res.data;
                // console.log( data, res );
                setDataSource( data );
            }).catch( e => {    
                Modal.error({content:'เกิดข้อผิดพลาดในการร้องขอข้อมูล'})
            })            
        } catch ( error ) {
            Modal.error({content:'เกิดข้อผิดพลาดในการร้องขอข้อมูล'});
        } finally { 
            setLoading( false );
        }

    
      }, [selected]); 

    const modalTitle = (<>
        <Flex align='center' gap={4}>
            <TbHighlight style={{fontSize: '1rem'}} />
            <Typography.Text className='ms-1 mb-0'>กรอกรหัสสินค้าคลัง VAT</Typography.Text>
        </Flex>    
    </>); 

    const information_items = (<>
        <Divider {...dividerProp}>ข้อมูลสินค้า</Divider>
        <Descriptions title={false} layout="vertical"  size='small' column={{xs:1, sm:2, md:2, lg:2, xl:2, xxl:2}} items={infoItem(dataSource)} />
    </>);

    const information_vat = (<>
        <Divider {...dividerProp}>ข้อมูลสินค้าคลัง VAT</Divider>
        <Descriptions title={false} layout="vertical" size='small' column={{xs:1, sm:2, md:2, lg:2, xl:2, xxl:2}} items={infoVat(dataSource)} />
    </>);

    const modalFooter = (<> 
        <Row>
            <Col span={24}>{/* Ignore */}</Col>
            <Col span={24}>
                <Flex justify="flex-end">
                <Button
                    className="bn-center bn-primary"
                    icon={<TbCheck />}
                    form="form-code-vat"
                    htmlType='submit'
                    // onClick={() => handleUpload()}
                >ยืนยัน/บันทึก</Button>
                </Flex>
            </Col>
        </Row> 
    </>);
    return (
        <>
        <div className='modal-suppliers' id="modal-area">
            <Modal
                open={openModal}
                title={modalTitle}
                afterClose={() => handleClose() }
                onCancel={() => setOpenModal(false) } 
                maskClosable={false}
                style={{ top: 0, height: 'calc(100vh - 10px)' }}
                width={840}
                className='modal-loading-type mymodal'
                footer={<>{modalFooter}</>}
            >
            <Spin spinning={loading} >
                <div style={containerStyle}> 
                    <Space direction="vertical" size="middle" style={{ display: 'flex', position: 'relative'}} className='current'  >
                        <Card style={{backgroundColor:'#f0f0f0' }}>
                            <Form form={form} layout="vertical" autoComplete="off" name='form-code-vat' onFinish={handleUpload} >
                                {information_items}
                                {information_vat}
                            </Form>
                        </Card> 
                    </Space>      
                    {/* { openManage && 
                        <ModalShippingTypeView 
                            open={openManage} 
                            close={()=>setOpenManage(false)} 
                            source={sourceData}
                        />
                    }*/}
                </div>

            </Spin>
            </Modal>                
        </div>

        </>
    )
}

