import { Col, Divider, Form, Input, Row, Select, Space, Badge } from "antd";
import React, { useEffect } from "react";

export default function CarmodelManageForm({
  formName = "form-name",
  submit,
  initeial,
  mode,
}) {
  const [form] = Form.useForm();
  const formRole = { required: true, message: "กรุณากรอกข้อมูลให้ครบถ้วน!" };

  useEffect(() => {
    //  console.log(initeial);
    if (mode !== "create") {
      form.setFieldsValue(initeial);
    }
  }, [initeial, mode, form]);

  const dividerProp = {
    orientation: "left",
    style: { marginBlock: 10 },
    className: "!border-black",
  };

  const onFinish = (values) => {
    submit(values);
  };

  return (
    <Space direction="vertical" className="w-full">
      <Form
        form={form}
        layout="vertical"
        name={formName}
        autoComplete="off"
        className="w-full"
        // onValuesChange={(_, value)=> setFormValue(value)}
        onFinish={onFinish}
      >
        <Divider {...dividerProp}>ข้อมูลสินค้า</Divider>
        <Row className="!mx-0" gutter={[8, 8]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <Form.Item
              label="ใส่ชื่อรุ่นรถ"
              name="modelname"
              rules={[formRole]}
            >
              <Input placeholder="กรอกชื่อรุ่นรถ" />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            style={
              mode === "edit" ? { display: "inline" } : { display: "none" }
            }
          >
            <Form.Item name="active_status" label="สถานะการใช้งาน">
              <Select
                size="large"
                options={[
                  {
                    value: "Y",
                    label: <Badge status="success" text="เปิดการใช้งาน" />,
                  },
                  {
                    value: "N",
                    label: <Badge status="error" text="ปิดการใช้งาน" />,
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="modelcode">
              <Input type="hidden" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Space>
  );
}
