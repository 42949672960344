import {
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Badge,
  Card,
} from "antd";
import React, { useEffect } from "react";
import { PROVINCE_OPTIONS } from "../../utils/util";
import BusinessService from "../../service/Business.service";

const businessService = BusinessService();

export default function BusinessManageForm({
  formName = "form-name",
  submit,
  initeial,
  mode,
}) {
  const [form] = Form.useForm();
  const formRole = { required: true, message: "กรุณากรอกข้อมูลให้ครบถ้วน!" };
  const { TextArea } = Input;
  const { Option } = Select;
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  useEffect(() => {
    //  console.log(initeial);
    if (mode !== "create") {
      form.setFieldsValue(initeial);
    }
    else
    {
      businessService
      .getcode()
      .then((res) => {
        let { data } = res;
          form.setFieldsValue({
            businessno: data,
          });
      })
      .catch((err) => {});
    }
  }, [initeial, mode, form]);

  const dividerProp = {
    orientation: "left",
    style: { marginBlock: 10 },
    className: "!border-black",
  };

  const onFinish = (values) => {
    submit(values);
  };
  const databusiness = (
    <>
      <Divider {...dividerProp}>ข้อมูลบริษัทลูกค้า</Divider>
      <Row className="!mx-0" gutter={[8, 8]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <Form.Item
            label="รหัสลูกค้าบริษัท"
            name="businessno"
            rules={[formRole]}
          >
            <Input
              disabled 
              placeholder="รหัสลูกค้าบริษัท"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <Form.Item label="คำนำหน้าชื่อ" name="title_name" rules={[formRole]}>
            <Select
              size="large"
              showSearch
              filterOption={filterOption}
              placeholder="เลือกคำนำหน้าชื่อ"
              options={[
                {
                  value: "ร้าน",
                  label: "ร้าน",
                },
                {
                  value: "บจก.",
                  label: "บจก.",
                },
                {
                  value: "บมจ.",
                  label: "บมจ.",
                },
                {
                  value: "หจก.",
                  label: "หจก.",
                },
              ]}
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <Form.Item label="ชื่อบริษัท" name="business_name" rules={[formRole]}>
            <Input placeholder="กรอกชื่อบริษัท" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <Form.Item label="เลขประจำตัวผู้เสียภาษี" name="taxno">
            <Input placeholder="กรอกเลขประจำตัวผู้เสียภาษี" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <Form.Item label="เบอร์โทรบริษัท" name="tel">
            <Input placeholder="กรอกเบอร์โทรบริษัท" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <Form.Item label="โทรสาร" name="fax">
            <Input placeholder="กรอกโทรสาร" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <Form.Item label="วงเงินเครดิต" name="credit">
            <Input placeholder="กรอกวงเงินเครดิต" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <Form.Item label="ประเภทเครดิต" name="credit_type">
            <Select
              size="large"
              showSearch
              filterOption={filterOption}
              placeholder="เลือกประภทเครดิต"
              options={[
                {
                  value: "เงินสด",
                  label: "เงินสด",
                },
                {
                  value: "เครดิต 7 วัน",
                  label: "เครดิต 7 วัน",
                },
                {
                  value: "เครดิต 30 วัน",
                  label: "เครดิต 30 วัน",
                },
                {
                  value: "เครดิต 30 วัน ไม่นับเดือนส่ง",
                  label: "เครดิต 30 วัน ไม่นับเดือนส่ง",
                },
              ]}
            ></Select>
          </Form.Item>
        </Col>
      
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <Form.Item label="ระบุสาขา" name="business_branch">
            <Select size="large" placeholder="ระบุสาขา" allowClear>
              <Option value="สำนักงานใหญ่">สำนักงานใหญ่</Option>
              <Option value="สาขา">สาขา</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.business_branch !== currentValues.business_branch
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("business_branch") === "สาขา" ? (
                <Form.Item name="branch_details" label="รายละเอียดสาขา">
                  <Input />
                </Form.Item>
              ) : null
            }
          </Form.Item>
        </Col>
      </Row>
      <Row className="!mx-0" gutter={[8, 8]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="การวางบิล" name="">
            <TextArea rows={2} placeholder="กรอกข้อมูลการวางบิล" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="การเก็บเงิน" name="">
            <TextArea rows={2} placeholder="กรอกข้อมูลการวางเ็บเงิน" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
  const dataaddress = (
    <>
      <Divider {...dividerProp}>ที่อยู่</Divider>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="ที่อยู่" name="address">
            <TextArea rows={2} placeholder="กรอกที่อยู่" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <Form.Item label="จังหวัด" name="province">
            <Select
              placeholder="เลือกจังหวัด"
              style={{ height: 40 }}
              showSearch
              filterOption={filterOption}
              options={PROVINCE_OPTIONS}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <Form.Item label="รหัสไปรษณีย์" name="zipcode">
            <Input placeholder="กรอกรหัสไปรษณีย์" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="ที่อยู่จัดส่งสินค้า" name="shipping_address">
            <TextArea rows={2} placeholder="กรอกที่อยู่จัดส่งสินค้า" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <Form.Item label="จังหวัดจัดส่งสินค้า" name="shipping_province">
            <Select
              placeholder="เลือกจังหวัด"
              style={{ height: 40 }}
              showSearch
              filterOption={filterOption}
              options={PROVINCE_OPTIONS}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <Form.Item
            label="รหัสไปรษณีย์ที่อยู่จัดส่งสินค้า"
            name="shipping_zipcode"
          >
            <Input placeholder="กรอกรหัสไปรษณีย์ที่อยู่จัดส่งสินค้า" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
  const contact = (
    <>
      <Divider {...dividerProp}>ผู้ติดต่อ</Divider>
      <Row className="!mx-0" gutter={[8, 8]}>
        <Col span={12}>
          <Card title="ผู้ติดต่อ">
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="ผู้ติดต่อ" name="contact_person">
                  <Input placeholder="กรอกผู้ติดต่อ" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="ติดต่อแผนก" name="contact_department">
                  <Input placeholder="กรอกติดต่อแผนก" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="โทรศัพท์" name="contact_tel">
                  <Input placeholder="กรอกโทรศัพท์" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="มือถือ" name="contact_tel_mobile">
                  <Input placeholder="กรอกมือถือ" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="ไลน์" name="contact_line">
                  <Input placeholder="กรอกไลน์" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="โทรสาร" name="contact_fax">
                  <Input placeholder="กรอกโทรสาร" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="บัญชี">
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="ผู้ติดต่อ" name="account_person">
                  <Input placeholder="กรอกผู้ติดต่อ" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="ติดต่อแผนก" name="account_department">
                  <Input placeholder="กรอกติดต่อแผนก" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="โทรศัพท์" name="account_tel">
                  <Input placeholder="กรอกโทรศัพท์" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="มือถือ" name="account_tel_mobile">
                  <Input placeholder="กรอกมือถือ" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="ไลน์" name="account_line">
                  <Input placeholder="กรอกไลน์" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label="โทรสาร" name="account_fax">
                  <Input placeholder="กรอกโทรสาร" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
  const other = (
    <>
      <Divider {...dividerProp}>เพิ่มเติม</Divider>
      <Row className="!mx-0" gutter={[8, 8]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="หมายเหตุ" name="remark">
            <TextArea rows={2} placeholder="กรอกหมายเหตุ" />
          </Form.Item>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={6}
          style={mode === "edit" ? { display: "inline" } : { display: "none" }}
        >
          <Form.Item name="active_status" label="สถานะการใช้งาน">
            <Select
              size="large"
              options={[
                {
                  value: "Y",
                  label: <Badge status="success" text="เปิดการใช้งาน" />,
                },
                {
                  value: "N",
                  label: <Badge status="error" text="ปิดการใช้งาน" />,
                },
              ]}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <Form.Item name="businessno">
            <Input type="hidden" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
  return (
    <Space direction="vertical" className="w-full">
      <Form
        form={form}
        layout="vertical"
        name={formName}
        autoComplete="off"
        className="w-full"
        // onValuesChange={(_, value)=> setFormValue(value)}
        onFinish={onFinish}
      >
        {databusiness}
        {dataaddress}
        {contact}
        {other}
      </Form>
    </Space>
  );
}
