 
import { comma } from "../../../utils/util";
const calTotalDiscount = (rec) => {
  const total =  Number(rec?.amount ||  0) * Number(rec?.price ||  0);
  const discount = 1 - ( Number(rec?.discount ||  0) / 100 );

  return total * discount;
}
export const column = () => [
    // {
    //   title: "ลำดับ",
    //   dataIndex: "ind",
    //   key: "ind",
    //   width: 60,
    //   align: "left",
    //   render: (im, rc, index) => <>{index + 1}</>,
    // },
    // {
    //   title: "รหัสสินค้า",
    //   dataIndex: "stcode",
    //   key: "stcode",
    //   width: 120,
    //   align: "left",
    // },
    {
      title: "จำนวน",
      dataIndex: "amount",
      key: "amount", 
      width: 80,
      align: "right",
      className: "!pe-6",
      render: (_, rec) => <>{ comma( Number(rec?.amount ||  0) )}</>,
    },
    {
      title: "ชื่อสินค้าสำหรับสั่งซื้อ",
      dataIndex: "rpodetail",
      key: "rpodetail",
      align: "left",
      width: 374
      // width: "20%",
    },
    {
      title: "ราคาชื้อ",
      dataIndex: "price",
      key: "price", 
      width: 108,
      align: "right",
      className: "!pe-5",
      render: (_, rec) => <>{ comma( Number(rec?.price ||  0),  2, 2 )}</>,
    }, 
    {
      title: "ราคารวม",
      dataIndex: "total",
      key: "total",
      width: 134,
      align: "right",
      className: "!pe-10",
      render: (_,rec) => <>{ comma( calTotalDiscount(rec),  2, 2 )}</>,
    }
]; 