import {
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Badge,
  Radio,
} from "antd";
import React, { useEffect } from "react";

export default function ManageForm({
  formName = "form-name",
  submit,
  initeial,
  mode,
}) {
  const [form] = Form.useForm();
  const formRole = { required: true, message: "กรุณากรอกข้อมูลให้ครบถ้วน!" };

  useEffect(() => {
    //  console.log(initeial);
    if (mode !== "create") {
      form.setFieldsValue(initeial);
    }
    else
    {
      form.setFieldsValue({
        count_stock: "1",
        stock_by_product: "1",
      });
    }
  }, [initeial, mode, form]);

  const dividerProp = {
    orientation: "left",
    style: { marginBlock: 10 },
    className: "!border-black",
  };

  const onFinish = (values) => {
    submit(values);
  };

  return (
    <Space direction="vertical" className="w-full">
      <Form
        form={form}
        layout="vertical"
        name={formName}
        autoComplete="off"
        className="w-full"
        // onValuesChange={(_, value)=> setFormValue(value)}
        onFinish={onFinish}
      >
        <Divider {...dividerProp}>หมวดหมู่สินค้า</Divider>
        <Row className="!mx-0" gutter={[8, 8]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <Form.Item
              label="ชื่อหมวดหมู่สินค้า"
              name="categoryname"
              rules={[formRole]}
            >
              <Input placeholder="กรอกชื่อหมวดหมู่สินค้า" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <Form.Item
              label="อักษรขึ้นต้นสินค้า"
              name="category_precode"
              rules={[formRole]}
            >
              <Input placeholder="กรอกอักษรขึ้นต้นสินค้า" />
            </Form.Item>
          </Col>        
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            style={
              mode === "edit" ? { display: "inline" } : { display: "none" }
            }
          >
            <Form.Item name="active_status" label="สถานะการใช้งาน">
              <Select
                size="large"
                options={[
                  {
                    value: "Y",
                    label: <Badge status="success" text="เปิดการใช้งาน" />,
                  },
                  {
                    value: "N",
                    label: <Badge status="error" text="ปิดการใช้งาน" />,
                  },
                ]}
              />
            </Form.Item>
          </Col>            
        </Row>
        <Row className="!mx-0" gutter={[8, 8]}>
        <Col xs={24} md={24} lg={6}>
            <Form.Item label="ตัดสต๊อก" name="count_stock">
              <Radio.Group
                buttonStyle="solid"
              >
                <Radio.Button value="1">Enable</Radio.Button>
                <Radio.Button value="0">Disable</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <Form.Item label="แยกตามล๊อตการผลิต" name="stock_by_product">
              <Radio.Group
                buttonStyle="solid"
              >
                <Radio.Button value="1">Enable</Radio.Button>
                <Radio.Button value="0">Disable</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>          
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="categorycode">
              <Input type="hidden" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Space>
  );
}
