import { Badge } from "antd";

export const accessColumn = () => [  
  {
    title: "typecode",
    dataIndex: "typecode",
    key: "typecode",
    width: "20%",
    hidden: true,
  },
  {
    title: "ชื่อประเภทสินค้า",
    dataIndex: "typename",
    key: "typename",
    width: "40%",
    hide: false,
  },
  {
    title: "หมวดหมู่สินค้า",
    dataIndex: "category_name",
    key: "category_name",
    width: "20%",
    hide: false,
  },
  {
    title: "สถานะการใช้งาน",
    dataIndex: "active_status",
    key: "active_status",
    width: "20%",
    hide: false,
    render: (data) => (
      <div>
        {data === "Y" ? (
          <Badge status="success" text="เปิดการใช้งาน" />
        ) : (
          <Badge status="error" text="ปิดการใช้การ" />
        )}
      </div>
    ),
  },
];

export const formData = {
  typecode: null,
  typename: null,
  active_status: null,
};
