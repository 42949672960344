/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom';
// import ReactDOMServer from "react-dom/server";
import { useReactToPrint } from 'react-to-print';
import thaiBahtText from 'thai-baht-text';
import "./MyPrint.css";
import logo from "../../../assets/images/logo_qct.svg";
 
import { Button, Flex,  Table, Typography, message } from 'antd';
import { column } from './model';

import dayjs from "dayjs";
import { comma } from "../../../utils/util";
import { PiPrinterFill } from 'react-icons/pi';
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from 'antd';
import PurchaseOrderService from "../../../service/PurchaseOrder.service";
import { TbFileTypePdf } from "react-icons/tb";
// import jsPDF from 'jspdf';

const apiservice = PurchaseOrderService();

function MyPrintPreview() {
    const { code, print } = useParams();
    const componentRef = useRef(null);
    const printRef = useRef(null);
    const handlePrint = useReactToPrint({
      documentTitle: "Print This Document",
      onBeforePrint: () => handleBeforePrint(),
      onAfterPrint: () => handleAfterPrint(),
      removeAfterPrint: true,
    });

    const [hData, setHData] = useState({});
    const [details, setDetails] = useState([]); 

    // const [packingCostSource, setPackingCostSource] = useState([]); 
    
    const [newPageContent, setNewPageContent] = useState([]);
    const columnDesc = column; 

    const [loading, setLoading] = useState(false);

    const handleAfterPrint = () => { 
        setNewPageContent([]);

        if( !!Number(print) ) window.close();
    }

    const handleBeforePrint = (e) => {
        console.log("before printing...")
        // const newElement = document.createElement('div');
        // newElement.id = 'new-container'; // Optional: Set an ID for the new container
        // newElement.innerHTML = 'TEST';
        // Render the new component into the new container 
    
        // Replace the old container with the new container
        // componentRef.current.innerHTML = 'TEST'; 
    }

    const handleCheckMultiPages = async () => {
        const limitPage = 700; 
        return new Promise( r =>{
            // const head = document.querySelector("#raw .in-head");
            const data = document.querySelector("#raw .in-data");
            const table = document.querySelector("#raw .in-data #tb-data");
            // const thead = table?.querySelector("thead");
            const mtbody = table?.querySelector("tbody");
            const mtfoot = table?.querySelector("tfoot");
            const row = mtbody?.querySelectorAll("tr");

            const samplesPage =  [];
            // console.log(head, table, tbody, row); 
            // console.log(componentRef.current);
            // let pageCount = 1;
            let hPageCheck = 0;
            let emlContent = [];
            for( let elm of row ){
                const h = Number(window.getComputedStyle(elm).getPropertyValue('height')?.replace("px", ""));
                if( (hPageCheck + h) > limitPage ){
                    // console.log( { hPageCheck } ); 
                    samplesPage.push([...emlContent, elm]); 
                    emlContent = [];
                    hPageCheck = 0;
                } else { 
                    hPageCheck += h;
                    emlContent = [...emlContent, elm];
                }

                // console.log( h, hPageCheck );
            }
            if(emlContent.length > 0 ) samplesPage.push(emlContent);  

            const hfoot = Number(window.getComputedStyle(mtfoot).getPropertyValue('height')?.replace("px", ""));
            const pages = []; 
            for( let rind in samplesPage ) {  
                // const chead = head.cloneNode(true);
                const cdata = data.cloneNode(true);
                const table = cdata.querySelector("#tb-data");
                // const thead = table?.querySelector("thead");
                const tbody = table?.querySelector("tbody");
                const tfoot = table?.querySelector("tfoot");
 
                tbody.style.height = `${limitPage - hfoot}px`; 

                tbody.innerHTML = `${ (samplesPage[rind].map( m => m.outerHTML )).join("") }`;

                if( rind < (samplesPage.length - 1)) tfoot.remove();
                const temp = document.createElement('div'); 

                // temp.appendChild( chead );
                temp.appendChild( cdata );
                temp.classList.add("on-page")
                pages.push(temp);
            }
            // console.log( pages );
            setNewPageContent( state => [ ...state, ...pages]);
            r(pages);
        }) 
    } 

    const hhandlePrintMultiPagesPdf = () => { 
        setLoading(true);                
        // await handleCheckMultiPages();
        handleCheckMultiPages().then( (res) => { 

            setLoading(false);
        }).catch( err => {
            console.log( err );
            setLoading(false);
        });

        
        // const html = ReactDOMServer.renderToString(ElmContent);        
        // const head = document.getElementById("form-head");
        // const step = document.getElementById("form-body-step");
        // const parm = document.getElementById("form-body-parm");

        // const headHieght = Number(window.getComputedStyle(head).getPropertyValue('height')?.replace("px", ""));
        // const stepHieght = Number(window.getComputedStyle(step).getPropertyValue('height')?.replace("px", ""));
        // const parmHieght = Number(window.getComputedStyle(parm).getPropertyValue('height')?.replace("px", ""));
        // console.log( {headHieght, stepHieght, parmHieght} );
        // if( headHieght + stepHieght > limitPage ){
        //     parm.style.pageBreakBefore = 'always';
        // } 
        // console.log( html.toString() );

        // return componentRef.current;
    } 

    const handlePrintMultiPages = async () => {
        setLoading(true);
        handleCheckMultiPages().then( res => { 
            handlePrint(null, () => printRef.current ); 
            setLoading(false); 
        });
    }

    useEffect( () =>  {
        const init = () => {
            apiservice.get( code ).then( async res => {
              const { data : { head, list } } = res.data; 
           
            //   console.log({ head, list } ); 
              setHData( head );
              setDetails( [...list] ); 
            }).catch( err => {
              console.log(err);
              message.error("Error getting infomation Estimation.")
            }) 
        }

        init();
        return () => {}
    }, []);
    
    useEffect( () => {
        if( !!Number(print) ) setTimeout( () => handlePrintMultiPages(), 480 );
        return () => {}
    }, [hData, details]);

    const HeaderForm = ({...resProps}) => {
        return (<>
            <div className='print-head' style={{height:64}}> 
                <div className='print-title flex gap-5'> 
                    <div className='grow pb-2'>
                        <img src={logo} alt="" style={{paddingInline: 6, height: '100%'}}  />  
                    </div>
                    <div className='flex grow-0 justify-end items-center' style={{width: 278}}>
                        <Flex className='mb-0 '>
                            <Typography.Title level={3} align='end' className='m-0 min-w-28 text-end'>ใบสั่งซื้อ</Typography.Title> 
                        </Flex> 
                    </div> 
                </div> 
            </div>
            <ContentHead {...resProps}/>  
        </>)
    }

    const FooterForm = ({page}) => {
        return (
            <div className='print-foot' style={{height:34}}>  
                <div className='print-title flex justify-end'>   
                    <Flex className='mb-0'>
                        {/* <Typography.Text className='text-sm min-w-8'>Page</Typography.Text> */}
                        {/* <Typography.Text className='text-sm' strong>{page}</Typography.Text>  */}
                    </Flex> 
                </div> 
            </div>   
        )
    }

    const Summary = () => {
        return <>
            <Table.Summary.Row style={{height:24}}>
                <Table.Summary.Cell colSpan={7} className='!align-top'></Table.Summary.Cell>
            </Table.Summary.Row>

            <Table.Summary.Row className='r-sum'>
                <Table.Summary.Cell colSpan={3} rowSpan={2} className='!align-top !ps-0'>
                    <Flex vertical gap={12}>
                        <Flex vertical gap={2}>
                            {/* <Typography.Text className='tx-title' strong>หมายเหตุ</Typography.Text>  */}
                            <pre className='tx-info m-0'>หมายเหตุ :{hData?.remark}</pre> 
                        </Flex> 
                    </Flex>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={2} className='text-summary text-end !align-top' >
                    <Typography.Text className='text-sm text-end'>รวมเงินทั้งสิ้น</Typography.Text>
                </Table.Summary.Cell> 
                <Table.Summary.Cell colSpan={2} className='text-summary text-end !align-top' >
                    <Typography.Text className='text-sm text-end'>{comma( Number(hData?.total | 0), 2, 2 )} บาท</Typography.Text>
                </Table.Summary.Cell> 
            </Table.Summary.Row>  

            <Table.Summary.Row className='r-sum'> 
                <Table.Summary.Cell colSpan={2} className='text-summary text-end !align-top' >
                    <Typography.Text className='text-sm text-end'>ภาษีมูลค่าเพิ่ม { hData?.vat > 0 && '(7%)'}</Typography.Text>
                </Table.Summary.Cell> 
                <Table.Summary.Cell colSpan={2} className='text-summary text-end !align-top' >
                    <Typography.Text className='text-sm text-end'>{comma( Number(hData?.vat || 0 ), 2, 2)} บาท</Typography.Text>
                </Table.Summary.Cell> 
            </Table.Summary.Row>    

            <Table.Summary.Row className='r-sum rl'>
                <Table.Summary.Cell colSpan={3} className='!align-top text-center !ps-0 !pt-1'>
                    <Typography.Text className='text-sm text-end'>({thaiBahtText(hData?.grand_total)})</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={2} className='text-summary text-end !align-top' >
                    <Typography.Text className='text-sm text-end !text-white'>ยอดรวมสุทธิ</Typography.Text>
                </Table.Summary.Cell> 
                <Table.Summary.Cell colSpan={2} className='text-summary text-end !align-top' >
                    <Typography.Text className='text-sm text-end !text-white'>{comma( Number(hData?.grand_total || 0), 2, 2 )} บาท</Typography.Text>
                </Table.Summary.Cell> 
            </Table.Summary.Row>

            <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2} className='!align-top'>{'\u00A0'}</Table.Summary.Cell>
            </Table.Summary.Row> 

            <Table.Summary.Row>
                <Table.Summary.Cell colSpan={8} className='!align-top !ps-0 !pt-8'>
                    <Flex className='w-full' gap={32} >
                        <Flex vertical className='w-1/2' style={{ gap:10}}>
                            <Flex gap={2}>
                                <Typography.Text className='tx-info' strong style={{ minWidth:48}}>ผู้จัดทำ </Typography.Text> 
                                <Typography.Text className='tx-info' >{'\u00A0'}</Typography.Text>
                            </Flex>
                            <Flex gap={2}>
                                <div className="w-full" style={{height: 90, border:'1px solid var(---color--1)'}}>{'\u00A0'}</div>
                            </Flex>
                        </Flex> 
                        <Flex vertical className='w-1/2' style={{ gap:10}}>
                            <Flex gap={2}>
                                <Typography.Text className='tx-info' strong style={{ minWidth:48}}>ผู้อนุมัติ </Typography.Text> 
                                <Typography.Text className='tx-info' strong>{'\u00A0'}</Typography.Text>
                            </Flex>
                            <Flex gap={2}>
                                <div className="w-full" style={{height: 90, border:'1px solid var(---color--1)'}}>{'\u00A0'}</div>
                            </Flex>
                        </Flex>
                    </Flex>
                </Table.Summary.Cell>
            </Table.Summary.Row>              

            <Table.Summary.Row>
                <Table.Summary.Cell colSpan={8} className='!align-top !ps-0 !pt-3'>
                    <Flex className='w-full' gap={32} >
                        <Flex vertical className='w-1/2' style={{ gap:10}}>
                            <Flex gap={2} justify='space-between' className='w-full font-bold'> 
                                ( <Typography.Text className='tx-info font-normal' >{hData?.created_name}</Typography.Text>  )
                            </Flex>
                            <Flex gap={2} justify='space-between' className='w-full font-bold'> 
                                ( <Typography.Text className='tx-info font-normal' > {dayjs().format("DD/MM/YYYY")}</Typography.Text> )
                            </Flex>
                        </Flex> 
                        <Flex vertical className='w-1/2' style={{ gap:10}}>
                            <Flex gap={2} justify='space-between' className='w-full font-bold'> 
                                ( <Typography.Text className='tx-info' strong>{'\u00A0'}</Typography.Text> )
                            </Flex>
                            <Flex gap={2} justify='space-between' className='w-full font-bold'> 
                                ( <Typography.Text className='tx-info' strong>{'\u00A0'}</Typography.Text> )
                            </Flex>
                        </Flex>
                    </Flex>
                </Table.Summary.Cell>
            </Table.Summary.Row>              
        </>
    }

    const ContentHead = ({page}) => {
        return ( 
        <div className='content-head in-head flex flex-col'> 
            <div className='print-title flex pb-2'>
                <div className='flex ps-3 grow-0' style={{width:280}}>
                    <Flex className='mb-1.5' vertical >
                        <Typography.Text className='tx-title min-w-48' strong>สั่งซื้อโดย</Typography.Text>
                        <Typography.Text className='tx-info' >คิวซีที ออโต้ (อ้อมใหญ่-สามพราน)</Typography.Text> 
                        <Typography.Text className='tx-info' >34/2 หมู่4 ถ.เพชรเกษม ต.อ้อมใหญ่ อ. สามพราน นครปฐม 73160</Typography.Text> 
                        <Typography.Text className='tx-info' >โทร. 02-8126353, 02-8126354</Typography.Text> 
                    </Flex>
                </div> 
                <div className='flex ps-3 grow-0' style={{width:280}}>
                    <Flex className='mb-1.5' vertical>
                        <Typography.Text className='tx-title min-w-48' strong>ข้อมูลผู้ขาย</Typography.Text>
                        <Typography.Text className='tx-info'>{hData?.supname}</Typography.Text> 
                        <Typography.Text className='tx-info'>{hData?.address}</Typography.Text> 
                        { hData?.contact_name && <Typography.Text className='tx-info'>ติดต่อ {hData?.contact_name || '-'}</Typography.Text> }
                        { hData?.contact_tel && <Typography.Text className='tx-info'>โทร. {hData?.tel}</Typography.Text> }
                        { hData?.contact_email && <Typography.Text className='tx-info'>อีเมล {hData?.email}</Typography.Text> }
                    </Flex> 
                </div> 
                <div className='flex px-3 grow justify-end'>
                    <Flex className='mb-1.5 w-full' vertical>
                        <Typography.Text className='tx-title min-w-48' strong>ข้อมูลใบสั่งซื้อ</Typography.Text>
                        <Flex justify='space-between'>
                            <Typography.Text className='tx-info' strong>เลขที่ใบสั่งซื้อ</Typography.Text> 
                            <Typography.Text className='tx-info'>{hData?.purcode}</Typography.Text>  
                        </Flex>
                        <Flex justify='space-between'>
                            <Typography.Text className='tx-info' strong>วันที่ใบสั่งซื้อ</Typography.Text> 
                            <Typography.Text className='tx-info'>{dayjs(hData?.purdate).format("DD/MM/YYYY")}</Typography.Text>  
                        </Flex>
                        <Flex justify='space-between'>
                            <Typography.Text className='tx-info' strong>วันที่ส่งของ</Typography.Text> 
                            <Typography.Text className='tx-info'>{dayjs(hData?.dated_price_until).format("DD/MM/YYYY")}</Typography.Text>  
                        </Flex>
                        <Flex justify='space-between'>
                            <Typography.Text className='tx-info' strong>การชำระเงิน/เครดิสเทอม</Typography.Text> 
                            <Typography.Text className='tx-info'>{hData?.payment}</Typography.Text>  
                        </Flex>
                        <Flex justify='space-between'>
                            <Typography.Text className='tx-info' strong>หน้า</Typography.Text> 
                            <Typography.Text className='tx-info'>{page}</Typography.Text>  
                        </Flex>
                    </Flex>
                </div> 
            </div>  
        </div>
        )
    }

    const ContentBody = () => {
        return ( 
        <div className='content-body in-data flex flex-col'> 
            <Table 
                id="tb-data"
                size='small'
                dataSource={details}
                columns={columnDesc()}
                pagination={false}
                rowHoverable={false}
                rowKey="id"
                bordered={false}
                locale = {{ emptyText: <span>No data available, please add some data.</span> }}
                onRow={(record, index)=>{ 
                    return { className: 'r-sub'}
                }}
                summary={Summary}
            />
        </div>
        )
    } 

    const Pages = () => (
        <div ref={componentRef} id="raw"> 
            <ContentData> 
                {/* <ContentHead /> */}
                <ContentBody />
            </ContentData>
        </div>    
    )

    const ContentData = ({ children, pageNum = 1, total = 1 }) => {
        return ( 
            <div className='purchase-order-pages flex flex-col'>
                <HeaderForm page={`${pageNum}/${total}`}/>
                <div className='print-content grow'> 
                    {children}
                </div>
                <FooterForm />
            </div>
        )
    }

    return (
        <> 
            <div className='page-show' id="purchase-order-print"> 
                { loading && <Spin fullscreen indicator={<LoadingOutlined  />} /> }
                <div className="title-preview flex gap-4"> 
                    { !Number(print) && <Button
                        className='bn-center  !bg-blue-400' 
                        onClick={() => { handlePrintMultiPages() }}
                        // onClick={() => { handlePrint(null, () => componentRef.current ); }}
                        icon={<PiPrinterFill style={{fontSize:'1.1rem'}} />}
                    >
                        PRINT
                    </Button>}
                    { false && <Button
                        className='bn-center  !bg-red-600 text-white hover:!text-white hover:!border-red-800' 
                        onClick={() => { hhandlePrintMultiPagesPdf() }}
                        // onClick={() => { handlePrint(null, () => componentRef.current ); }}
                        icon={<TbFileTypePdf style={{fontSize:'1.1rem'}} />}
                    >
                        PDF
                    </Button>}
                </div>         
                <div className="layout-preview">
                    <Pages />
                </div>
                <div className='hidden' id="mypage">
                    <div ref={printRef} >
                        {newPageContent?.map( (page, i) => {
                        // console.log( page.innerHTML );
                        return ( 
                            <div key={i} className='on-page'>
                                <ContentData pageNum={i+1} total={(newPageContent?.length)} > 
                                    <div dangerouslySetInnerHTML={{ __html: page.innerHTML }}></div>
                                </ContentData>
                                {i < (newPageContent.length-1) && <div className='page-break'></div>}
                            </div>)
                        })}
                    </div>
                </div>
            </div>        
        </>


    )
}

export default MyPrintPreview