import { Form, Input, Typography } from "antd";


export const infoItem = (val) => [
    {
      key: 'stcode',
      label: 'รหัสสินค้า',
      children: <Typography.Text>{ val?.stcode || "-" }</Typography.Text>,
    },
    {
      key: 'stname',
      label: 'ชื่อสินค้า',
      children: <Typography.Text>{ val?.stname || "-" }</Typography.Text>,
    },
    {
      key: 'typename',
      label: 'ประเภทสินค้า',
      children: <Typography.Text>{val?.typename || "-"}</Typography.Text>,
    },
    {
      key: 'material_code',
      label: 'Material code',
      children: <Typography.Text>{val?.material_code || '-'}</Typography.Text>,
    },
  ];

export const infoVat = (val) => [
    {
      key: 'stcode_vat',
      label: "รหัสสินค้าคลัง VAT",
      contentStyle: { paddingInline: '0 2rem'},
      children: (
        <Form.Item className="!mb-0 w-full"  name='stcode_vat'  rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' }]}>
            <Input placeholder='กรอกรหัสสินค้าคลัง VAT'/>
        </Form.Item>
      ),
    },
    {
      key: 'stname_vat',
      label: 'ชื่อสินค้าคลัง VAT',
      children: <Typography.Text>{ val?.stname_vat || "-" }</Typography.Text>,
    },
  ];
  