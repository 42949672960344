 
import { comma, dateToThaiFormat } from "../../../utils/util"; 
export const column = () => [
  {
    title: "ลำดับ",
    dataIndex: "ind",
    key: "ind",
    width: 80,  
    render: (im, rc, index) => <>{index + 1}</>,
  },
  {
    title: "เลขที่ใบรายการซ่อม",
    dataIndex: "rpocode",
    key: "rpocode",
    width: '25%',   
  },
  {
    title: "เลขที่ใบกำกับภาษี",
    dataIndex: "invcode",
    key: "invcode",
    width: '25%',  
    render: (im) => <>{im || '-'}</>,
  },
  {
    title: " วันที่ใบรายการซ่อม",
    dataIndex: "rpodate",
    key: "rpodate",
    width: '25%',  
    render: (v) => dateToThaiFormat(v),
  },
  {
    title: " รวมเงิน",
    dataIndex: "price_total",
    key: "price_total",
    align: 'right', 
    className: '!pe-4',
    render: (v) => comma( v, 2, 2),
  },
]; 