/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { Modal, Card, Form, Typography, Flex, Select, Tag, Button, Drawer, Tooltip, Checkbox } from "antd";
import { Row, Col, Space } from "antd";
// import { Input } from "antd";
import { TbArrowBack, TbBuildingFactory2, TbBuildingWarehouse, TbLocationSearch, TbShoppingCartCopy, TbTransferIn } from "react-icons/tb";
import { TableSearchValue } from '../../form/index.js';
import OptionService from '../../../service/Options.service.js';
import { v4 as uuidv4 } from 'uuid';

import { useTbSearchContext } from '../../../store/context/table-search.context.js';
import { myModalTiresColumn } from "./model.module.js";
// import { TagIs } from '../../badge-and-tag/index.js';
import MyModalItemTiresAmount from './MyModalItemTiresAmount.jsx';

const optrequest = OptionService();
const tbparamsDefault = { pagination: { current: 1, pageSize: 10 } };
const emptyText = { emptyText:<span>ไม่มีข้อมูลสินค้า</span> };


export default function MyModalItemsTires({show, close, values, selected={}, hasQt=false}) {
    // const plainOptions = [
    //     {label:<TagIs result={1} />, value: 1 },
    //     {label:<TagIs result={0} />, value: 0 },
    // ];
    const thisYear = ( new Date() ).getFullYear();
    const yearOptions = Array.from({ length: 7 },  () => thisYear ).map( (v, i) => ({ value: v - i, label: v - i }) );
    const quarterOptions = Array.from({ length: 4 }).map( (v, i) => ({ label: `Q${i+1}`, value: i+1 }) );
    const containerStyle = {
      position: 'relative',
      overflow: 'hidden',
      height: '100%'
    };

    const context = useTbSearchContext();
    const [form] = Form.useForm();
    const [modal, contextHolder] = Modal.useModal();
    const [loading,  setLoading] = React.useState(true); 

    const [openModal, setOpenModal] = React.useState(show);
    const [openAmountSelected, setOpenAmountSelected] = React.useState(false);
    const [isAmountOpened, setIsAmountOpened] = React.useState(false);

    const [criteria, setCriteria] = React.useState({});

    const [tbparams, setTbparams] = React.useState({...tbparamsDefault});
    const [pageValue, setPageValue] = React.useState({...tbparamsDefault.pagination});

    const [optionsLocation, setOptionsLocation] = React.useState([]);

    const [dataSource, setDataSource] = React.useState([]); 
    const [dataSelect, setDataSelect] = React.useState([]); 
    // const [resultStock, setResultStock] = React.useState([]);

    const [inited, setInited] = React.useState(false);

    // const [checkedList, setCheckedList] = React.useState([]); 
    const [checkedQuarteList, setCheckedQuarterList] = React.useState([]); 

    const keySelected = React.useMemo(() => {
        
      return selected.stcode;
    }, [selected]); 

    // const onChange = (list) => {
    //   setCheckedList(list);
    // };
 
    const handleClose = () =>{ 
        // setOpenModal(false);
        // console.log( show );
        // close(false)
        setTimeout( () => { close(false);  }, 240);
        //setTimeout( () => close(false), 200 );
    }

    const handleCriteria = (_, criteria) => { 
        setCriteria(criteria);
        setTbparams(page => ({ 
          ...page, 
          pagination: {...page?.pagination, current: 1},
          current: 1
        }));
    }

    const handleRowSelect = async (record, key) => { 
        const keySelect = record.map( m => m?.key_code );
        const rowlost = dataSelect.find( f => !keySelect.includes( f.key_code ) );
        if( !!rowlost && Number( rowlost?.qty || 0 ) > 0 ) {
            const result = await modal.confirm({title: 'จำนวนสินค้าที่กรอกไปแล้วจะถูกยกเลิก', content: 'ยืนยันที่จะทำรายการต่อหรือไม่'});
            if( !result ){
                context.clickSelect( 'key_code', dataSelect, true );
                return;
            }
        }
        // console.log( record, key );
        const newRecord = record?.map( m => ({ ...m, qty: m?.qty || 0 }));
        setDataSelect([...newRecord]); 
        // handleClose(); 
    }

    const handleChoosed = () => {
        const qty = dataSelect?.reduce( (a,v) => a += Number( v.qty ), 0);
        if( qty !== Number(selected?.amount) && hasQt){ 
            Modal.error( { content: "จำนวนที่กรอกยังไม่เพียงพอสำหรับที่ต้องการใช้" } );
            return;
        }
        
        typeof values === "function" && values( dataSelect ); 
        // handleClose();
        setOpenModal(false);
    } 

    const handleOpenAmountItems = () => {
        setOpenAmountSelected(true);
        setIsAmountOpened(true); 
    } 

    const handleSearch = React.useCallback( async () => {
        if( !inited )  return;
        const parm = { criteria:{ ...criteria, stcode:keySelected }, tbparams, p:"stock-tires" };
        setLoading(true);
        try { 
            const res = await optrequest.optionsStockPost(parm, { ignoreLoading:true } )
            const { data:{ source, tbparams } } = res.data;
            
            setPageValue(tbparams?.pagination);
            // setPaing( state => ( {...state, ...pagination }) );
            setDataSource(source);

            setTimeout( () => { 
                handleCheckStock();
                setLoading(false) ;
            }, 200 );
      } catch(err) {
        setTimeout( () => setLoading(false), 200 );
      } 
    }, [criteria, tbparams]);

    const handleAmountChange = (v) => {
        const newSource = [...v];
        // setResultStock([...newSource]);
        setDataSelect([...newSource]);
    }

    const handleCheckStock = () => {
        const { stock } = selected;
        if( !!stock && stock?.length > 0 ){
          context.clickSelect( 'key_code', stock, true );
        
          setDataSelect( stock );

          handleOpenAmountItems();
        }
    }    

    React.useEffect(() => {
        handleSearch();
    
    }, [handleSearch]);
 

    React.useEffect(() => {
      const initeil = async() => {
        // console.log( keySelected, !keySelected)
          if( !keySelected ) return;
          const [locationRes ] = await Promise.all([
              optrequest.optionsStock({p:"location-stock-with-item", code:keySelected})
          ]);
          setOptionsLocation( locationRes.data.data );


          setCriteria({stcode: keySelected});
          setInited(true); 
      } 
      initeil();     


      return () => {  setInited(false) };
    }, []);

    React.useEffect(() => { 
        if( !openModal ) close(openModal)
    
    }, [openModal])

    const modalTitle = (<>
        <Flex align='center' gap={4}>
            <TbBuildingWarehouse style={{fontSize: '1rem'}} />
            <Typography.Text className='ms-1 mb-0'>เลือกสต๊อกสินค้า</Typography.Text>
        </Flex>
    </>);

    const modalFooter = (<> 
        <Row>
          <Col span={12}>{
            openAmountSelected && <Button
                className="bn-center bn-secondary items-center"
                icon={<TbArrowBack style={{fontSize:'1rem'}} />}
                style={{ width: 160 }}
                onClick={() => setOpenAmountSelected(false)}
            >กลับ</Button>
          }</Col>
          <Col span={12}>
            <Flex justify="flex-end">{
                !openAmountSelected
                ?(
                    <Button
                        className="bn-center bn-primary items-center"
                        icon={<TbTransferIn style={{fontSize:'1rem'}} />}
                        style={{ width: 160 }}
                        onClick={() => handleOpenAmountItems()}
                        disabled={dataSelect.length < 1}
                    >ยืนยันเลือกสต๊อก</Button>                    
                )
                :(
                    <Button
                        className="bn-center bn-success items-center"
                        icon={<TbShoppingCartCopy style={{fontSize:'1rem'}} />}
                        style={{ width: 160 }}
                        onClick={() => handleChoosed()}
                    >ยืนยันการทำรายการ</Button>
                )
              }
            </Flex>
          </Col>
        </Row>
    </>);

    const modalSearch = (<>
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'> 
            <Col xs={24} sm={24} lg={24}>  
                <Form.Item label='ประเภทสินค้า' name='items_type'>
                    <Select 
                        mode='multiple'
                        allowClear
                        autoClearSearchValue={false}
                        tagRender={(props) => <Tag {...props} color='var(--primary)' icon={<TbLocationSearch />} className='flex gap-1 items-center' style={{height: '1.84rem'}} >{props.label}</Tag>}
                        style={{ width: '100%', height: '50px' }}
                        placeholder="เลือกสถานที่เก็บ"
                        maxTagCount= 'responsive'
                        options={optionsLocation}
                        suffixIcon={<TbLocationSearch style={{fontSize:'1rem', color:'#000'}} />}
                        getPopupContainer={() => document.getElementById('modal-area')}
                        maxTagPlaceholder={(omittedValues) => (
                            <Tooltip title={
                                <div className='flex flex-wrap gap-1'>
                                    {omittedValues.map(({ label, i }) => (
                                        <Tag color='var(--primary)' key={uuidv4()} icon={<TbLocationSearch />} className='flex gap-1 items-center' >{label}</Tag>
                                    ))} 
                                </div>
                            }
                                color='white'
                                placement="bottomRight"
                            >
                                 <Tag color='var(--primary)' icon={<TbLocationSearch />} className='flex gap-1 items-center mb-1' >{omittedValues.length}+</Tag>
                            </Tooltip>
                        )}
                    ></Select>
                </Form.Item> 
            </Col> 

            {/* <Col xs={24} sm={24} lg={12}> 
                <Space direction="vertical" size="middle" >
                    <Form.Item label="สต๊อก vat" name='vat' > 
                        <Checkbox.Group options={plainOptions} value={checkedList} onChange={onChange} className='modal-checkbox' name='vat' />
                    </Form.Item> 
                </Space>
            </Col>  */}

            <Col xs={24} sm={24} lg={12}> 
                <Form.Item label='ปีที่ผลิต' name='mfyear'>
                    <Select 
                        mode='multiple'
                        allowClear
                        autoClearSearchValue={false}
                        tagRender={(props) => <Tag {...props} color='var(--primary)' icon={<TbBuildingFactory2 />} className='flex gap-1 items-center' style={{height: '1.84rem'}} >{props.label}</Tag>}
                        style={{ width: '100%', height: '50px' }}
                        placeholder="เลือกปีที่ผลิต"
                        maxTagCount= 'responsive'
                        options={yearOptions}
                        suffixIcon={<TbBuildingFactory2 style={{fontSize:'1rem', color:'#000'}} />}
                        getPopupContainer={() => document.getElementById('modal-area')}
                    ></Select>
                </Form.Item>             
            </Col>

            <Col xs={24} sm={24} lg={12}> 
                <Form.Item label="ไตรมาส" name='quarter' > 
                    <Checkbox.Group options={quarterOptions} value={checkedQuarteList} onChange={(v)=>setCheckedQuarterList(v)} className='modal-checkbox' name='quarter' />
                </Form.Item>
            </Col>             
        </Row>   
    </>);

    return (<> 
    <div className='modal-suppliers' id="modal-area"> 
        <Modal
            open={openModal}
            title={modalTitle}
            // afterOpenChange={(e)=>{ console.log(e) }}
            afterClose={() => { handleClose(); }}
            onCancel={() => setOpenModal(false)} 
            maskClosable={false}
            // style={{ top: 10, height: 'calc(100vh - 10px)' }}
            style={{ top: 10 }}
            width={1060}
            className='mymodal'
            footer={modalFooter}
            // containerStyle={{ height: 'calc(100vh - ( 10px * 2) )' }}
        >
            <div style={containerStyle}>  
                <Space direction="vertical" size="middle" style={{ display: 'flex', position: 'relative'}} className='current'  >
                    <Card style={{backgroundColor:'#f0f0f0' }}>
                        <Form form={form} layout="vertical" autoComplete="off" onValuesChange={handleCriteria} >
                            {modalSearch}
                        </Form>
                    </Card>
                    <Card style={{minHeight:440}}>
                        <TableSearchValue 
                            title='รายการข้อมูลสินค้า'
                            multi={true}
                            pagingDefault={tbparamsDefault?.pagination}
                            onPageChange={(page)=>setTbparams(page)}
                            onSelectedRow={handleRowSelect}
                            tbProps={{
                                rowKey:"key_code",
                                dataSource:dataSource,
                                columns:myModalTiresColumn(),
                                pagination: pageValue,
                                locale:{...emptyText},
                                loading
                            }}
                        />
                    </Card>
                </Space>      
                {isAmountOpened && 
                    <Drawer
                        title={`สต๊อกที่เลือก ${dataSelect.length} รายการ`}
                        onClose={() => { setOpenAmountSelected(false); }}
                        open={openAmountSelected} 
                        width='100%'
                        className="responsive-drawer" 
                        afterOpenChange={(e)=>{ !e && setIsAmountOpened(false); }}
                        getContainer={false}
                    >
                        <MyModalItemTiresAmount master={selected} list={dataSelect} change={handleAmountChange} hasQt={hasQt} />
                    </Drawer>
                } 
            </div>
        </Modal>
    </div>         
    {contextHolder}
    </>)
}