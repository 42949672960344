import { Badge } from "antd";

export const accessColumn = () => [  
  {
    title: "location_code",
    dataIndex: "location_code",
    key: "location_code",
    width: "20%",
    hidden: true,
  },
  {
    title: "ชื่อสถานที่จัดเก็บ",
    dataIndex: "location_name",
    key: "location_name",
    width: "50%",
    hide: false,
  },
  {
    title: "สถานะการใช้งาน",
    dataIndex: "active_status",
    key: "active_status",
    width: "25%",
    hide: false,
    render: (data) => (
      <div>
        {data === "Y" ? (
          <Badge status="success" text="เปิดการใช้งาน" />
        ) : (
          <Badge status="error" text="ปิดการใช้การ" />
        )}
      </div>
    ),
  },
];

export const formData = {
  location_code: null,
  location_name: null,
  active_status: null,
};
