import { Col, Divider, Form, Input, Row, Select, Space, Badge } from "antd";
import React, { useEffect } from "react";
import { filterOption } from "../../utils/util";
import OptionService from "../../service/Options.service";

const ops = OptionService();
export default function ItemtypeManageForm({
  formName = "form-name",
  submit,
  initeial,
  mode,
}) {
  const [form] = Form.useForm();
  const formRole = { required: true, message: "กรุณากรอกข้อมูลให้ครบถ้วน!" };
  const [optionCategory, setOptionCategory] = React.useState([]);
  useEffect(() => {
    const init = async () => {
      // console.log( initeial )
      const [opCategoryRes] = await Promise.all( [ 
        ops.optionsItemscategory() 
      ]);

      setOptionCategory(opCategoryRes.data.data);

    }

    init();
    //  console.log(initeial);
    if (mode !== "create") {
      form.setFieldsValue(initeial);
    }
  }, [initeial, mode, form]);

  const dividerProp = {
    orientation: "left",
    style: { marginBlock: 10 },
    className: "!border-black",
  };

  const onFinish = (values) => {
    submit(values);
  };

  return (
    <Space direction="vertical" className="w-full">
      <Form
        form={form}
        layout="vertical"
        name={formName}
        autoComplete="off"
        className="w-full"
        // onValuesChange={(_, value)=> setFormValue(value)}
        onFinish={onFinish}
      >
        <Divider {...dividerProp}>ประเภทสินค้า</Divider>
        <Row className="!mx-0" gutter={[8, 8]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <Form.Item
              label="หมวดหมู่สินค้า"
              name="categorycode"
              rules={[formRole]}
            >
              <Select
                size="large"
                showSearch
                filterOption={filterOption}
                placeholder="เลือกหมวดหมู่สินค้า"
                options={optionCategory.map((item) => ({
                  value: item.categorycode,
                  label: item.categoryname,
                }))}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <Form.Item
              label="ชื่อประเภทสินค้า"
              name="typename"
              rules={[formRole]}
            >
              <Input placeholder="กรอกชื่อประเภทสินค้า" />
            </Form.Item>
          </Col>          
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            style={
              mode === "edit" ? { display: "inline" } : { display: "none" }
            }
          >
            <Form.Item name="active_status" label="สถานะการใช้งาน">
              <Select
                size="large"
                options={[
                  {
                    value: "Y",
                    label: <Badge status="success" text="เปิดการใช้งาน" />,
                  },
                  {
                    value: "N",
                    label: <Badge status="error" text="ปิดการใช้งาน" />,
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="typecode">
              <Input type="hidden" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Space>
  );
}
