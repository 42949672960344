import React from 'react'
import { TbSearchProvider } from '../../../store/context/table-search.context';

import MyModalItems from './MyModalItems';
export default function MyModalItemsForPurc({...restProps}) {
    // const [open, setOpen] = React.useState(show);
    // console.log( {...restProps} )
    // const handleChoosed = ( value ) => { 
    //     typeof values === "function" && values( value );
    // }
    return (
        <TbSearchProvider>  
            <MyModalItems {...restProps} />
        </TbSearchProvider>
    )
}