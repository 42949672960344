import React from 'react'
import { Modal, Typography, Flex, Tabs } from "antd";
import { TbUserDollar } from "react-icons/tb"; 
// import SupplierService from '../../../service/Supplier.service.js';

import { useTbSearchContext } from '../../../store/context/table-search.context.js';
import { tabItems } from './model.module.js';
import StickyBox from 'react-sticky-box';

// import { v4 as uuidv4 } from 'uuid';
// import MyModalAppBusiness from './MyModalAppBusiness.jsx';
// import MyModalAppPerson from './MyModalAppPerson.jsx';  
// const apirequest = SupplierService();
export default function MyModal({show, close, values, countRpo}) {
    const context = useTbSearchContext();
    const [openModal, setOpenModal] = React.useState(show);
    const [currentTab, setCurrentTab] = React.useState(1);
    const containerStyle = {
      position: 'relative',
      overflow: 'hidden',
    };    


    const handleClose = () =>{ 
        setOpenModal(false);
        context.clearSelect();
        setTimeout( () => { close(false);  }, 140); 
        //setTimeout( () => close(false), 200 );
    }

    const handleValue = ( res, key ) => {
        if( !key ) return;
        
        if( !!values ) values( res, key, currentTab );

        handleClose();
    }


    
    const items = tabItems(handleValue, countRpo);

    const renderTabBar = (props, DefaultTabBar) => (
      <StickyBox offsetTop={8} offsetBottom={8} style={{ zIndex: 1 }} >
        <DefaultTabBar {...props} />
      </StickyBox>
    );

    const modalTitle = (<>
        <Flex align='center' gap={4}> 
            <TbUserDollar style={{fontSize: '1rem'}} />
            <Typography.Text className='ms-1 mb-0'>รายการลูกค้า</Typography.Text>
        </Flex>    
    </>);

    return (<>
        <div className='modal-suppliers' id="modal-area">
            <Modal
                open={openModal}
                title={modalTitle}
                afterClose={() => handleClose() }
                onCancel={() => setOpenModal(false) } 
                maskClosable={false}
                style={{ top: 0, height: 'calc(100vh - 10px)' }}
                width={840}
                className='modal-loading-type mymodal'
                footer={<></>}
            >
            {/* <Spin spinning={loading} > */}
                <div style={containerStyle}> 
                    <Tabs renderTabBar={renderTabBar} items={items} destroyInactiveTabPane={false} defaultActiveKey="1" onChange={(k)=>setCurrentTab(Number(k))} /> 
                </div>
            {/* </Spin> */}
            </Modal>
        </div>
    </>)
}

