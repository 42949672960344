import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client'; 
import App from "./App";  
const originalError = console.error;

// Override console.error with custom implementation
console.error = function(...args) {
  if( args?.find( f => f?.includes("findDOMNode is deprecated"))) return; 
  if( args?.find( f => f?.includes("[antd: message]"))) { 
    console.warn( ...args );
    return; 
  }
  // Call the original console.error function
  originalError.apply(console, args);

  // Here you can log errors to your error tracking service or perform any other custom handling
};

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript


root.render(<App />);