import React, { createContext, useContext, useState } from 'react';

const TbSearchContext = createContext();

export const TbSearchProvider = ({ children }) => {
  const [rowKeySelect, setRowKeySelect] = useState([]);
  const [rowRecordSelect, setRowRecordSelect] = useState([]);

  const clickSelect = (keys = null, record, multi = false) => {
    const newSelectedRowKeys = [...rowKeySelect];
    const newSelectedRecord = [...rowRecordSelect];
    const rowKey = keys || "id";
    const recordKey = multi ? record.map( m => m[rowKey]) : record[rowKey];
  
    // console.log( recordKey, record, newSelectedRecord, newSelectedRowKeys );
    if (newSelectedRowKeys.includes(recordKey)) {
      setRowKeySelect(newSelectedRowKeys.filter(key => key !== recordKey));
      setRowRecordSelect(newSelectedRecord.filter(rec => rec[rowKey] !== recordKey))
    } else {
      // console.log( [ ...new Set([...rowKeySelect, ...recordKey])] )
      // console.log( [ ...new Set([...rowRecordSelect, ...record].map( s => JSON.stringify(s))) ].map( m => JSON.parse(m)) )

      setRowKeySelect(state => multi ? [ ...new Set([...state, ...recordKey])] : [recordKey]); 
      setRowRecordSelect(state => multi ? [ ...new Set(
        ([...state, ...record].map( s => JSON.stringify(s)))
      )].map( m => JSON.parse(m)) : [record])
    } 
  }

  const clearSelect = () => {
    setRowKeySelect([]);
    setRowRecordSelect([]); 
  }

  return (
    <TbSearchContext.Provider value={{ rowKeySelect, setRowKeySelect, rowRecordSelect, setRowRecordSelect, clickSelect, clearSelect}}>
      {children}
    </TbSearchContext.Provider>
  );
};

export const useTbSearchContext = () => {
  return useContext(TbSearchContext);
};