import React from 'react'
import { Route } from 'react-router-dom'

import {
  ApprovePurchaseOrder, 
  ApprovePurchaseOrderAccess, 
  ApprovePurchaseOrderManage, 
  ApprovePurchaseOrderInfo,
  ApprovePurchaseOrderManageForm,
} from '../pages/purchase-order-approve'; 


export const ApproveRouter = (<>
  <Route path="/purchase-orders-approve/" exact element={<ApprovePurchaseOrder />}>
      <Route index element={<ApprovePurchaseOrderAccess />} />
      <Route path="manage" element={<ApprovePurchaseOrderManage />} />
      <Route path="info" element={<ApprovePurchaseOrderInfo />} />
      <Route path="manage-approve" element={<ApprovePurchaseOrderManageForm />} />
  </Route>
</>)