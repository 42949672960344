/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { QctPageLayout } from '../../components/layout'; 
import { Form, message } from 'antd';
import dayjs from "dayjs";
import PurchaseOrderManageForm from './MyManageForm';
import PurchaseOrderManageView from './MyManageView';

import BillingNoteService from "../../service/BillingNote.service.js"; 
import { TbSearchProvider } from '../../store/context/table-search.context';

import { formData } from "./model";
import { CancelButton } from '../../components/drawer/cancel';
import { ButtonCreateBililnNote } from '../../components/modal/billing-note';
 
const apirequest = BillingNoteService();

function Manage() {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const location = useLocation();

  const { config:{ mode, acname, code } } = location.state || { config: null };

  const [formValue, setFormValue] = useState(formData); 

  const [source, setSource] = React.useState([]);
  const [choose, setChoose] = React.useState([]);
  // const [header, setHeader] = React.useState({});

  const handleSubmit = async (value) =>{ 
    try {  
      const {head, list} = value;
      // setHeader(head);
      setChoose(list); 
  
      await apirequest[mode]({head, list});
      navigate(-1);
      message.success("Request create done.");
    } catch ( er ){
      console.log( er ); 
      message.error("Error request.")
    }
  }

  const handleCancel = async (v) => {

    try { 
      await apirequest.deleted({comment:v, code});
      navigate(-1);
      message.success("ยกเลิกใบสั่งสินค้าเรียบร้อย");
    } catch ( er ){
      console.log( er ); 
      const { data:{ option } } = er.response;
      message.error(`Error request. ${option}`);
    }   
  }

  const handleChoosed = (value) => {
    setSource(value);
    setChoose(value);
  }

  useEffect( () => {
    const initeil = async () =>{
      try {
        if( mode !== "create" && !!code){
          const res = await apirequest.get(code);
          const { data:{ head, list } } = res.data; 

          head.bndate = !!head.bndate ? dayjs(head?.bndate) : null;
          head.payment_duedate = !!head.payment_duedate ? dayjs(head?.payment_duedate) : null;
          // setHeader(head);
          setChoose(list);
          setFormValue({ head, list}); 
        } 
      } catch ( er ){
        console.log( er );
        message.error("error request.")
      }
    }
    initeil();
  }, [mode, code]);

  const acButton = (<>
  { mode !== "view" 
    ?<ButtonCreateBililnNote value={handleSubmit} source={source} selected={choose} formSet={form} disabled={source.length < 1} />
    :<CancelButton value={handleCancel} />
  }
  </>); 
 

  return (<>
    <QctPageLayout title={`ใบวางบิล - ${acname}`} back={true} footerRight={acButton} hearderRight={acButton} >
    {mode === "view"
      ? <PurchaseOrderManageView initeial={formValue} />
      : (<TbSearchProvider>
          <PurchaseOrderManageForm choosed={handleChoosed} initeial={formValue} formSet={form} />
        </TbSearchProvider>)
    }
 
    </QctPageLayout>  
  </>)
}

export default Manage