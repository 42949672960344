import { TbTagStarred } from "react-icons/tb";
import { TagsMulti } from "../../components/badge-and-tag";

export const accessColumn = () => [
    {
      title: "รหัสผู้ขาย",
      dataIndex: "supcode",
      key: "supcode",
      width: 120, 
      align: "left",
      sorter: true,
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => (a?.supcode || "").localeCompare(b?.supcode || ""),
    },
    {
      title: "ชื่อผู้ขาย",
      dataIndex: "supname",
      key: "supname", 
      align: "left",
      sorter: true, 
    },
    {
      title: "ติดต่อ",
      dataIndex: "contact_name",
      key: "contact_name", 
      width: "22%",
      align: "left",
      sorter: true,
      hide: false,
    },
    {
      title: "เบอร์โทร",
      dataIndex: "tel",
      key: "tel",
      width: "12%",
      align: "left",
      sorter: true,
      hide: false,
    },
    {
      title: "จังหวัด",
      dataIndex: "province",
      key: "province",
      width: "12%",
      align: "left",
      sorter: true,
      hide: false,
    },
    {
      title: "แท็ก/ประเภทการขาย",
      dataIndex: "tag",
      key: "tag",
      width: 190,
      align: "left",
      sorter: true,
      hide: false,
      render: (data) => <TagsMulti data={JSON.parse(data)} icon={<TbTagStarred />}  />
    },
]; 

export const formData = { 
    id: null,
    supcode: null,
    supname: null,
    address: null,
    zipcode: null,
    district: null,
    amphoe: null,
    province: null,
    tel: null,
    fax: null,
    taxnumber: null,
    email: null,
    remark: null,
    active_status: null,
    contact_name: null,
    contact_tel: null,
    tag: [],
}