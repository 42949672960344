import React from 'react'
import { TbSearchProvider } from '../../../store/context/table-search.context';

import MyModalItem from './MyModalItem';
export default function MyModal({...restProps}) {
    return (
        <TbSearchProvider>
            <MyModalItem {...restProps} />
        </TbSearchProvider>
    )
}