/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { QctPageLayout } from '../../components/layout';
import { TbCheck } from "react-icons/tb";
import { Button, message } from 'antd';
import SuppliersManageForm from './MyManageForm';

import SupplierService from "../../service/Supplier.service";

import { formData } from "./model";

const formName = "sup-form";
const apirequest = SupplierService();
function Manage() {
  const navigate = useNavigate();
  const location = useLocation();

  const { config:{ mode, acname, code, record } } = location.state || { config: null };

  const [formValue, setformValue] = useState(formData);
  const handleSubmit = async (value) =>{ 
    try {  
      // console.log( value );
      // const action = mode === "create" ? apirequest.create(value) : apirequest.update(value);
      await apirequest[mode]({...record, ...value, id:code});
      navigate(-1);
      message.success("Request create done.");
    } catch ( er ){
      console.log( er ); 
      message.error("Error request.")
    }
  }

  useEffect( () => {
    const initeil = async () =>{
      try {
        if( mode !== "create" && !!code){
          const res = await apirequest.get(code);
          const { data } = res.data; 
          setformValue( data || record ); 
        } 
      } catch ( er ){
        console.log( er );
        
        message.error("error request.")
      }
    }
    initeil();
  }, [mode, code]);

  const frButton = (
    <>
      <Button
        icon={<TbCheck style={{ fontSize: "1rem" }} />}
        className="bn-center bn-primary"
        form={formName}
        htmlType="submit"
      >
        ยืนยัน/บันทึก
      </Button>
    </>
  );

  return (<>
    <QctPageLayout title={`ผู้ขาย - ${acname}`}  back={true} hearderRight={frButton} >
      <SuppliersManageForm  formName={formName} submit={handleSubmit} initeial={formValue} />
    </QctPageLayout> 
  </>)
}

export default Manage