import React from 'react'
import './MyTransitionStyle.css';
import { CSSTransition, SwitchTransition } from 'react-transition-group'; 

function MyTransitionSwitch({children, propIn = true, propMode = "out-in" }) {
  const [mode] = React.useState(propMode);
  const [state, setState] = React.useState(propIn);
  const helloRef = React.useRef(null);
  const goodbyeRef = React.useRef(null);
  const nodeRef = state ? helloRef : goodbyeRef;

  React.useEffect( () => {
    setState(propIn);
    // console.log( propIn );
  },  [propIn]);

  // React.useEffect( () => {
  //   console.log( nodeRef );
  // },  [nodeRef]);

  return (<>
    <SwitchTransition mode={mode}>
      <CSSTransition
        key={state}
        nodeRef={nodeRef}
        addEndListener={(done) => {
          nodeRef.current.addEventListener("transitionend", done, false);
        }}
        classNames="fade"
      >
        <div ref={nodeRef} className="button-container">
          {children}
        </div>
      </CSSTransition>
    </SwitchTransition>
  </>)
}

export default MyTransitionSwitch