/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { QctPageLayout } from '../../components/layout';
import { TbCheck, TbSquareRoundedX } from "react-icons/tb";
import { Button, Col, Drawer, Flex, Input, Modal, Row, Typography, message } from 'antd';
import dayjs from "dayjs";
import PurchaseOrderManageForm from './MyManageForm';
import PurchaseOrderManageView from './MyManageView';

import PurchaseOrderService from "../../service/PurchaseOrder.service";
import { TbSearchProvider } from '../../store/context/table-search.context';
import { formData } from "./model";

const formName = "sup-form";
const apirequest = PurchaseOrderService();
function Manage() {
  const navigate = useNavigate();
  const location = useLocation();

  const { config:{ mode, acname, code } } = location.state || { config: null };

  const [formValue, setFormValue] = useState(formData);

  const [openComment, setOpenComment] = React.useState(false);
  const [comment, setComment] = React.useState(null);
  const handleSubmit = async (value) =>{ 
    try {  
      // console.log( value );
      // const action = mode === "create" ? apirequest.create(value) : apirequest.update(value);
      await apirequest[mode]({...value, id:code});
      navigate(-1);
      message.success("Request create done.");
    } catch ( er ){
      console.log( er ); 
      message.error("Error request.")
    }
  }

  const handleCancel = async () => {
     
      Modal.confirm({
        title: "ยืนยันการยกเลิกใบสั่งซื้อ", 
        content: `ต้องการยกเลิกใบสั่งซื้อ ใช่หรือไม่`,
        okText: 'ยืนยัน',
        okType: 'danger',
        cancelText: 'ยกเลิก',
        async onOk(){  
          try { 
            await apirequest.deleted(comment, code);
            navigate(-1);
            message.success("ยกเลิกใบสั่งสินค้าเรียบร้อย");
          } catch ( er ){
            console.log( er ); 
            const { data:{ option } } = er.response;
            message.error(`Error request. ${option}`);
          }    
        },
        // onCancel() { },
      });

    
  }

  useEffect( () => {
    const initeil = async () =>{
      try {
        if( mode !== "create" && !!code){
          const res = await apirequest.get(code);
          const { data:{ head, list } } = res.data; 

          head.purdate = !!head.purdate ? dayjs(head?.purdate) : null;
          head.duedate = !!head.duedate ? dayjs(head?.duedate) : null;

          setFormValue({ head, list}); 
        } 
      } catch ( er ){
        console.log( er );
        message.error("error request.")
      }
    }
    initeil();
  }, [mode, code]);

  const acButton = (<>
  { mode !== "view" 
    ?<Button 
      icon={<TbCheck style={{fontSize:'1rem'}}/>} 
      className='bn-center bn-primary'
      form={formName}
      htmlType='submit'   
    >ยืนยัน/บันทึก</Button>
    :<Button 
      icon={<TbSquareRoundedX style={{fontSize:'1.4rem'}}/>} 
      className='bn-center bn-secondary-outline'
      onClick={() => setOpenComment(true)}
      disabled={!['รออนุมัติ','รอรับสินค้า'].includes(formValue.head?.status)}
    >ยกเลิกใบสั่งสินค้า</Button> 
  }
  </>); 

  const commentFooter = (<>
    <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
        <Col span={12} className='p-0'>
            <Flex gap={4} justify='start'>
              <Button  
                className='bn-center bn-secondary-outline'
                onClick={() => setOpenComment(false)}
              >ปิด</Button>
            </Flex>
        </Col>
        <Col span={12} className='p-0'>
            <Flex gap={4} justify='end'>
                <Button className={`bn-center bn-secondary-outline`} onClick={()=>handleCancel()} >ยืนยันการยกเลิก</Button>
            </Flex>
        </Col>
    </Row>
  </>);

  return (<>
    <QctPageLayout title={`ใบสั่งซื้อสินค้า - ${acname}`} back={true} footerRight={acButton} hearderRight={acButton} >
    {mode === "view"
      ? <PurchaseOrderManageView initeial={formValue} />
      : (<TbSearchProvider>
          <PurchaseOrderManageForm  formName={formName} submit={handleSubmit} initeial={formValue} />
        </TbSearchProvider>)
    }
 
    </QctPageLayout> 
    <Drawer
      title={`เหตุผลในการยกเลิกใบรับสินค้า`}
      closable={false}
      onClose={()=>setOpenComment(false)}
      open={openComment}
      key="comment"
      footer={commentFooter}
      styles={{ 
        header:{
          borderBottom: `2px solid var(--secondary)`
        },
        content:{
          border: `2px solid var(--secondary)`
        },
        footer:{
          borderTop: `2px solid var(--secondary)`
        } 
      }}
    >
      <Typography.Text  >ความคิดเห็น</Typography.Text>
      <Input.TextArea rows={5} onChange={(e) => setComment(e.target.value)} ></Input.TextArea>
    </Drawer> 
  </>)
}

export default Manage