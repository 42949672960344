import { Button } from 'antd'
import React from 'react'
import MyModal from './MyModal'
import { TbCheck } from 'react-icons/tb';
import dayjs from "dayjs"; 

export default function MyModalButton({value, formSet, disabled=false, source=[], selected=[]}) {
    const [show, setShow] = React.useState( false );
    const [head, setHead] = React.useState( {} );
    
    // const [list, setList] = React.useState( [] );
    const handleOpen = async () => {
        try{
            const f = await formSet?.validateFields();
            const payment_duedate = !!f.payment_duedate ? dayjs(f.payment_duedate).format("YYYY-MM-DD") : null;
            const bndate = dayjs(f.bndate).format("YYYY-MM-DD")
            setHead( h => ({...h, ...f, payment_duedate, bndate }))
            setShow(true); 
        } catch( e ){

        }
    }
    
    const hancleConfirmCancel = async (v) => { 

        if( typeof value === 'function'){
            value( {head, list: v} );
        }
    }
    return (
    <> 
        <Button 
            icon={<TbCheck style={{fontSize:'1.4rem'}}/>} 
            className='bn-center bn-primary'
            disabled={disabled} 
            onClick={() => handleOpen()}
        >ยืนยัน/สร้างใบวางบิล</Button>
        { show && <MyModal show={show} close={()=>setShow(false)} values={hancleConfirmCancel} source={source} selected={selected} /> }
    </>
    )
}
