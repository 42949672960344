/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Divider, Form, Input, Row, Space, message } from "antd";
import React, { useEffect } from "react";
import { CreateInput } from "thai-address-autocomplete-react";
import { FormTagValue } from "../../components/form";

import OptionService from "../../service/Options.service.js";

const optrequest = OptionService();
export default function ManageForm({
  formName = "form-name",
  submit,
  initeial,
}) {
  const [form] = Form.useForm();
  const InputAddress = CreateInput({ maxSearchResult: 50 });
  const formRole = { required: true, message: "กรุณากรอกข้อมูลให้ครบถ้วน!" };

  const [optionsTag, setOptionsTag] = React.useState([]);

  const [tagValue, setTagValue] = React.useState([]);

  const dividerProp = {
    orientation: "left",
    style: { marginBlock: 10 },
    className: "!border-black",
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const onFinish = (values) => {
    submit({ ...values, tags: tagValue });
  };

  useEffect(() => {
    const { tag } = initeial;
    form.setFieldsValue(initeial);

    setTagValue(tag.map((tg) => tg.tag));
    return () => {};
  }, [initeial]);

  useEffect(() => {
    const initeil = async () => {
      try {
        const [tagRes] = await Promise.all([
          optrequest.optionsSupplierTag({ p: "tag-type" }),
        ]);

        const { data: tags } = tagRes.data;
        const opn_tags = tags.map((v) => ({ value: v.text }));
        setOptionsTag(opn_tags);
      } catch (err) {
        console.log(err);
        const {
          data: { message: mes, option },
        } = err.response;

        message.error(`error: ${mes || option}`);
      }
    };

    initeil();
    return () => {};
  }, []);

  const handleAddress = (val) => {
    const form_val = form.getFieldsValue();
    form.setFieldsValue({ ...form_val, ...val });
  };

  const info = (
    <>
      <Divider {...dividerProp}>ข้อมูลผู้ขาย</Divider>
      <Row className="!mx-0" gutter={[8, 8]}>
        <Col xs={24} sm={24} lg={8}>
          <Form.Item label="ชื่อผู้ขาย" name="supname" rules={[formRole]}>
            <Input placeholder="กรอกชื่อผู้ขาย" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={8}>
          <Form.Item label="เลขที่ภาษี" name="taxnumber">
            <Input placeholder="กรอกเลขที่ภาษี" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={8}>
          <Form.Item label="แท็ก/ประเภทการขาย" name="tags">
            <FormTagValue
              source={tagValue}
              choose={(v) => setTagValue(v)}
              options={optionsTag}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  const address = (
    <>
      <Divider {...dividerProp}>ข้อมูลที่อยู่</Divider>
      <Row className="!mx-0" gutter={[8, 8]}>
        <Col xs={24} sm={12} lg={8}>
          <Form.Item label="ที่อยู่" name="address">
            <Input placeholder="กรอกที่อยู่" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={4}>
          <Form.Item label="รหัสไปรษณีย์" name="zipcode">
            <InputAddress.Zipcode
              style={{ height: 40 }}
              autoCompleteProps={{ placeholder: "กรอกรหัสไปรษณีย์" }}
              onSelect={handleAddress}
              id="supplier-zipcode"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={4}>
          <Form.Item label="ตำบล" name="district">
            <InputAddress.District
              style={{ height: 40 }}
              autoCompleteProps={{ placeholder: "กรอกตำบล" }}
              onSelect={handleAddress}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={4}>
          <Form.Item label="อำเภอ" name="amphoe">
            <InputAddress.Amphoe
              style={{ height: 40 }}
              autoCompleteProps={{ placeholder: "กรอกอำเภอ" }}
              onSelect={handleAddress}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={4}>
          <Form.Item label="จังหวัด" name="province">
            <InputAddress.Province
              style={{ height: 40 }}
              autoCompleteProps={{ placeholder: "กรอกจังหวัด" }}
              onSelect={handleAddress}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="!mx-0" gutter={[8, 8]}>
        <Col xs={24} sm={12} lg={8}>
          <Form.Item label="เบอร์โทร" name="tel">
            <Input placeholder="กรอกเบอร์โทร" />
          </Form.Item>
        </Col>        
        <Col xs={24} sm={12} lg={8}>
          <Form.Item label="โทรสาร" name="fax">
            <Input placeholder="กรอกโทรสาร" />
          </Form.Item>
        </Col> 
        <Col xs={24} sm={12} lg={8}>
          <Form.Item label="อีเมล" name="email">
            <Input placeholder="กรอกอีเมล" />
          </Form.Item>
        </Col> 
      </Row>
    </>
  );

  const contact = (
    <>
      <Divider {...dividerProp}>ข้อมูลติดต่อ</Divider>
      <Row className="!mx-0" gutter={[8, 8]}>
        <Col xs={24} sm={12} lg={8}>
          <Form.Item label="ชื่อผู้ติดต่อ" name="contact_name">
            <Input placeholder="กรอกชื่อผู้ขาย" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <Form.Item label="เบอร์โทรผู้ติดต่อ" name="contact_tel">
            <Input placeholder="กรอกเบอร์โทรผู้ติดต่อ" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <Form.Item label="อีเมลผู้ติดต่อ" name="contact_email">
            <Input placeholder="กรอกอีเมลผู้ติดต่อ" />
          </Form.Item>
        </Col> 
      </Row>
    </>
  );

  const other = (
    <>
      <Divider {...dividerProp}>เพิ่มเติม</Divider>
      <Row className="!mx-0" gutter={[8, 8]}>
        <Col xs={24} sm={12} lg={12}>
          <Form.Item label="หมายเหตุ" name="remark">
            <Input.TextArea placeholder="กรอกหมายเหตุ" rows={3} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  return (
    <Space direction="vertical" className="w-full">
      <Form
        form={form}
        layout="vertical"
        name={formName}
        autoComplete="off"
        className="w-full"
        // onValuesChange={(_, value)=> setFormValue(value)}
        onKeyDown={handleKeyDown}
        onFinish={onFinish}
      >
        {info}
        {address}
        {contact}
        {other}
      </Form>
    </Space>
  );
}
