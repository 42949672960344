/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom';
// import ReactDOMServer from "react-dom/server";
import { useReactToPrint } from 'react-to-print';
import thaiBahtText from 'thai-baht-text';
import "./MyPrint.css";
// import logo from "../../../assets/images/logo_qct.png";
 
import { Button, Flex,  Table, Typography, message } from 'antd';
import { column } from './model';

import dayjs from "dayjs";
import { comma, dateToThaiFormat } from "../../../utils/util";
import { PiPrinterFill } from 'react-icons/pi';
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from 'antd';
// import RepairOrderService from "../../../service/RepairOrder.service";
import TaxInvoiceService from '../../../service/TaxInvoice.service';
// import jsPDF from 'jspdf';

// const apiservice = RepairOrderService();
const taxservice = TaxInvoiceService();
const sytlePage =`@media print { @page { size: 230mm 280mm; } } `;
function MyPrintPreview() {
    const { code, print } = useParams();
    const componentRef = useRef(null);
    const printRef = useRef(null);
    const handlePrint = useReactToPrint({
      documentTitle: "Print This Document",
      onBeforePrint: () => handleBeforePrint(),
      onAfterPrint: () => handleAfterPrint(),
      removeAfterPrint: true,
    });



    const [hData, setHData] = useState({});
    const [details, setDetails] = useState([]); 

    // const [packingCostSource, setPackingCostSource] = useState([]); 
    
    const [newPageContent, setNewPageContent] = useState([]);
    const columnDesc = column; 

    const [loading, setLoading] = useState(false);

    const handleAfterPrint = () => { 
        setNewPageContent([]);

        if( !!Number(print) ) window.close();
    }

    const handleBeforePrint = (e) => {
        console.log("before printing...")
        // const newElement = document.createElement('div');
        // newElement.id = 'new-container'; // Optional: Set an ID for the new container
        // newElement.innerHTML = 'TEST';
        // Render the new component into the new container 
    
        // Replace the old container with the new container
        // componentRef.current.innerHTML = 'TEST'; 
    }

    const handleCheckMultiPages = async () => {
        const limitPage = 530; 
        return new Promise( r =>{
            // const head = document.querySelector("#raw .in-head");
            const data = document.querySelector("#raw .in-data");
            const table = document.querySelector("#raw .in-data #tb-data");
            // const thead = table?.querySelector("thead");
            const mtbody = table?.querySelector("tbody");
            // const mtfoot = table?.querySelector("tfoot");
            const row = mtbody?.querySelectorAll("tr");

            const samplesPage =  [];
            // console.log(head, table, tbody, row); 
            // console.log(componentRef.current);
            // let pageCount = 1;
            let hPageCheck = 0;
            let emlContent = [];
            for( let elm of row ){
                const h = Number(window.getComputedStyle(elm).getPropertyValue('height')?.replace("px", ""));
                if( (hPageCheck + h) > limitPage ){
                    // console.log( { hPageCheck } ); 
                    samplesPage.push([...emlContent, elm]); 
                    emlContent = [];
                    hPageCheck = 0;
                } else { 
                    hPageCheck += h;
                    emlContent = [...emlContent, elm];
                }

                // console.log( h, hPageCheck );
            }
            if(emlContent.length > 0 ) samplesPage.push(emlContent);  

            // const hfoot = Number(window.getComputedStyle(mtfoot).getPropertyValue('height')?.replace("px", ""));
            const pages = []; 
            for( let rind in samplesPage ) {  
                // const chead = head.cloneNode(true);
                const cdata = data.cloneNode(true);
                const table = cdata.querySelector("#tb-data");
                // const thead = table?.querySelector("thead");
                const tbody = table?.querySelector("tbody");
                // const tfoot = table?.querySelector("tfoot");
 
                tbody.style.height = `${limitPage}px`; 

                tbody.innerHTML = `${ (samplesPage[rind].map( m => m.outerHTML )).join("") }`;

                // if( rind < (samplesPage.length - 1)) tfoot.remove();
                const temp = document.createElement('div'); 

                // temp.appendChild( chead );
                temp.appendChild( cdata );
                temp.classList.add("on-page")
                pages.push(temp);
            }
            // console.log( pages );
            setNewPageContent( state => [ ...state, ...pages]);
            r(pages);
        }) 
    }  
    const handlePrintMultiPages = async () => {
        setLoading(true);
        handleCheckMultiPages().then( res => { 
            handlePrint(null, () => printRef.current ); 
            setLoading(false); 
        });
    }

    useEffect( () =>  {
        const init = async () => {
            const res = await taxservice.get( code ).catch( err => {
              console.log(err);
              message.error("Error getting infomation for print.")
            });
            const { data : { head, taxs } } = res.data; 

            const { price_total } = head;
            const vat = Number( price_total || 0 ) * 0.07;
            const grand_total = Number( price_total || 0 ) + vat;
            const newHead = {...head, vat, grand_total };

            setHData( {...newHead} );
            
            setDetails( [...taxs] );
        }

        init();
        return () => {}
    }, []);

    useEffect( () => {
        if( !!Number(print) ) setTimeout( () => handlePrintMultiPages(), 480 );
        return () => {}
    }, [details])

    const HeaderForm = ({...resProps}) => {
        return (<>
            <div className='print-head' style={{height:75, paddingRight:14, fontWeight: 700}}> 
                <div className='print-title flex gap-5'> 
                    <div className='grow !pb-2'>
                        {/* <img src={logo} alt="" style={{paddingInline: 6, height: '100%'}}  />   */}
                    </div>
                    <div className='flex grow-0 justify-end items-center' style={{width: 278}}>
                        <Flex className='mb-0 '>
                            <Typography.Title level={3} align='end' className='m-0 min-w-28 text-end'>{'\u00A0'}</Typography.Title> 
                        </Flex> 
                    </div>
                </div> 
            </div>
            <div className='print-head items-end' style={{height:104, paddingInline: '4.2rem 5.4rem', paddingBottom: '.2rem', fontWeight: 700}}> 
                <div className='print-title flex gap-8 items-end'> 
                    <div className='flex grow flex-wrap !ps-14' style={{maxWidth: 470}}>
                        <Flex className='mb-0 '>
                            <Typography.Text className='tx-info m-0 min-w-28 text-start'>{hData?.cusname}</Typography.Text> 
                        </Flex> 
                    </div>
                    <div className='flex grow-0 justify-center items-end' style={{width: 200}}>
                        <Flex className='mb-0 items-end'>
                            <Typography.Text className='tx-info m-0 min-w-28 text-center'>{dateToThaiFormat( dayjs().format("YYYY-MM-DD") )}</Typography.Text> 
                        </Flex> 
                    </div> 
                </div> 
            </div>
            <div className='print-head items-start' style={{height:70, paddingInline: '4.2rem 5.4rem', paddingBottom: '.2rem', fontWeight: 700}}> 
                <div className='print-title flex gap-8 items-end'> 
                    <div className='flex grow flex-wrap !ps-10'>
                        <Flex className='mb-0' gap={3}>
                            <Typography.Text className='tx-info m-0 min-w-28 text-start' style={{lineHeight: '30px', textWrap: 'balance'}}>
                                { hData?.address }
                                { hData?.contact_name  && ` ติดต่อ ${hData?.contact_name}`}
                                { hData?.contact_tel   && ` โทร. ${hData?.contact_tel}`}
                                { hData?.contact_email && ` อีเมล ${hData?.contact_email}`}
                                { hData?.taxno && ` เลขประจำตัวผู้เสียภาษี. ${hData?.taxno}`} 
                            </Typography.Text>
                            {/* <Typography.Text className='tx-info m-0 min-w-28 text-start' style={{lineHeight: '30px', minWidth: 200}}>
                            </Typography.Text> */}
                        </Flex> 
                    </div>
                </div> 
            </div>
            {/* <ContentHead {...resProps}/>   */}
        </>)
    }

    const FooterForm = ({page}) => {
        return (
            <div className='print-head items-start' style={{height:98, paddingInline: '4.2rem 5.4rem', paddingBottom: '.2rem', fontWeight: 700}}>
                <div className='print-title h-full flex gap-4 items-start'> 
                    <div className='flex grow h-full flex-wrap justify-center items-end' style={{maxWidth: 400}}>
                        <Flex className='mb-0 justify-center items-end'> 
                            <Typography.Text className='tx-info m-0 min-w-28 text-center'>({thaiBahtText(hData?.grand_total)})</Typography.Text> 
                        </Flex> 
                    </div> 
                    <div className='flex grow-0 h-full justify-end items-start' style={{width: 244}}>
                        <Flex className='mb-0 items-start gap-4' vertical>
                            <Typography.Text className='tx-info m-0 min-w-12 text-center'>{'\u00A0'}</Typography.Text>
                            <Typography.Text className='tx-info m-0 min-w-12 text-center'>7%</Typography.Text>
                            <Typography.Text className='tx-info m-0 min-w-12 text-center'>{'\u00A0'}</Typography.Text>
                        </Flex> 
                        <Flex className='mb-0 items-start gap-4' vertical>
                            <Typography.Text className='tx-info m-0 min-w-20 text-end'>{comma( Number(hData?.price_total | 0), 2, 2 )}</Typography.Text> 
                            <Typography.Text className='tx-info m-0 min-w-20 text-end'>{comma( Number(hData?.vat | 0), 2, 2 )}</Typography.Text> 
                            <Typography.Text className='tx-info m-0 min-w-20 text-end'>{comma( Number(hData?.grand_total || 0), 2, 2 )}</Typography.Text> 
                        </Flex> 
                    </div> 
                </div>  
            </div>   
        )
    }

    const ContentBody = () => {
        return ( 
        <div className='content-body in-data flex flex-col' style={{paddingInline: '4.5rem 5.3rem', paddingBlock: '2rem .5rem', minHeight: 600}} > 
            <Table 
                id="tb-data"
                size='small'
                dataSource={details}
                columns={columnDesc()}
                pagination={false}
                rowHoverable={false}
                rowKey="id"
                bordered={false}
                showHeader={false}
            />
        </div>
        )
    } 

    const Pages = () => (
        <div ref={componentRef} id="raw"> 
            <ContentData> 
                <style>{sytlePage}</style>
                {/* <ContentHead /> */}
                <ContentBody />
            </ContentData>
        </div>    
    )

    const ContentData = ({ children, pageNum = 1, total = 1 }) => {
        return ( 
            <div className='receipt-pages flex flex-col'>
                <HeaderForm page={`${pageNum}/${total}`}/>
                <div className='print-content grow'> 
                    {children}
                    <FooterForm />
                </div>
            </div>
        )
    }

    return (
        <> 
            <div className='page-show' id="receipt-print"> 
                { loading && <Spin fullscreen indicator={<LoadingOutlined  />} /> }
                <div className="title-preview flex gap-4"> 
                    { !Number(print) && <Button
                        className='bn-center  !bg-blue-400' 
                        // onClick={() => { handlePrintMultiPages() }}
                        onClick={() => { handlePrint(null, () => componentRef.current ); }}
                        icon={<PiPrinterFill style={{fontSize:'1.1rem'}} />}
                    >
                        PRINT
                    </Button>
                    } 
                </div>         
                <div className="layout-preview">
                    <Pages />
                </div>
                <div className='hidden' id="mypage">
                    <div ref={printRef} >
                        {newPageContent?.map( (page, i) => {
                        // console.log( page.innerHTML );
                        return ( 
                            <div key={i} className='on-page'>
                                <ContentData pageNum={i+1} total={(newPageContent?.length)} > 
                                    <div dangerouslySetInnerHTML={{ __html: page.innerHTML }}></div>
                                </ContentData>
                                {i < (newPageContent.length-1) && <div className='page-break'></div>}
                            </div>)
                        })}
                    </div>
                </div>
            </div>        
        </>
    )
}

export default MyPrintPreview