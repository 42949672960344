import { Badge, Flex, Tag } from "antd";
import { TbTagStarred } from "react-icons/tb";

export const myModalColumn = () => [
    {
        title: "รหัสผู้ขาย",
        dataIndex: "supcode",
        key: "supcode",
        width: 100, 
        align: "left",
        sorter: true, 
    },
    {
        title: "ชื่อผู้ขาย",
        dataIndex: "supname",
        key: "supname", 
        align: "left",
        sorter: true, 
        style: { minWidth: 120 }
    },
    {
        title: "ติดต่อ",
        dataIndex: "contact_name",
        key: "contact_name", 
        width: "18%",
        align: "left",
        sorter: true,
    },
    {
        title: "จำนวนสั่งซื้อ",
        dataIndex: "pocount",
        key: "pocount", 
        onCell: (rec) => ( { className: '!pe-6' } ),
        width: 100,
        align: "right",
        sorter: true,
        render:(v) => <Badge
            className="site-badge-count-109"
            count={Number( v || 0)}
            style={{ backgroundColor: '#52c41a' }}
        />
    },
    {
        title: "แท็ก",
        dataIndex: "tag",
        key: "tag",
        width: 180, 
        align: "left",
        sorter: true,
        render: (data) => {
            const tags = JSON.parse(data)?.map((str, i) =>
                <Tag icon={<TbTagStarred />} color="var(--primary)" key={i} className="m-0 flex flex-nowrap items-center gap-1">
                    {str}
                </Tag>
            ); 
            return <Flex wrap="wrap" gap={2}>{tags}</Flex>
        },
    },
];