import React from 'react';
import { Tag } from "antd";
import { comma } from '../../../utils/util';


function TagStockStatus({result}) {
    
  if( result === null || result === undefined){
    return (<><Tag icon={false} ><>ไม่มีสต๊อก</></Tag></>)
  }
  else if( !!Number(result) && Number(result) > 0 ){
    return (<>
        <Tag icon={false} color='green'><>{ comma( Number(result) )}</></Tag>
    </>) 
  }
  else {
    return (<> <Tag icon={false}  color='red' ><>สต๊อกหมด</></Tag> </>)    
  }  
}

export default TagStockStatus