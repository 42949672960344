import React from 'react'
import { Card, Form, Typography, Flex, Divider, Row, Col,  Drawer, Input, InputNumber } from "antd"; 
import { TbPencil } from 'react-icons/tb';
   
import { SelectCarBrand, SelectCarModel, SelectProvince } from '../../select/index.js';

const dividerProp = {orientation:"left", style:{marginBlock:10}, className:'!border-black'}; 
export default function MyFormManage({form, show, close, title="จัดการข้อมูล"}) {  
    const [openDrawer, setOpenDrawer] = React.useState(show); 
    const headStyle = {
        borderBottom: `2px solid var(--secondary)`,
        backgroundColor: '#d3d3d3',
    }

    const ThisTitle = (<>
        <Flex align='center' gap={4}>
            <TbPencil  style={{fontSize: '1rem'}} />
            <Typography.Text className='ms-1 mb-0'>{title}</Typography.Text>
        </Flex>    
    </>); 

    const information = (<>
        <Divider {...dividerProp}>ข้อมูลรถยนต์</Divider>
        <Card style={{backgroundColor:'#f0f0f0' }}>
            <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
                <Col xs={24} sm={24} lg={8}>
                    <Form.Item label="ทะเบียนรถ" name='car_no'  rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' }]}  >
                        <Input placeholder='กรอกทะเบียนรถ'/>
                    </Form.Item>  
                </Col>
                <Col xs={24} sm={24} lg={16}>
                    <Form.Item label="จังหวัด" name='car_province'  rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' }]}>
                        <SelectProvince />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={8}>
                    <Form.Item label="ยี่ห้อรถยนต์" name='car_brand' rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' }]}>
                        <SelectCarBrand />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={8}>
                    <Form.Item label="รุ่นรถยนต์" name='car_model' rules={[{ required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' }]}>
                        <SelectCarModel />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={8}>
                    <Form.Item label="ปีรถยนต์" name='car_year' >
                        <InputNumber 
                        name="car_year" 
                        placeholder='กรอกปีรถยนต์' 
                        className='w-full input-40 text-end' 
                        style={{height: 40}} 
                        controls={false} 
                        min={1800}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={8}>
                    <Form.Item label="สีรถยนต์" name='car_color' >
                        <Input name="car_color" placeholder='กรอกสีรถยนต์' />
                    </Form.Item>
                </Col>
            </Row>            
        </Card> 
    </>); 
  
    return (
        <>
            <Drawer
                title={ThisTitle}
                onClose={() => { setOpenDrawer(false); }}
                open={openDrawer} 
                width='100%'
                className="responsive-drawer" 
                afterOpenChange={(e)=>{ !e && close(false); }}
                getContainer={false}
                styles={{ header: headStyle }}
            > 
                <Form form={form} layout="vertical" autoComplete="off" >
                    {information}
                </Form> 
            </Drawer> 
        </>
    )
}

