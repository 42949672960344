/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Form, Input, Space } from "antd";
import { QctPageLayout } from "../../components/layout";
import { FormSearchValue, TableSearchValue } from "../../components/form";
// import { Typography } from 'antd';
import CarService from "../../service/Car.service";

import { accessColumn } from "./model";
import useSearch from "../../store/hook/use-search.hook";

const carService = CarService();

function CarAccess() {
  const navigate = useNavigate();

  const stSearch = useSearch("car-access");
  const { criteria: cria, tbparams: parm } = stSearch.gettingSearch();
  const tbparamsDefault = {
    ...parm,
    pagination: { ...{ current: 1, pageSize: 10 }, ...parm?.pagination },
  };
  const criteriaDefault = cria || {};
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);

  const [criteria, setCriteria] = useState({ ...criteriaDefault });

  const [tbparams, setTbparams] = useState({ ...tbparamsDefault });
  const [pageValue, setPageValue] = useState({ ...tbparamsDefault.pagination });
  const [selected, setSeleted] = useState(undefined);
  const [viewOnly, setViewOnly] = useState(false);

  const formSearch = (
    <FormSearchValue
      title="ค้นหา"
      onValues={(value) => handleCriteria(value)}
      defaultValue={criteriaDefault}
    >
      <Col xs={24} sm={12} md={12} lg={6} xl={6}>
        <Form.Item label="ทะเบียนรถ" name="carno">
          <Input placeholder="กรอกทะเบียนรถ" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={12} lg={6} xl={6}>
        <Form.Item label="จังหวัด" name="province">
          <Input placeholder="กรอกจังหวัด" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={12} lg={6} xl={6}>
        <Form.Item label="ยี่ห้อ" name="brand">
          <Input placeholder="กรอกยี่ห้อรถ" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={12} lg={6} xl={6}>
        <Form.Item label="รุ่นรถ" name="car_model">
          <Input placeholder="กรอกรุ่นรถ" />
        </Form.Item>
      </Col>
    </FormSearchValue>
  );

  const handleCriteria = (criteria) => {
    setCriteria(criteria);
    setTbparams(page => ({ 
      ...page, 
      pagination: {...page?.pagination, current: 1},
      current: 1
    }));
  };

  const handlePaging = (page) => {
    setTbparams(page);
    // setTbparams(page.pagination);
  };

  const handleSearch = () => {
    const parm = { criteria, tbparams };
    
    carService.search(parm, { ignoreLoading: Object.keys(parm.criteria).length !== 0 }).then((res) => {
      const {
        data: { source, tbparams },
      } = res.data;

      stSearch.updateSearch(parm);
      setPageValue(tbparams?.pagination);
      // setPaing( state => ( {...state, ...pagination }) );
      setDataSource(source);

      const { order, field } = tbparams;
      setTimeout(() => {
        setColumns((state) =>
          !!field
            ? state.map((col) => {
                if (col.key === field) col["sortOrder"] = order;
                else delete col["sortOrder"];
                return col;
              })
            : state
        );
      }, 80);
    });
  };

  const handleRowSelect = (record, key) => {  
    setViewOnly( !!key );
    // setSubmitStock( !!Number(record?.stock_submit || 0 ) );
    setSeleted( !!key ? record : undefined );
  }

  const handleReviewData = (code, record) => { 
    const config = { mode:"view", acname: `ทะเบียน ${record?.carno}`, record, code };
    navigate("view", {state:{config}});
  }

  useEffect(() => {
    handleSearch();

    // setMounted(true);
  }, [JSON.stringify(criteria), JSON.stringify(tbparams)]);

  useEffect(() => {
    const init = () => {
      const { order, field } = tbparamsDefault;
      const cols = accessColumn();
      setColumns(
        !!field
          ? accessColumn().map((col) => {
              if (col.key === field) col["sortOrder"] = order;
              else delete col["sortOrder"];
              return col;
            })
          : cols
      );
      // console.log(  order, field  );
    };

    init();

    return () => {};
  }, []);

  return (
    <>
      <QctPageLayout title={"ข้อมูลรถ"}>
        <Space direction="vertical" className="flex gap-4">
          {formSearch}
          <TableSearchValue
            title="รายการข้อมูลรถ"
            pagingDefault={tbparamsDefault?.pagination}
            onPageChange={handlePaging}
            onSelectedRow={handleRowSelect}
            onReView={ viewOnly && !!selected && handleReviewData}
            tbProps={{
              rowKey: "carno",
              dataSource: dataSource,
              columns: columns,
              pagination: pageValue,
            }}
          />
        </Space>
      </QctPageLayout>
    </>
  );
}

export default CarAccess;
