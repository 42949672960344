import React from 'react'
import { Tag } from "antd"
import { MinusCircleOutlined } from '@ant-design/icons';

function TagStatus({data:v}) {
  if( v === 'รออนุมัติ'){
    return <Tag  color="var(--primary)">รออนุมัติ</Tag> 
  }
  else if( v === 'รอรับสินค้า' ){
    return <Tag color="var(--secondary)">รอรับสินค้า</Tag>
  }
  else if( v === 'รับสินค้าไม่ครบ' ) {
    return <Tag color="var(--warning)">รับสินค้าไม่ครบ</Tag> 
  }
  else if( v === 'เสร็จสิ้น' ) {
    return <Tag color="var(--success)">เสร็จสิ้น</Tag> 
  }
  else if( v === 'ยกเลิก' ) {
    return <Tag color="var(--danger)">ยกลิก</Tag> 
  }
  else if( v === 'ไม่อนุมัติ' ) {
    return <Tag color="var(--danger)">ไม่อนุมัติ</Tag> 
  }
  else return <Tag icon={<MinusCircleOutlined />} color="default">ไม่พบสถานะ</Tag>
} 

export default TagStatus