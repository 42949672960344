import React from 'react'
import { Route } from 'react-router-dom'

import {PurchaseOrder, PurchaseOrderAccess, PurchaseOrderManage} from '../pages/purchase-order'; 
import {GoodsReceipt, GoodsReceiptAccess, GoodsReceiptManage} from '../pages/goods-receipt'; 


export const WarehouseRouter = (<>
  <Route path="/purchase-orders/" exact element={<PurchaseOrder />}>
      <Route index element={<PurchaseOrderAccess />} />
      <Route path="manage" element={<PurchaseOrderManage />} />
      {/* <Route path="view" element={<PilotScaleView />} /> */}
  </Route>
  <Route path="/goods-receipt/" exact element={<GoodsReceipt />}>
      <Route index element={<GoodsReceiptAccess />} />
      <Route path="manage" element={<GoodsReceiptManage />} />
      {/* <Route path="view" element={<PilotScaleView />} /> */}
  </Route>
</>)