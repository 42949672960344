/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { QctPageLayout } from "../../components/layout";
import { TbCheck } from "react-icons/tb";
import { Button, message } from "antd";
import { SolutionOutlined, FileSearchOutlined } from "@ant-design/icons";
import ItemsManageForm from "./ItemsManageForm";
import ItemsCreate from "./ItemsCreate";
import FormItemtype from "../../components/form/itemtype/FormItemtype";
import StepPanel from "../../components/step/StepPanel";

import ItemsService from "../../service/Item.service";

import { formData, DEFALUT_CHECK_STEP_FORM } from "./model";

const formName = "sup-form";

const apirequest = ItemsService();
function ItemsManage() {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    config: { mode, acname, code },
  } = location.state || { config: null };

  const [formValue, setformValue] = useState(formData);
  const [checkStep, setCheckStep] = useState(DEFALUT_CHECK_STEP_FORM);

  const handleSubmit = async (value) => {
    // console.log(value)
    const ininteial_value = {
      ...formValue,
      ...value,
    };

    const actions =
      mode !== "create"
        ? apirequest.update(ininteial_value)
        : apirequest.create(ininteial_value);
    const restext =
      mode !== "create" ? "Request edit done" : "Request create done";
    await actions
      .then(async (r) => {
        message.success(restext);
        navigate(-1);
      })
      .catch((err) => {
        console.log(err);

        message.error("Error request.");
      });
  };

  useEffect(() => {
    const initeil = async () => {
      try {
        if (mode !== "create" && !!code) {
          const res = await apirequest.get(code);
          const { data } = res.data;
          setformValue(data);

          // console.log(data);
        }
      } catch (er) {
        console.log(er);

        message.error("error request.");
      }
    };

    initeil();
  }, [mode, code]);

  const handleNextStep = (values) => {
    // console.log(values);

    // console.log(checkStep);
    setCheckStep((prev) => ({ ...prev, ...values }));
  };

  const FormStepQuotation = () => {
    return (
      <>
        <br></br>
        <ItemsCreate
          formName={formName}
          submit={handleSubmit}
          initeial={formValue}
          mode={mode}
          checkStep={checkStep}
        />
      </>
    );
  };


  const frButton = (
    <>
      {mode === "edit" ? (<Button
        icon={<TbCheck style={{ fontSize: "1rem" }} />}
        className="bn-center bn-primary"
        form={formName}
        htmlType="submit"
      >
        ยืนยัน/บันทึก
      </Button>): (
          <></>
        )}
    </>
  );

  const steps = [
    {
      step: 1,
      title: "เลือกประเภทสินค้า",
      icon: <SolutionOutlined />,
      content: <FormItemtype onChoosetype={handleNextStep} />,
    },
    {
      step: 2,
      icon: <FileSearchOutlined />,
      title: "กรอกรายละเอียดสินค้า",
      content: <FormStepQuotation  />,
    },
  ];

  return (
    <>
      <QctPageLayout
        title={`ระบบสินค้า - ${acname}`}
        back={true}
        hearderRight={frButton}
        footerRight={frButton}
      >
        {mode === "edit" ? (
          <ItemsManageForm
            formName={formName}
            submit={handleSubmit}
            initeial={formValue}
            mode={mode}
          />
        ) : (
          <StepPanel dataStep={checkStep} steps={steps} formName={formName} submit={handleSubmit} />
        )}
      </QctPageLayout>
    </>
  );
}

export default ItemsManage;
