import React from 'react'
import {Tag} from "antd"
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

function TagApproveStatus({data:v}) {
  if( v === 'Y'){
    return <Tag icon={<CheckCircleOutlined />} color="success"> อนุมัติ </Tag> 
  }
  else if( v === 'J' ){
    return <Tag icon={<CloseCircleOutlined />} color="error"> ไม่อนุมัติ </Tag>
  }
  else if( v === 'N' ) {
    return <Tag icon={<ClockCircleOutlined spin />} color="warning"> รออนุมัติ </Tag> 
  }
  else return <Tag icon={<MinusCircleOutlined />} color="default">ไม่พบสถานะ</Tag>
} 

export default TagApproveStatus
