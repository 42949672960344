import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Modal } from "antd";
import { Layout } from "antd";
import { Menu, Typography } from "antd";
import { Authenticate } from "../../service/Authenticate.service";
import {
  // SettingOutlined,
  UserOutlined,
  // AuditOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import logo from "../../assets/images/logo_nsf.png";

import { menus } from "../../menu";
import { TbExclamationCircle } from "react-icons/tb";
const authService = Authenticate();
const Header = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null); 
  
  const [current, setCurrent] = useState(null);
  // const [items,  setItems] = useState([]);
  useEffect(() => {
    const users = authService.getUserInfo();
    setUserInfo(users);
    // setItems( item => [...menus ]); 
    return () => {};
  }, []); 
 
  const onLogout = () => {
    Modal.confirm({
      title: "ยืนยันการออกจากระบบ", 
      icon:<TbExclamationCircle style={{fontSize:'1.6rem'}} className='text-blue-700' />,
      content: 'คุณแน่ใจหรือไม่ว่าต้องการออกจากระบบ',
      okText: 'ยืนยัน',
      okType: 'primary',
      cancelText: 'ยกเลิก',
      onOk() {  
        authService.removeToken(); 
        navigate("/", { replace: true });
      },
      // onCancel() { },
    }); 
  }; 

  const onClick = (e) => {
    // console.log("click ", e);
    setCurrent(e.key);
  };
  
  return (
    <>
      <Layout.Header className="px-4 sm:px-20 lg:px-40 flex-col" style={{ backgroundColor: '#fff', display: 'flex', alignItems: 'center', borderBottom: '1px solid rgba(5, 5, 5, 0.06)' }} >
        <Flex justify="space-between" className="w-full" align="center">
          <div className="flex flex-grow items-center">
            <div className="demo-logo cursor-pointer"  >
              <img className="uploadfile.pb-15" style={{width: 63, height: 63}} src={logo} alt="logo 1" onClick={()=>navigate("/", { replace: true })} />
            </div>
            <Menu
              style={{ flex:1, minWidth: 0, border:'none' }}
              overflowedIndicator={<MenuOutlined />}
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={menus} 
            />
          </div>
          <div className="flex flex-grow-0 items-center justify-end">
            <Menu
              style={{  flex:1, minWidth: 200, border:'none', justifyContent: 'end' }}
              mode="horizontal"
              items={[{
                label: <Typography.Text>{userInfo?.firstname} {userInfo?.lastname}</Typography.Text>,
                key: "custom1",
                icon: <UserOutlined />,
                popupOffset:[0,0],
                children: [
                  {
                    label: (
                      <Typography.Link onClick={onLogout} rel="noopener noreferrer">
                        ออกจากระบบ
                      </Typography.Link>
                    ),
                    key: "custom1-1",
                  },
                ]}
              ]} 
            />
          </div> 
        </Flex> 
      </Layout.Header>
    </>
  );
};
export default Header;
