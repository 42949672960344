import { Badge } from "antd";

export const accessColumn = () => [  
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
    width: "20%",
    hide: false,
  },
  {
    title: "ชื่อ",
    dataIndex: "firstname",
    key: "firstname",
    width: "20%",
    hide: false,
  },
  {
    title: "นามสกุล",
    dataIndex: "lastname",
    key: "lastname",
    width: "20%",
    hide: false,
  },
  {
    title: "ประเภทผู้ใช้",
    dataIndex: "type",
    key: "type",
    width: "20%",
    hide: false,
  },
  {
    title: "สถานะการใช้งาน",
    dataIndex: "active_status",
    key: "active_status",
    width: "20%",
    hide: false,
    render: (data) => (
      <div>
        {data === "Y" ? (
          <Badge status="success" text="เปิดการใช้งาน" />
        ) : (
          <Badge status="error" text="ปิดการใช้การ" />
        )}
      </div>
    ),
  },
];

export const formData = {
  users_code: null,
  users_name: null,
  active_status: null,
};
