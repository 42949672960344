import { Badge } from "antd";

export const accessColumn = () => [  
  {
    title: "business_code",
    dataIndex: "businessno",
    key: "businessno",
    width: "20%",
    hidden: true,
  },
  {
    title: "คำนำหน้าชื่อ",
    dataIndex: "title_name",
    key: "title_name",
    width: "5%",
    hide: false,
  },
  {
    title: "ชื่อบริษัท",
    dataIndex: "business_name",
    key: "business_name",
    width: "30%",
    hide: false,
  },
  {
    title: "เบอร์โทร",
    dataIndex: "tel",
    key: "tel",
    width: "20%",
    hide: false,
  },
  {
    title: "สถานะการใช้งาน",
    dataIndex: "active_status",
    key: "active_status",
    width: "25%",
    hide: false,
    render: (data) => (
      <div>
        {data === "Y" ? (
          <Badge status="success" text="เปิดการใช้งาน" />
        ) : (
          <Badge status="error" text="ปิดการใช้การ" />
        )}
      </div>
    ),
  },
];

export const formData = {
  business_code: null,
  business_name: null,
  active_status: null,
};
