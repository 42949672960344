import { requestService as api } from "./Request.service"  
const API_URL = { 
  API_MANAGE: `/tax-invoice/manage.php`,
};

const TaxInvoiceService = () => { 
  
  const create = (parm = {}, config = {}) => api.post(`${API_URL.API_MANAGE}`, parm, config);
  const update = (parm = {}, config = {}) => api.put(`${API_URL.API_MANAGE}`, parm, config);
  const deleted = (code) => api.delete(`${API_URL.API_MANAGE}?code=${code}`);
  const get = (code) => api.get(`${API_URL.API_MANAGE}?code=${code}`);
 
  return {
    create,
    update,
    deleted,
    get,
  };
};

export default TaxInvoiceService;