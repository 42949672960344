/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Col, Form, Input, Select, Space, Tag } from 'antd';
import { QctPageLayout } from '../../components/layout'
import { FormSearchValue, TableSearchValue  } from "../../components/form";
// import { Typography } from 'antd';
import SupplierService from "../../service/Supplier.service";
import { accessColumn } from './model';
import useSearch from '../../store/hook/use-search.hook';
import OptionService from "../../service/Options.service.js"
import { TbTags } from 'react-icons/tb';


const optrequest = OptionService();
const apirequest = SupplierService();
function Access() {
  const navigate = useNavigate();

  const stSearch = useSearch("supplier-access");
  const { criteria:cria, tbparams:parm } = stSearch.gettingSearch();
  const tbparamsDefault = { ...parm, pagination:  {...{ current: 1, pageSize: 10 }, ...parm?.pagination} };
  const criteriaDefault = cria || { };
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);

  const [criteria, setCriteria] = useState({...criteriaDefault});

  const [tbparams, setTbparams] = useState({...tbparamsDefault});
  const [pageValue, setPageValue] = useState({...tbparamsDefault.pagination});

  const [optionsTag, setOptionsTag] = React.useState([]);


  const formSearch = (
    <FormSearchValue title='ค้นหา' onValues={(value) => handleCriteria(value)} defaultValue={criteriaDefault} >
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="รหัสผู้ขาย" name="supcode" >
          <Input placeholder="ใส่รหัสผู้ขาย" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="ชื่อผู้ขาย" name="supname" >
          <Input placeholder="ใส่ชื่อผู้ขาย" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="ชื่อผู้ติดต่อ" name="contact" >
          <Input placeholder="ใส่ชื่อผู้ติดต่อ" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}> 
        <Form.Item label='แท็ก' name='tag'>
            <Select 
                mode='multiple'
                tagRender={(props) => <Tag {...props} color='var(--primary)' icon={<TbTags />} className='flex gap-1 items-center' >{props.label}</Tag>}
                allowClear
                autoClearSearchValue={false}
                style={{ width: '100%', height: '40px' }}
                placeholder="เลือกแท็ก"
                maxTagCount= 'responsive'
                options={optionsTag.map((item) => ({
                    value: item.text,
                    label: item.text,
                }))}
                getPopupContainer={() => document.getElementById('area')}
            ></Select>
        </Form.Item>   
      </Col>
    </FormSearchValue>
  );

  const handleCriteria = (criteria) => {
    setCriteria(criteria);
    setTbparams(page => ({ 
      ...page, 
      pagination: {...page?.pagination, current: 1},
      current: 1
    }));
    // handleSearch(true);
  }

  const handlePaging = (page) => {  
    setTbparams(page); 
    // setTbparams(page.pagination); 
  }
  
  const handleSearch = () => {
    const parm = { criteria, tbparams };
    apirequest.search(parm, { ignoreLoading:Object.keys(parm.criteria).length !== 0 } ).then( res => {
      const { data:{ source, tbparams } } = res.data;
      parm.criteria.tag?.length === 0 && delete parm.criteria.tag; 
      stSearch.updateSearch( parm );
      setPageValue(tbparams?.pagination);
      // setPaing( state => ( {...state, ...pagination }) );
      setDataSource(source); 

      const { order, field } = tbparams;
      setTimeout( ()=>{
        setColumns( state => stSearch.columnOrder(state, order, field)); 
      }, 80);
    })
  }

  // const handleRowSelect = (record, key) => { }

  const handleCreatData = () => {
    const config = { mode:"create", acname: "สร้าง" };
    navigate("manage", {state:{config}});
  }
  
  const handleUpdateData = (code, record) => { 
    const config = { mode:"update", acname: `แก้ไข #${record?.supcode}`, record, code };
    navigate("manage", {state:{config}});
  }

  useEffect( () => { 
    console.log( criteriaDefault );
    handleSearch();

    // setMounted(true);
  }, [JSON.stringify(criteria), JSON.stringify(tbparams)]);

  React.useEffect(() => {
    const initeil = async() => {
      const [ tagRes ] = await Promise.all([
          optrequest.optionsSupplierTag({p:"tag"})
      ]);

      const {data:tags} = tagRes.data; 
      setOptionsTag( tags );
  }

  initeil();    

  }, [])
 
  useEffect( () => {
    const init = () => {
      const { order, field } = tbparamsDefault;
      const cols = accessColumn();
      const colOrder = stSearch.columnOrder(cols, order, field);
      setColumns(colOrder);
      // console.log(  order, field  );
    }

    init();
    
    return () => {};
  }, []);

  return (<>
  <QctPageLayout title={"ผู้ขาย"}>
    <Space direction='vertical' className='flex gap-4'>
      { formSearch }
      <TableSearchValue
        title='รายการข้อมูลผู้ขาย'
        pagingDefault={tbparamsDefault?.pagination}
        onPageChange={handlePaging}
        // onSelectedRow={handleRowSelect}
        onCreate={handleCreatData}
        onUpdate={handleUpdateData}
        // onDelete={handleDeleteData}
        tbProps={{
          rowKey:"id",
          dataSource:dataSource,
          columns:columns,
          pagination: pageValue
        }}
      />
    </Space>

  </QctPageLayout>
  </>)
}

export default Access