import { Select, Tag } from 'antd'
import React from 'react' 
import { TbBuildingFactory2 } from 'react-icons/tb';

export default function MySelect({name="mfyear", style={}, className="", onChange, value=null }) {
  const thisYear = ( new Date() ).getFullYear();
  const yearOptions = Array.from({ length: 7 },  () => thisYear ).map( (v, i) => ({ value: v - i, label: v - i }) );

  const handleChange = (value, option) => {
    if( typeof onChange === "function") {
      onChange(value, option);
    }
  }
    return (<>
      <Select 
          mode='multiple'
          allowClear
          autoClearSearchValue={false}
          tagRender={(props) => <Tag {...props} color='var(--primary)' icon={<TbBuildingFactory2 />} className='flex gap-1 items-center' style={{height: '1.84rem'}} >{props.label}</Tag>}
          style={{ width: '100%', height: '50px' }}
          placeholder="เลือกปีที่ผลิต"
          maxTagCount= 'responsive'
          options={yearOptions}
          suffixIcon={<TbBuildingFactory2 style={{fontSize:'1rem', color:'#000'}} />}
          getPopupContainer={() => document.getElementById('modal-area')}
          onChange={handleChange}
      ></Select>
    </>)
}
