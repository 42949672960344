export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const formatMoney = (amount, decimalCount) => {
  try {
    let decimal = ".",
      thousands = ",";
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
};

export const PROVINCE_OPTIONS = [
  { label: "กรุงเทพมหานคร", value: "กรุงเทพมหานคร" },
  { label: "กระบี่", value: "กระบี่" },
  { label: "กาญจนบุรี", value: "กาญจนบุรี" },
  { label: "กาฬสินธุ์", value: "กาฬสินธุ์" },
  { label: "กำแพงเพชร", value: "กำแพงเพชร" },
  { label: "ขอนแก่น", value: "ขอนแก่น" },
  { label: "จันทบุรี", value: "จันทบุรี" },
  { label: "ฉะเชิงเทรา", value: "ฉะเชิงเทรา" },
  { label: "ชัยนาท", value: "ชัยนาท" },
  { label: "ชัยภูมิ", value: "ชัยภูมิ" },
  { label: "ชุมพร", value: "ชุมพร" },
  { label: "ชลบุรี", value: "ชลบุรี" },
  { label: "เชียงใหม่", value: "เชียงใหม่" },
  { label: "เชียงราย", value: "เชียงราย" },
  { label: "ตรัง", value: "ตรัง" },
  { label: "ตราด", value: "ตราด" },
  { label: "ตาก", value: "ตาก" },
  { label: "นครนายก", value: "นครนายก" },
  { label: "นครปฐม", value: "นครปฐม" },
  { label: "นครพนม", value: "นครพนม" },
  { label: "นครราชสีมา", value: "นครราชสีมา" },
  { label: "นครศรีธรรมราช", value: "นครศรีธรรมราช" },
  { label: "นครสวรรค์", value: "นครสวรรค์" },
  { label: "นราธิวาส", value: "นราธิวาส" },
  { label: "น่าน", value: "น่าน" },
  { label: "นนทบุรี", value: "นนทบุรี" },
  { label: "บึงกาฬ", value: "บึงกาฬ" },
  { label: "บุรีรัมย์", value: "บุรีรัมย์" },
  { label: "ประจวบคีรีขันธ์", value: "ประจวบคีรีขันธ์" },
  { label: "ปทุมธานี", value: "ปทุมธานี" },
  { label: "ปราจีนบุรี", value: "ปราจีนบุรี" },
  { label: "ปัตตานี", value: "ปัตตานี" },
  { label: "พะเยา", value: "พะเยา" },
  { label: "พระนครศรีอยุธยา", value: "พระนครศรีอยุธยา" },
  { label: "พังงา", value: "พังงา" },
  { label: "พิจิตร", value: "พิจิตร" },
  { label: "พิษณุโลก", value: "พิษณุโลก" },
  { label: "เพชรบุรี", value: "เพชรบุรี" },
  { label: "แพร่", value: "แพร่" },
  { label: "พัทลุง", value: "พัทลุง" },
  { label: "ภูเก็ต", value: "ภูเก็ต" },
  { label: "มหาสารคาม", value: "มหาสารคาม" },
  { label: "มุกดาหาร", value: "มุกดาหาร" },
  { label: "แม่ฮ่องสอน", value: "แม่ฮ่องสอน" },
  { label: "ยโสธร", value: "ยโสธร" },
  { label: "ยะลา", value: "ยะลา" },
  { label: "ร้อยเอ็ด", value: "ร้อยเอ็ด" },
  { label: "ระนอง", value: "ระนอง" },
  { label: "ระยอง", value: "ระยอง" },
  { label: "ราชบุรี", value: "ราชบุรี" },
  { label: "ลพบุรี", value: "ลพบุรี" },
  { label: "ลำปาง", value: "ลำปาง" },
  { label: "ลำพูน", value: "ลำพูน" },
  { label: "เลย", value: "เลย" },
  { label: "ศรีสะเกษ", value: "ศรีสะเกษ" },
  { label: "สกลนคร", value: "สกลนคร" },
  { label: "สงขลา", value: "สงขลา" },
  { label: "สมุทรสาคร", value: "สมุทรสาคร" },
  { label: "สมุทรปราการ", value: "สมุทรปราการ" },
  { label: "สมุทรสงคราม", value: "สมุทรสงคราม" },
  { label: "สระแก้ว", value: "สระแก้ว" },
  { label: "สระบุรี", value: "สระบุรี" },
  { label: "สิงห์บุรี", value: "สิงห์บุรี" },
  { label: "สุโขทัย", value: "สุโขทัย" },
  { label: "สุพรรณบุรี", value: "สุพรรณบุรี" },
  { label: "สุราษฎร์ธานี", value: "สุราษฎร์ธานี" },
  { label: "สุรินทร์", value: "สุรินทร์" },
  { label: "สตูล", value: "สตูล" },
  { label: "หนองคาย", value: "หนองคาย" },
  { label: "หนองบัวลำภู", value: "หนองบัวลำภู" },
  { label: "อำนาจเจริญ", value: "อำนาจเจริญ" },
  { label: "อุดรธานี", value: "อุดรธานี" },
  { label: "อุตรดิตถ์", value: "อุตรดิตถ์" },
  { label: "อุทัยธานี", value: "อุทัยธานี" },
  { label: "อุบลราชธานี", value: "อุบลราชธานี" },
  { label: "อ่างทอง", value: "อ่างทอง" },
];

export const CAR_BRAND_OPTON = [
  {
    value: "BMW",
    label: "BMW",
  },
  {
    value: "HONDA",
    label: "HONDA",
  },
  {
    value: "MAZDA",
    label: "MAZDA",
  },
  {
    value: "TOYOTA",
    label: "TOYOTA",
  },
  {
    value: "NISSAN",
    label: "NISSAN",
  },
  {
    value: "ISUZU",
    label: "ISUZU",
  },
  {
    value: "MITSUBISHI",
    label: "MITSUBISHI",
  },
  {
    value: "ROVER",
    label: "ROVER",
  },
  {
    value: "AUDI",
    label: "AUDI",
  },
  {
    value: "BENZ",
    label: "BENZ",
  },
  {
    value: "VOLKSWAGEN",
    label: "VOLKSWAGEN",
  },
  {
    value: "OPEL",
    label: "OPEL",
  },
  {
    value: "HINO",
    label: "HINO",
  },
  {
    value: "FORD",
    label: "FORD",
  },
  {
    value: "KIA",
    label: "KIA",
  },
  {
    value: "SUZUKI",
    label: "SUZUKI",
  },
  {
    value: "SEAT",
    label: "SEAT",
  },
  {
    value: "VOLVO",
    label: "VOLVO",
  },
  {
    value: "HOLDEN",
    label: "HOLDEN",
  },
  {
    value: "HYUNDAI",
    label: "HYUNDAI",
  },
  {
    value: "DAEWOO",
    label: "DAEWOO",
  },
  {
    value: "DAIHATSU",
    label: "DAIHATSU",
  },
  {
    value: "PEUGEOT",
    label: "PEUGEOT",
  },
  {
    value: "LAND ROVER",
    label: "LAND ROVER",
  },
  {
    value: "CHEVROLET",
    label: "CHEVROLET",
  },
  {
    value: "DATSUN",
    label: "DATSUN",
  },
  {
    value: "FIAT",
    label: "FIAT",
  },
  {
    value: "CITROEN",
    label: "CITROEN",
  },
  {
    value: "JEEP",
    label: "JEEP",
  },
  {
    value: "SSAGYONG",
    label: "SSAGYONG",
  },
  {
    value: "LEXUS",
    label: "LEXUS",
  },
  {
    value: "SUBARU",
    label: "SUBARU",
  },
  {
    value: "RENAULT",
    label: "RENAULT",
  },
  {
    value: "SAAB",
    label: "SAAB",
  },
  {
    value: "MINI",
    label: "MINI",
  },
  {
    value: "PROTON",
    label: "PROTON",
  },
  {
    value: "SKODA",
    label: "SKODA",
  },
  {
    value: "CHRYSLER",
    label: "CHRYSLER",
  },
  {
    value: "WULING",
    label: "WULING",
  },
  {
    value: "HUMBER",
    label: "HUMBER",
  },
  {
    value: "TATA",
    label: "TATA",
  },
  {
    value: "ALFA ROMEO",
    label: "ALFA ROMEO",
  },
  {
    value: "DFM",
    label: "DFM",
  },
  {
    value: "PORSCHE",
    label: "PORSCHE",
  },
  {
    value: "THAIRUNG",
    label: "THAIRUNG",
  },
  {
    value: "VOLK",
    label: "VOLK",
  },
  {
    value: "NAZA",
    label: "NAZA",
  },
  {
    value: "JAGUAR",
    label: "JAGUAR",
  },
  {
    value: "สามล้อ",
    label: "สามล้อ",
  },
  {
    value: "BENTLEY",
    label: "BENTLEY",
  },
  {
    value: "RELY",
    label: "RELY",
  },
  {
    value: "CHERRY",
    label: "CHERRY",
  },
  {
    value: "MG",
    label: "MG",
  },
  {
    value: "MERCEDES-BENZ",
    label: "MERCEDES-BENZ",
  },
  {
    value: "HAMMER",
    label: "HAMMER",
  },
  {
    value: "HAVAL",
    label: "HAVAL",
  },
  {
    value: "NETA",
    label: "NETA",
  },
  {
    value: "ORA",
    label: "ORA",
  },
  {
    value: "BYD",
    label: "BYD",
  },
  {
    value: "TESIA",
    label: "TESIA",
  },
  {
    value: "BOMA",
    label: "BOMA",
  },
  {
    value: "YARIS",
    label: "YARIS",
  },
  {
    value: "TESLA",
    label: "TESLA",
  },
];

export const capitalized = (word) => word.split(/\s|-/).map((w) => w.charAt(0).toUpperCase() + w.slice(1)).join(" ");

export const formatFileSize = (fileSizeInBytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (Number(fileSizeInBytes) === 0) return "0 Byte";
  const i = parseInt(Math.floor(Math.log(fileSizeInBytes) / Math.log(1024)));
  return Math.round(fileSizeInBytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const comma = (number, dmax = 2, dmin = 0) => number.toLocaleString("en-US", { minimumFractionDigits: dmin,  maximumFractionDigits: dmax});

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const filterOption = (input, option) => {  return (String(option?.label ?? "")).toLowerCase().includes((input || "")?.toLowerCase()) };

export const filterComplete = (input, option) => (String(option?.value ?? "").toUpperCase().indexOf(input?.toUpperCase()) !== -1);

export const notEnter = (e) => { (e.key === 'Enter') && e.preventDefault(); }

export const quarterFormat = ( v ) => {
  const quarter = Math.floor(v.month() / 3) + 1;
  return `Q${quarter}`;
}

export const cmConfig = (content, title = "ยืนยันการทำรายการ") =>( {
  title: title,
  content:content,
})

export const jsonParsable = (value) => {
  try { 
    return JSON.parse(value);
  } catch (e) {
    return undefined;
  }
}

export const dateToThaiFormat = (dateString) => {
  // Convert the input date string to a Date object
  const date = new Date(dateString);

  // Define the months in Thai
  const thaiMonths = [
    "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
    "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
  ];

  // Extract the day, month, and year
  const day = date.getDate();
  const month = thaiMonths[date.getMonth()];
  const year = date.getFullYear() + 543; // Convert to Buddhist year

  // Format the date in Thai format
  return `${day} ${month} ${year}`;
}

export const address = ( val ) => {
  const { address, zipcode, district, amphoe, province, tel, fax, email } = val;

  let dist = "";
  dist += !!district ? ` ต.${district}` :'';
  dist += !!amphoe   ? ` อ.${amphoe}` :'';
  dist += !!province ? ` จ.${province}` :'';
  dist += !!zipcode ? ` ${zipcode}` :'';
  dist += !!tel ? ` โทร.${tel}` :'';
  dist += !!fax ? ` แฟกซ์ ${fax}` :'';
  dist += !!email ? ` อีเมล ${email}` :'';

  const addr = `${address || ''}${dist}`; 
  return addr;
}