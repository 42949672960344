import MyModalAppBusiness from './MyModalAppBusiness.jsx';
import MyModalAppPerson from './MyModalAppPerson.jsx';

export const myModalColumn = () => [
    {
        title: "รหัสลูกค้า",
        dataIndex: "cuscode",
        key: "cuscode",
        width: 100, 
        align: "left",
        sorter: true, 
    },
    {
        title: "ชื่อลูกค้า",
        dataIndex: "cusname",
        key: "cusname", 
        align: "left",
        sorter: true, 
        style: { minWidth: 120 }
    },
    {
        title: "จังหวัด",
        dataIndex: "province",
        key: "province", 
        width: 140, 
        align: "left",
        sorter: true,
    }
];


export const tabItems = ( response, countRpo ) => ([
    {
        label: `ลูกค้าบริษัท`,
        key: "1",
        children: <MyModalAppBusiness values={response} countRpo={countRpo} />, 
    },
    {
        label: `ลูกค้าบุคคล`,
        key: "0",
        children: <MyModalAppPerson values={response} countRpo={countRpo} />, 
    } 
])