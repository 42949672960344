import {
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Badge,
  InputNumber,
} from "antd";
import React, { useState, useEffect } from "react";
import OptionsService from "../../service/Options.service";
import { PROVINCE_OPTIONS } from "../../utils/util";
import { CAR_BRAND_OPTON } from "../../utils/util";
const opService = OptionsService();
export default function CarManageForm({
  formName = "form-name",
  submit,
  initeial,
  mode,
}) {
  const [form] = Form.useForm();
  const formRole = { required: true, message: "กรุณากรอกข้อมูลให้ครบถ้วน!" };
  const [optionModel, setOptionModel] = useState([]);
  const [optionCustomer, setOptionCustomer] = useState([]);
  const [optionBusiness, setOptionBusiness] = useState([]);
  const [optionValueModel, setOptionValueModel] = useState();
  const [optionValueCustomer, setOptionValueCustomer] = useState([]);
  const [optionValueBusiness, setOptionValueBusiness] = useState([]);
  const { TextArea } = Input;
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  useEffect(() => {
    GetCustomer();
    GetBusiness();
    GetModel();
    //  console.log(initeial);
    if (mode !== "create") {
      form.setFieldsValue(initeial);
    } else {
      form.setFieldsValue({
        business_car: "1",
      });
    }
  }, [initeial, mode, form]);

  const dividerProp = {
    orientation: "left",
    style: { marginBlock: 10 },
    className: "!border-black",
  };
  const GetModel = () => {
    opService.optionsModel().then((res) => {
      let { data } = res.data;
      // console.log(data)
      setOptionModel(data);
    });
  };
  const GetCustomer = () => {
    opService.optionsCustomer().then((res) => {
      let { data } = res.data;
      // console.log(data)
      setOptionCustomer(data);
    });
  };
  const GetBusiness = () => {
    opService.optionsBusiness().then((res) => {
      let { data } = res.data;
      // console.log(data)
      setOptionBusiness(data);
    });
  };
  const onFinish = (values) => {
    submit(values);
  };

  return (
    <Space direction="vertical" className="w-full">
      <Form
        form={form}
        layout="vertical"
        name={formName}
        autoComplete="off"
        className="w-full"
        // onValuesChange={(_, value)=> setFormValue(value)}
        onFinish={onFinish}
      >
        <Divider {...dividerProp}>ลักษณะรถ</Divider>
        <Row className="!mx-0" gutter={[8, 8]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="business_car" label="ลักษณะรถ">
              <Select
                size="large"
                showSearch
                filterOption={filterOption}
                options={[
                  {
                    value: "0",
                    label: "รถส่วนบุคคล",
                  },
                  {
                    value: "1",
                    label: "รถบริษัท",
                  },
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.business_car !== currentValues.business_car
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("business_car") === "0" ? (
                  <Form.Item name="cuscode" label="รถส่วนบุคคล">
                    <Select
                      size={"large"}
                      value={optionValueCustomer}
                      placeholder="รถส่วนบุคคล"
                      showSearch
                      filterOption={filterOption}
                      onChange={(value) => setOptionValueCustomer(value)}
                      options={optionCustomer.map((item) => ({
                        value: item.cuscode,
                        label:
                          item.title_name +
                          " " +
                          item.firstname +
                          " " +
                          item.lastname,
                      }))}
                    ></Select>
                  </Form.Item>
                ) : (
                  <Form.Item name="businessno" label="รถบริษัท">
                    <Select
                      size={"large"}
                      value={optionValueBusiness}
                      placeholder="รถบริษัท"
                      showSearch
                      filterOption={filterOption}
                      onChange={(value) => setOptionValueBusiness(value)}
                      options={optionBusiness.map((item) => ({
                        value: item.businessno,
                        label: item.business_name,
                      }))}
                    ></Select>
                  </Form.Item>
                )
              }
            </Form.Item>
          </Col>
          <Divider {...dividerProp}>ข้อมูลรถ</Divider>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item label="ทะเบียนรถ" name="carno" rules={[formRole]}>
              <Input placeholder="กรอกทะเบียนรถ" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="province" label="จังหวัด">
              <Select
                size="large"
                placeholder="เลือกจังหวัด"
                showSearch
                filterOption={filterOption}
                options={PROVINCE_OPTIONS}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="brand" label="ยี่ห้อ">
              <Select
                size="large"
                placeholder="เลือกยี่ห้อ"
                showSearch
                filterOption={filterOption}
                options={CAR_BRAND_OPTON}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="car_model" label="รุ่น/ปี">
              <Select
                size={"large"}
                value={optionValueModel}
                placeholder="เลือกรุ่น/ปี"
                showSearch
                filterOption={filterOption}
                onChange={(value) => setOptionValueModel(value)}
                options={optionModel.map((item) => ({
                  value: item.modelcode,
                  label: item.modelname,
                }))}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="color" label="สี">
              <Input placeholder="กรอกสี" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={3}>
            <Form.Item name="front_tire" label="ลมยางล้อหน้า">
              <InputNumber
                style={{
                  width: "100%",
                }}
                size="large"
                placeholder="กรอกลมยางล้อหน้า"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={3}>
            <Form.Item name="back_tire" label="ลมยางล้อหลัง">
              <InputNumber
                style={{
                  width: "100%",
                }}
                size="large"
                placeholder="กรอกลมยางล้อหลัง"
              />
            </Form.Item>
          </Col>
          <Divider {...dividerProp}>เพิ่มเติม</Divider>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name="remark" label="หมายเหตุ">
              <TextArea rows={2} placeholder="กรอกหมายเหตุ" />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={6}
            style={
              mode === "edit" ? { display: "inline" } : { display: "none" }
            }
          >
            <Form.Item name="active_status" label="สถานะการใช้งาน">
              <Select
                size="large"
                options={[
                  {
                    value: "Y",
                    label: <Badge status="success" text="เปิดการใช้งาน" />,
                  },
                  {
                    value: "N",
                    label: <Badge status="error" text="ปิดการใช้งาน" />,
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item name="id">
              <Input type="hidden" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Space>
  );
}
