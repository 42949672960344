import { Badge } from "antd";

export const accessColumn = () => [  
  {
    title: "id",
    dataIndex: "id",
    key: "id",
    width: "20%",
    hidden: true,
  },
  {
    title: "ทะเบียนรถ",
    dataIndex: "carno",
    key: "carno",
    width: "20%",
    hide: false,
  },
  {
    title: "จังหวัด",
    dataIndex: "province",
    key: "province",
    width: "20%",
    hide: false,
  },
  {
    title: "ยี่ห้อ",
    dataIndex: "brand",
    key: "brand",
    width: "20%",
    hide: false,
  },
  {
    title: "รุ่น",
    dataIndex: "car_model",
    key: "car_model",
    width: "20%",
    hide: false,
  },
  {
    title: "สถานะการใช้งาน",
    dataIndex: "active_status",
    key: "active_status",
    width: "25%",
    hide: false,
    render: (data) => (
      <div>
        {data === "Y" ? (
          <Badge status="success" text="เปิดการใช้งาน" />
        ) : (
          <Badge status="error" text="ปิดการใช้การ" />
        )}
      </div>
    ),
  },
];

export const formData = {
  car_code: null,
  car_name: null,
  active_status: null,
};
