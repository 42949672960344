/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Form, Input, Space } from "antd";
import { QctPageLayout } from "../../components/layout";
import { FormSearchValue, TableSearchValue } from "../../components/form";
// import { Typography } from 'antd';
import BusinessService from "../../service/Business.service";


import { accessColumn } from "./model";
import useSearch from "../../store/hook/use-search.hook";

const businessService = BusinessService();

function BusinessAccess() {
  const navigate = useNavigate();

  const stSearch = useSearch("business-access");
  const { criteria: cria, tbparams: parm } = stSearch.gettingSearch();
  const tbparamsDefault = {
    ...parm,
    pagination: { ...{ current: 1, pageSize: 10 }, ...parm?.pagination },
  };
  const criteriaDefault = cria || {};
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);

  const [criteria, setCriteria] = useState({ ...criteriaDefault });

  const [tbparams, setTbparams] = useState({ ...tbparamsDefault });
  const [pageValue, setPageValue] = useState({ ...tbparamsDefault.pagination });

  const formSearch = (
    <FormSearchValue
      title="ค้นหา"
      onValues={(value) => handleCriteria(value)}
      defaultValue={criteriaDefault}
    >
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
        <Form.Item label="ชื่อบริษัท" name="business_name">
          <Input placeholder="กรอกชื่อบริษัท" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
        <Form.Item label="เบอร์โทร" name="tel">
          <Input placeholder="กรอกเบอร์โทร" />
        </Form.Item>
      </Col>
    </FormSearchValue>
  );

  const handleCriteria = (criteria) => {
    setCriteria(criteria);
    setTbparams(page => ({ 
      ...page, 
      pagination: {...page?.pagination, current: 1},
      current: 1
    }));
  };

  const handlePaging = (page) => {
    setTbparams(page);
    // setTbparams(page.pagination);
  };

  const handleSearch = () => {
    const parm = { criteria, tbparams };
    businessService.search(parm, { ignoreLoading: Object.keys(parm.criteria).length !== 0 }).then((res) => {
      const {
        data: { source, tbparams },
      } = res.data;

      stSearch.updateSearch(parm);
      setPageValue(tbparams?.pagination);
      // setPaing( state => ( {...state, ...pagination }) );
      setDataSource(source);

      const { order, field } = tbparams;
      setTimeout(() => {
        setColumns((state) =>
          !!field
            ? state.map((col) => {
                if (col.key === field) col["sortOrder"] = order;
                else delete col["sortOrder"];
                return col;
              })
            : state
        );
      }, 80);
    });
  };

  const handleRowSelect = (record, key) => {
    // console.log(record, key);
  };

  const handleCreatData = () => {
    navigate("manage/create", {
      state: { config: { mode: "create", acname: "สร้าง" } },
    });
  };

  const handleUpdateData = (keys) => {
    navigate("manage/edit", {
      state: { config: { mode: "edit", acname: "แก้ไข",code:keys } },
    });
  };

  useEffect(() => {
    handleSearch();

    // setMounted(true);
  }, [JSON.stringify(criteria), JSON.stringify(tbparams)]);

  useEffect(() => {
    const init = () => {
      const { order, field } = tbparamsDefault;
      const cols = accessColumn();
      setColumns(
        !!field
          ? accessColumn().map((col) => {
              if (col.key === field) col["sortOrder"] = order;
              else delete col["sortOrder"];
              return col;
            })
          : cols
      );
      // console.log(  order, field  );
    };

    init();

    return () => {};
  }, []);

  return (
    <>
      <QctPageLayout title={"ลูกค้าบริษัท"}>
        <Space direction="vertical" className="flex gap-4">
          {formSearch}
          <TableSearchValue
            title="รายชื่อลูกค้าบริษัท"
            pagingDefault={tbparamsDefault?.pagination}
            onPageChange={handlePaging}
            onSelectedRow={handleRowSelect}
            onCreate={handleCreatData}
            onUpdate={handleUpdateData}
            tbProps={{
              rowKey: "businessno",
              dataSource: dataSource,
              columns: columns,
              pagination: pageValue,
            }}
          />
        </Space>
      </QctPageLayout>
    </>
  );
}

export default BusinessAccess;
