/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { QctPageLayout } from '../../components/layout';
import { TbCheck } from "react-icons/tb";
import { Button, message } from 'antd';
import dayjs from "dayjs";
import PurchaseOrderManageForm from './MyManageForm';
import PurchaseOrderManageView from './MyManageView';

import QuotationService from "../../service/Quotation.service";
import { TbSearchProvider } from '../../store/context/table-search.context';
import { formData } from "./model";
import useConfirm from '../../store/hook/use-confirm.hook';
import { CancelButton } from '../../components/drawer/cancel';

const formName = "quotation-form";
const apirequest = QuotationService();
function Manage() {
  const navigate = useNavigate();
  const location = useLocation();
  const confirms = useConfirm();

  const { config:{ mode, acname, code } } = location.state || { config: null };

  const [formValue, setFormValue] = useState(formData);
  const [hasRpocode, setHasRpocode] = useState(undefined);
  const handleSubmit = async (value) =>{ 
    try {  
      // console.log( value );
      // const action = mode === "create" ? apirequest.create(value) : apirequest.update(value);
      await apirequest[mode]({...value, id:code});
      navigate(-1);
      message.success("Request create done.");
    } catch ( er ){
      console.log( er ); 
      message.error("Error request.")
    }
  }

  const handleCancel = async (v) => {
    const result = await confirms.deleted();
    if( !result ) return;
    try { 
      await apirequest.deleted({comment:v, code});
      navigate(-1);
      message.success("ยกเลิกใบเสนอราคาเรียบร้อย");
    } catch ( er ){
      console.log( er ); 
      const { data:{ option } } = er.response;
      message.error(`Error request. ${option}`);
    }
  }

  useEffect( () => {
    const initeil = async () =>{
      try {
        if( mode !== "create" && !!code){
          const res = await apirequest.get(code);
          const { data:{ head, list } } = res.data; 

          head.qtdate = !!head.qtdate ? dayjs(head?.qtdate) : null;
          head.confirm_date = !!head.confirm_date ? dayjs(head?.confirm_date) : null;
          setHasRpocode( !!head.rpocode );
          setFormValue({ head, list}); 
        } 
      } catch ( er ){
        console.log( er );
        message.error("error request.")
      }
    }
    initeil();
  }, [mode, code]);

  const acButton = (<>
  { mode !== "view" 
    ?<Button
      icon={<TbCheck style={{fontSize:'1rem'}}/>} 
      className='bn-center bn-primary'
      form={formName}
      htmlType='submit'   
    >ยืนยัน/บันทึก</Button>
    : <CancelButton value={handleCancel} text='ยกเลิกใบเสนอราคา' disabled={hasRpocode} />
  }
  </>); 

  return (<>
    <QctPageLayout title={`ใบเสนอราคา - ${acname}`} back={true} footerRight={acButton} hearderRight={acButton} >
    {mode === "view"
      ? <PurchaseOrderManageView initeial={formValue} />
      : (<TbSearchProvider>
          <PurchaseOrderManageForm  formName={formName} submit={handleSubmit} initeial={formValue} />
        </TbSearchProvider>)
    }
 
    </QctPageLayout> 
  </>)
}

export default Manage