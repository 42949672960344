import { Tag, Typography } from 'antd';
import React from 'react';
import MyDrawerStockNormal from './MyDrawerStockNormal';
import MyDrawerStockTires from './MyDrawerStockTires';
import { comma } from '../../../utils/util';

export default function MyDrawerButton({ value, result = null, record = {}, code=null }) {
    const [show, setShow] = React.useState( false );
    
    const handleClick = () => {
        // console.log( 111  )
        setShow(true);
    }
    
    const hancleConfirmCancel = (v) => { 
        if( typeof value === 'function'){
            value( v ); 
        }
    }
    return (
        <> 
            { 
                ( result === null || result === undefined) 
                ? <Tag icon={false} className='m-0' >ไม่มีสต๊อก</Tag> 
                : !!Number(result) && Number(result) > 0 
                ? <Typography.Link 
                    onClick={handleClick}
                    className='hover:!underline'
                    >{comma(Number(result || 0))}</Typography.Link> 
                : <Tag icon={false}  className='m-0' color='red' >สต๊อกหมด</Tag>
            } 

            { !!show && Number(record?.stock_by_product || 0 ) === 0  && 
                <MyDrawerStockNormal 
                    show={show} 
                    close={()=>setShow(false)} 
                    value={hancleConfirmCancel} 
                    keySelect={code}
                    title={`รายการสต๊อก #${code}${!!record.stname ? ` - ${record.stname}`  : ''}`}
                /> }
            { !!show && Number(record?.stock_by_product || 0 ) === 1  && 
                <MyDrawerStockTires 
                    show={show} 
                    close={()=>setShow(false)} 
                    value={hancleConfirmCancel}
                    keySelect={code}
                    title={`รายการสต๊อก #${code}${!!record.stname ? ` - ${record.stname}`  : ''}`}
                /> }
        </>
    )
}
