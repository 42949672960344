import dayjs from 'dayjs';
import { comma } from '../../utils/util';
import { Typography } from 'antd';
 

export const accessColumn = () => [
  {        
    title: "เลขใบวางบิล",
    dataIndex: "bncode",
    key: "bncode",
    width: '10%', 
    align: "left",
    sorter: true, 
  },
  {
    title: "วันที่ใบวางบิล",
    dataIndex: "bndate",
    key: "bndate",
    width: '10%',
    align: "left",
    sorter: true,
    hide: false,
    render: (v) => !!v ? dayjs(v).format("DD/MM/YYYY") : "-",
  },
  {
    title: "วันที่กำหนดชำระ",
    dataIndex: "payment_duedate",
    key: "payment_duedate",
    width: '15%',
    align: "left",
    sorter: true,
    hide: false,
    render: (v) => !!v ? dayjs(v).format("DD/MM/YYYY") : "-",
  },
  {
    title: "รหัสลูกค้า",
    dataIndex: "cuscode",
    key: "cuscode",
    width: '15%', 
    align: "left",
    sorter: true,
    hide: false,
  },
  {
    title: "ชื่อลูกค้า",
    dataIndex: "cusname",
    key: "cusname",
    align: "left",
    sorter: true,
  },
  {
    title: "ออกโดย",
    dataIndex: "created_name",
    key: "created_name",
    width: "15%",
    align: "left",
    sorter: true,
    hide: true,
  },
]; 
 

export const formData = {
  head: { 
    bncode: null,
    cuscode: null,
    cusname: null,
    bndate: dayjs(),
    payment_duedate: null, 
    address: null,
    created_date: null,
    updated_date: null,
    created_by: null,
    updated_by: null,
    contact: null,    
  },
  list: []
} 

export const info = (val) => [
  {
    key: 'bndate',
    label: 'วันที่ใบวางบิล',
    children: <Typography.Text>{!!val?.bndate ? dayjs(val?.bndate).format('YYYY/MM/DD') : "-"}</Typography.Text>,
  },
  {
    key: 'payment_duedate',
    label: 'วันที่กำหนดชำระ',
    children: <Typography.Text>{!!val?.payment_duedate ? dayjs(val?.payment_duedate).format('YYYY/MM/DD') : "-"}</Typography.Text>,
  },
];

//{xs:1, sm:2, md:2, lg:3, xl:4, xxl:4} 
export const customer = (val) => [
  {
    key: 'cuscode',
    label: 'รหัสลูกค้า',
    children: <Typography.Text>{val?.cuscode || '\u00A0'}</Typography.Text>,
    span: { xl: 1, xxl: 1 }
    // labelStyle:lableDesStyle
  },
  {
    key: 'cusname',
    label: 'ชื่อลูกค้า', 
    children: <Typography.Text>{val?.cusname || '\u00A0'}</Typography.Text>, 
    span: { xl: 3, xxl: 3 }
    // labelStyle:lableDesStyle
  },
  {
    key: 'taxnumber',
    label: 'เลขผู้เสียภาษี', 
    children: <Typography.Text>{val?.taxnumber || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_name',
    label: 'ติดต่อ', 
    children: <Typography.Text>{val?.contact_name || '\u00A0'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_tel',
    label: 'เบอร์โทร', 
    children: <Typography.Text>{val?.contact_tel || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_email',
    label: 'อีเมล', 
    children: <Typography.Text>{val?.contact_email || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'address',
    label: 'ที่อยู่ลูกค้า', 
    children: <Typography.Text className='text-wrap'>{val?.address || '\u00A0'}</Typography.Text>,
  },
];
 

export const columnRepairOrder = () => [
  {
    title: "ลำดับ",
    dataIndex: "ind",
    key: "ind",
    width: 80,  
    render: (im, rc, index) => <>{index + 1}</>,
  },
  {
    title: "เลขที่ใบรายการซ่อม",
    dataIndex: "rpocode",
    key: "rpocode",
    width: '25%',   
  },
  {
    title: "เลขที่ใบกำกับภาษี",
    dataIndex: "invcode",
    key: "invcode",
    width: '25%',  
    render: (im) => <>{im || '-'}</>,
  },
  {
    title: " วันที่ใบรายการซ่อม",
    dataIndex: "rpodate",
    key: "rpodate",
    width: '25%',  
    render: (v) => dayjs(v).format("DD/MM/YYYY"),
  },
  {
    title: " รวมเงิน",
    dataIndex: "price_total",
    key: "price_total",
    align: 'right', 
    className: '!pe-4',
    render: (v) => comma( v, 2, 2),
  },
]