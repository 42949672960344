import { requestService as api } from "./Request.service"  
const API_URL = { 
  API_MANAGE: `/repair-order/manage.php`, 
  API_SEARCH: `/repair-order/search.php`, 
  API_SUBMIT: `/repair-order/submit-stock.php`, 

  API_APPROVE: `/repair-order/approve.php`,
};

const RepairOrderService = () => { 
  
  const create = (parm = {}) => api.post(`${API_URL.API_MANAGE}`, parm);
  const update = (parm = {}) => api.put(`${API_URL.API_MANAGE}`, parm);
  const deleted = (comment, code) => api.delete(`${API_URL.API_MANAGE}?${!!code ? `code=${code}` : ''}${!!comment ? `&comment=${comment}` : ''}`);
  const get = (code) => api.get(`${API_URL.API_MANAGE}?code=${code}`);
  
  const search = (parm = {}, config = {}) => api.post(`${API_URL.API_SEARCH}`, parm, {...config, cancle: true});
  
  const submit_stock = (parm = {}) => api.put(`${API_URL.API_SUBMIT}`, parm);
  return {
    create,
    update,
    deleted,
    get,
    search,
    submit_stock
  };
};

export default RepairOrderService;