import React from 'react'
import { Route } from 'react-router-dom'

import { 
  PurchaseOrderPrintPdf, 
  PurchaseOrderPrint, 
  QuotationPrint, 
  RepairOrderPrint, 
  ReceiptsPrint,
  BillingNote 
} from '../components/print';

export const PrintRouter = (<>
  <Route path="/purchase-order-print-pdf/:code/:print?" element={<PurchaseOrderPrintPdf />} />
  <Route path="/purchase-order-print/:code/:print?" element={<PurchaseOrderPrint />} />
  <Route path="/quotation-print/:code/:print?" element={<QuotationPrint />} />
  <Route path="/repair-order-print/:code/:print?" element={<RepairOrderPrint />} />
  <Route path="/receipt-print/:code/:print?" element={<ReceiptsPrint />} />
  <Route path="/billing-note-print/:code/:print?" element={<BillingNote />} />
</>)