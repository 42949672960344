import React from 'react'
import { TbSearchProvider } from '../../../store/context/table-search.context';

import MyModalItem from './MyModalItem';
import MyModalItemTires from './MyModalItemTires';
export default function MyModal({selected = {}, close,...restProps}) {
    // console.log( selected, restProps ); 

    React.useEffect( ()=>{
        const { cat_stock_by_product } = selected;
        if( cat_stock_by_product === undefined ) setTimeout( () => close(false), 120 );
    }, [close, selected]);
    return (
        <TbSearchProvider>
            { Number(selected?.cat_stock_by_product) === 1 && (<MyModalItemTires selected={selected} close={close}  {...restProps} />)}
            { Number(selected?.cat_stock_by_product) === 0 && (<MyModalItem selected={selected} close={close}  {...restProps} />)}
        </TbSearchProvider>
    )
}