import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import "./MyPage.css";
function MyPage() {
    useEffect(() => { 
      return () => { };
    }, []);
    return (<div className='layout-content' id="area"><Outlet /></div>)
}

export default MyPage