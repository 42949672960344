import { Badge } from "antd";

export const accessColumn = () => [  
  {
    title: "รหัสรุ่น",
    dataIndex: "modelcode",
    key: "modelcode",
    hidden: true,
  },
  {
    title: "ชื่อรุ่นรถ",
    dataIndex: "modelname",
    key: "modelname",
  },
  {
    title: "สถานะการใช้งาน",
    dataIndex: "active_status",
    key: "active_status",
    hide: false,
    render: (data) => (
      <div>
        {data === "Y" ? (
          <Badge status="success" text="เปิดการใช้งาน" />
        ) : (
          <Badge status="error" text="ปิดการใช้การ" />
        )}
      </div>
    ),
  },
];

export const formData = {
  carmodel_code: null,
  carmodel_name: null,
  active_status: null,
};
