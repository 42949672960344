import React from 'react';
import { Descriptions, Divider, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { columnViewDetail, carData } from "./model"
import { TableSearchValue } from '../../components/form';
const dividerProp = {orientation:"left", style:{marginBlock:10}, className:'!border-black'}; 
const emptyText = { emptyText:<span>ไม่มีข้อมูลสินค้า</span> };



export default function ManageView({initeial}) {
  const navigate = useNavigate();
  const [headValue, setHeadValue] = React.useState({});
  const [listValue, setListValue] = React.useState([]);
  

  const cardetail = (<>
    <Divider {...dividerProp}>ข้อมูลรถ</Divider>
    <Descriptions title={false} layout="vertical" bordered size='small' column={{xs:1, sm:2, md:2, lg:2, xl:4, xxl:4}} items={carData(headValue)} />
  </>);

  const handleView = (code,record) => {
    // repair-orders/manage
    // const newWindow = window.open("", "_blank");
    // newWindow.location.href = `/dln-print/${data.dncode}`;
    
    const config = { mode:"view", acname: `ใบรายการซ่อม #${record}`,code:record };
    navigate("../../repair-orders/manage", {state:{config}});
  };

  const lists = (<>
      <Divider {...dividerProp}>ประวัติ</Divider>
      <TableSearchValue
          title='ประวัติรายการซ่อม' 
          notSelect={true}
          tbProps={{               
              dataSource:listValue,
              columns:columnViewDetail({handleView}),
              rowKey:"id",
              pagination: false,
              locale:{...emptyText}
              // summary: (record) => totalPrice
          }}
      />
  </>);

  React.useEffect(() => {
    const { head, list } = initeial;
    setHeadValue(head);

    // const newList = list?.flatMap( m => {
    //   let seq = 1;
    //   const { cat_stock_by_product, amount, id } = m
    //   if( Number( cat_stock_by_product || 0 ) === 1) {
    //       const newObj = {
    //           ...m,
    //           amount: 1, 
    //       } 
    //       return [...Array(Number(amount || 0 )).keys()].map( m => ({...newObj, id: `${id}/${seq++}`, id: uuidv4()})); 
    //   } else return m; 
    // });

    // console.log( list );
    setListValue(list);
  }, [initeial]);

  return (
    <Space direction='vertical' className='w-full desc-repair-order'>
      {cardetail}
      {lists}
    </Space>
  )
}
