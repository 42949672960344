
export const myModalColumn = () => [
    {
        title: "เลขใบเสนอราคา",
        dataIndex: "qtcode",
        key: "qtcode",
        width: 100, 
        align: "left",
        sorter: true, 
    },
    {
        title: "วันที่ใบเสนอราคา",
        dataIndex: "qtdate",
        key: "qtdate", 
        align: "left",
        sorter: true, 
        style: { minWidth: 120 }
    },
    {
        title: "รหัสลูกค้า",
        dataIndex: "cuscode",
        key: "cuscode", 
        align: "left",
        sorter: true, 
        style: { minWidth: 120 }
    },
    {
        title: "ชื่อลูกค้า",
        dataIndex: "cusname",
        key: "cusname", 
        align: "left",
        sorter: true, 
        style: { minWidth: 120 }
    },
    {
        title: "ติดต่อ",
        dataIndex: "contact_name",
        key: "contact_name", 
        width: "22%",
        align: "left",
        sorter: true,
        render: (_,v) => `${v?.contact_name || ''} ${v?.contact_tel || ''}`
    },
];