import React from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { LoadingProvider } from "../../store/context/loading-context";
import { Authenticate } from "../../service/Authenticate.service";
import { AxiosInterceptor } from "../layout/AxiosInterceptor";
import Main from "../layout/Main";
import { 
  ConfigProvider, 
  Modal 
} from "antd";

// import locale from 'antd/locale/th_TH';
import dayjs from 'dayjs';

import 'dayjs/locale/th';

dayjs.locale('th'); 
const authService = Authenticate();
const defaultData = {
  borderRadius: 8,
  colorPrimary: '#130ab0', 
};
const PrivateRoute = ({ allowdRole, mode = "manage" }) => { 
  const location = useLocation();
  const navigate = useNavigate(); 
  const [theme] = React.useState(defaultData);
  const authCheck = () => {
    let payload = { role: "admin" };
    const exp = authService.isExpireToken( ()=>{ 
      authService.setCurrent(location.pathname);
      navigate("/login", { replace: true });
    }); 
    
    if( allowdRole.includes(payload?.role) && exp ) return true;
    else return Modal.error({
      title: 'Session Expire',
      content: 'your session expired please relogin',
      onOk: () => { 
        authService.setCurrent(location.pathname);
        navigate("/", { replace: true })
      }
    })
  };

  return authCheck() ? (
    // <ConfigProvider theme={{ token: { colorPrimary: '#130ab0' } }}>
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: theme.colorPrimary,
        borderRadius: theme.borderRadius,
      }, 
    }}
    >
      <LoadingProvider>
        <AxiosInterceptor>
          { mode === "manage" &&
            <Main>
              <Outlet />
            </Main>
          }
          { mode === "print" && 
            <div style={{height:'100vh', overflow:'auto'}}>
              <Outlet />
            </div>
          }

        </AxiosInterceptor>
      </LoadingProvider>
    </ConfigProvider>
    // </ConfigProvider>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
